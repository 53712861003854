import React from "react";
import {
  primaryDarkStyle,
  primaryStyle,
  secondaryOutlineStyle,
  secondaryStyle,
  secondaryDisabledStyle,
  secondaryOutlineStyleDisabled,
} from "../Button";

type IconButtonPropsType = {
  element?: React.ReactNode | null;
  children: React.ReactNode;
  variant?: "primary" | "primary-dark" | "secondary" | "secondary-outline";
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
  removeHover?: boolean;
  small?: boolean;
};
// The component should have the following props: children, variant? = "primary", onClick?
// The variants should be enumerated as strings of the following: primary, and secondary.

const IconButton = ({
  children,
  className,
  variant = "primary",
  onClick = () => {},
  disabled = false,
  removeHover = false,
  small = false,
}: IconButtonPropsType) => {
  const typeStyle =
    variant === "primary"
      ? primaryStyle(removeHover)
      : variant === "primary-dark"
      ? primaryDarkStyle(removeHover)
      : variant === "secondary"
      ? secondaryStyle(removeHover)
      : variant === "secondary-outline"
      ? secondaryOutlineStyle(removeHover)
      : "";
  const disabledStyle =
    variant === "primary" || variant === "secondary"
      ? secondaryDisabledStyle
      : variant === "secondary-outline" || variant === "primary-dark"
      ? secondaryOutlineStyleDisabled
      : "";
  return (
    <button
      onClick={!disabled ? onClick : () => {}}
      // className={`flex px-2  py-2 justify-center items-center ${typeStyle} ${disabled ? disabledStyle : typeStyle}`}
      className={`${className} flex ${
        small ? "h-6 w-6" : "px-3 py-2.5"
      } justify-center items-center ${disabled ? disabledStyle : typeStyle}`}
    >
      {children}
    </button>
  );
};

export default IconButton;
