import React, { useEffect, useMemo } from "react";
import Button from "../../../../components/Button";
import PlusIcon from "../../../../assets/PlusIcon";
import SelectField2 from "../../../../components/SelectedField2";
import { Controller } from "react-hook-form";
import { useState } from "react";
import { stateAtom } from "../../../../atom";
import { useRecoilState } from "recoil";
import { getListOfPincodes } from "../../../../api/channelPartner";
import CrossIcon from "../../../../assets/CrossIcon";
import ErrorMessage from "../../../../components/ErrorMessage";
import { toast } from "react-toastify";
import _debounce from "lodash/debounce";

interface TableItem {
  id: string;
  value: string;
  type: "city" | "pincode";
}

const AssignedLocations = ({
  watch,
  setValue,
  errors,
  clearErrors,
  control,
}: any) => {
  const [pincodeLoading, setPincodeLoading] = useState(false);
  const [pincodes, setPincodes] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pageLength, setPageLength] = useState<number>(25);
  const [totalPage, setTotalPage] = useState(0);
  const [stateData] = useRecoilState(stateAtom);

  const itemsPerPage = 10;
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedType, setSelectedType] = useState<"city" | "pincode" | null>(
    null
  );
  const selectedPincodes = watch("assigned_zipcodes") || [];
  const selectedCities = watch("assigned_cities") || [];

  //City State dropdown states
  const [locationList, setLocationList] = useState<any>([]);
  const [locationPage, setLocationPage] = useState(1);
  const [locationTotalPage, setLocationTotalPage] = useState(0);
  const [locationSearch, setLocationSearch] = useState("");

  const tableItems: TableItem[] = useMemo(() => {
    const pincodeItems = selectedPincodes.map(
      (pincode: string, index: number) => ({
        key: index,
        id: `pincode-${pincode}`,
        value: pincode,
        type: "pincode" as const,
      })
    );

    const cityItems = selectedCities.map((city: any, index: number) => ({
      key: index,
      id: `city-${city}`,
      value: city,
      type: "city" as const,
    }));

    return [...pincodeItems, ...cityItems];
  }, [selectedPincodes, selectedCities]);

  // Update availablePincodeOptions to filter based on selectedType
  const availablePincodeOptions = useMemo(() => {
    if (selectedType === "city") return [];

    return pincodes?.map((el: any, index: number) => ({
      key: el.zipcode,
      value: el.zipcode,
      label: el.zipcode,
    }));
  }, [pincodes, selectedType]);

  // Update locationList filtering based on selectedType
  const availableCityOptions = useMemo(() => {
    if (selectedType === "pincode") return [];

    return locationList;
  }, [locationList, selectedType]);

  const removeItem = (item: TableItem) => {
    if (item.type === "pincode") {
      const updatedPincodes = selectedPincodes.filter(
        (p: any) => p !== item.value
      );
      setValue("assigned_zipcodes", updatedPincodes);
      if (updatedPincodes.length === 0) setSelectedType(null);
    } else {
      const updatedCities = selectedCities.filter((c: any) => c !== item.value);
      setValue("assigned_cities", updatedCities);
      if (updatedCities.length === 0) setSelectedType(null);
    }
  };

  useEffect(() => {
    if (selectedPincodes.length > 0 && !selectedType) {
      setSelectedType("pincode");
    } else if (selectedCities.length > 0 && !selectedType) {
      setSelectedType("city");
    }
  }, [selectedPincodes, selectedCities]);

  const getPincodeList = async () => {
    setPincodeLoading(true);
    const params = {
      page: page,
      length: pageLength,
      search: searchValue,
    };
    try {
      const res = await getListOfPincodes(params);
      setPincodes(res?.data?.pincode);
    } catch (error) {
      console.error("Error fetching pincode list:", error);
    } finally {
      setPincodeLoading(false);
    }
  };

  const debouncedFetchProjects = _debounce(getPincodeList, 500);

  // Updated effect for search
  useEffect(() => {
    if (selectedType === "pincode" || !selectedType) {
      setPage(1);
      setPincodes([]);

      // Only trigger the API call if search value is 4 or more digits
      if (searchValue.length >= 4) {
        debouncedFetchProjects();
      } else {
        // Clear pincodes when search value is less than 4 digits
        setPincodes([]);
      }

      return () => {
        debouncedFetchProjects.cancel();
      };
    }
  }, [searchValue, selectedType]);

  useEffect(() => {
    setLocationPage(1);
  }, [locationSearch]);

  useEffect(() => {
    const allCities =
      stateData
        ?.map((state: any, index: number) => {
          return state?.cities?.map((city: any, index: number) => ({
            key: index,
            value: `${city}, ${state.name}, ${state.state_code}`,
            label: `${city}, ${state.name}`,
          }));
        })
        .flat(1) || [];

    const filteredCities = allCities.filter((city: any) =>
      `${city.value} ${city.label}`
        .toLowerCase()
        .includes(locationSearch.toLowerCase())
    );

    const totalPage = Math.ceil(filteredCities.length / itemsPerPage);
    setLocationTotalPage(totalPage);

    if (locationPage === 1) {
      // Reset locationList if page is 1
      setLocationList(filteredCities.slice(0, itemsPerPage));
    } else {
      // Append to locationList if page increases
      const startIndex = (locationPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const paginatedList = [
        ...(locationList || []), // Preserve existing items
        ...filteredCities.slice(startIndex, endIndex),
      ];
      setLocationList(paginatedList);
    }
  }, [stateData, locationPage, locationSearch]);

  // Add these new states to store temporary selections
  const [tempPincode, setTempPincode] = useState<string>("");
  const [tempCity, setTempCity] = useState<string>("");

  // Update the Controller components to store temporary values instead of directly adding to the table
  const handleAddLocations = () => {
    if (tempPincode && selectedType === "pincode") {
      if (!selectedPincodes.includes(tempPincode)) {
        const updatedPincodes = [...selectedPincodes, tempPincode];
        setValue("assigned_zipcodes", updatedPincodes);
        clearErrors("assigned_locations");
        setTempPincode("");
      } else {
        toast.error("This pincode is already added!");
      }
    } else if (tempCity && selectedType === "city") {
      if (!selectedCities.includes(tempCity)) {
        const updatedCities = [...selectedCities, tempCity];
        setValue("assigned_cities", updatedCities);
        clearErrors("assigned_locations");
        setTempCity("");
      } else {
        toast.error("This city is already added!");
      }
    }
  };

  return (
    <div className="w-full flex flex-col gap-4 mt-2">
      <Controller
        name="assigned_zipcodes"
        control={control}
        render={({ field }) => (
          <SelectField2
            label="Assigned Pincode"
            options={availablePincodeOptions}
            value={tempPincode}
            onChange={(value) => {
              setTempPincode(value);
              setSelectedType("pincode");
            }}
            externalSearch={searchValue}
            externalSetSearch={setSearchValue}
            searchPlaceHolder="Enter minimum 4 digits to search pincode"
            disabled={pincodeLoading || selectedType === "city"}
            hint={errors?.assigned_zipcodes?.message}
          />
        )}
      />

      <span className="text-center text-[15px] font-gilroy-regular leading-5 text-[#8F8F8F]">
        or
      </span>

      <Controller
        name="assigned_cities"
        control={control}
        render={({ field }) => (
          <SelectField2
            label="Assigned City"
            options={availableCityOptions}
            value={tempCity}
            searchPlaceHolder="Search city"
            totalPage={locationTotalPage}
            page={locationPage || 1}
            setPage={setLocationPage}
            externalSearch={locationSearch}
            externalSetSearch={setLocationSearch}
            onChange={(e: string) => {
              const cityStateArray = e.split(", ");
              const formattedValue = `${cityStateArray[0]},${cityStateArray[2]}`;
              setTempCity(formattedValue);
              setSelectedType("city");
            }}
            disabled={selectedType === "pincode"}
            hint={errors?.assigned_locations?.message}
          />
        )}
      />

      <Button
        type="button"
        label={"Add City/ Pincode"}
        leftIcon={<PlusIcon color="#FFFFFF" />}
        variant="secondary"
        fullWidth={false}
        className="w-[35%] mt-1"
        onClick={handleAddLocations}
      />
      <div className="w-full h-[0.5px] mb-1 mt-4 bg-[#DEDEDE]"></div>

      {tableItems.length > 0 && (
        <div className="w-full mt-4 overflow-y-auto custom-scrollbar h-[35vh]">
          <table className="w-full">
            <thead className="bg-[#ECECEC80] border-[#ECECEC80] border-l-[0.3px]  border-y-0 border-r-0 font-gilroy-semi-bold text-sm leading-4">
              <tr>
                <th className="w-16 py-3 px-2 text-left">Sr no.</th>
                <th className="py-3 px-4 text-left">Particular</th>
                <th className="w-14 py-3 px-2 text-left">Action</th>
              </tr>
            </thead>
            <tbody className="font-gilroy-medium text-sm leading-4">
              {tableItems.map((item, index) => (
                <tr key={item.id} className="">
                  <td className="w-16 py-3 px-4 border-[0.3px] border-t-0 border-[#ECECEC]">
                    {index + 1}
                  </td>
                  <td className="py-3 px-4 border-[0.3px] border-t-0 border-[#ECECEC]">
                    {item.value}
                  </td>
                  <td className="w-14 py-3 px-4 border-[0.3px] border-t-0 border-r-[0.1px] border-[#ECECEC]">
                    <button
                      onClick={() => removeItem(item)}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <CrossIcon color="black" width="14" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AssignedLocations;
