import { protectedAxiosInstance } from "./axiosManagement";

export const getListForChannelPartner = (params) => {
  return protectedAxiosInstance
    .get("/admin/accounts/channel-partner", { params })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getListOfPincodes = (params) => {
  return protectedAxiosInstance
    .get("/accounts/zipcodes", { params })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const postChannelPartner = (payload) => {
  return protectedAxiosInstance
    .post("/admin/accounts/channel-partner", payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const updateChannelPartner = (partnerId, payload) => {
  return protectedAxiosInstance
    .put(`/admin/accounts/channel-partner/${partnerId}`, payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const updateChannelPartnerStatus = (id, payload) => {
  return protectedAxiosInstance
    .patch(`/admin/accounts/channel-partner/${id}`, payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getSingleChannlePartner = (partnerId) => {
  return protectedAxiosInstance
    .get(`/admin/accounts/channel-partner/${partnerId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
