import { AreaType, FloorType, ProjectsType } from "../../types";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import EditableField from "./EditableField";
import FloorIcon from "../../assets/svg-tsx/FloorIcon";
import AreaIcon from "../../assets/svg-tsx/AreaIcon";
import ProductIcon from "../../assets/svg-tsx/ProductIcon";
import _debounce from "lodash/debounce";

import HipchatChevronLeftIcon from "../../assets/HipchatChevronLeftIcon";
import TickIcon from "../../assets/TickIcon";
import { protectedAxiosInstance } from "../../api/axiosManagement";
import { toast } from "react-toastify";

export type DropdownBoxType = {
  mouseLocation?: number;
  data?: any;
  top?: boolean;
  type?: string;
  selected: {
    project: ProjectsType | null;
    floor: FloorType | null;
    area: AreaType | null;
    setProject: Dispatch<SetStateAction<ProjectsType | null>>;
    setFloor: Dispatch<SetStateAction<FloorType | null>>;
    setArea: Dispatch<SetStateAction<AreaType | null>>;
  };
  actionType?: string;
  updateActionType?: any;
  showCopyItem?: boolean;
  quotationId?: any;
  itemId?: any;
  itemCount?: any;
  getSingleQuotationCartItems?: any;
  closeProjectSelection?: any;
};

const DropdownBox = ({
  mouseLocation = 0,
  selected,
  top = false,
  type = "false",
  actionType,
  updateActionType,
  showCopyItem = false,
  quotationId,
  itemId,
  itemCount,
  getSingleQuotationCartItems,
  closeProjectSelection,
}: DropdownBoxType) => {
  const [newField, setNewField] = useState(false);
  const [editable, setEditable] = useState<
    AreaType | ProjectsType | FloorType | null
  >(null);
  const [valueStash, setValueStash] = useState<
    FloorType | AreaType | ProjectsType | null
  >(null);
  const [clickCheck, setClick] = useState(0);
  const [leftOffset, setLeftOffset] = useState(
    `${Math.trunc(mouseLocation * 2)}%`
  );
  const [selectedType, setSelectedType] = useState<any>(
    selected?.floor ? "area" : selected?.project ? "floor" : "project"
  );

  const [itemsType, setItemsType] = useState<any>("quotations");
  const [quotationDetails, setQuotationDetails] = useState<any>(null);
  const [projectDetails, setProjectDetails] = useState<any>(null);
  const [moveCopyLoading, setMoveCopyLoading] = useState(false);
  const [projectData, setProjectData] = useState<any>(null);
  const [floorData, setFloorData] = useState<any>(null);
  const [floorId, setFloorId] = useState<any>(null);
  const [areaId, setAreaId] = useState<any>(null);

  //Infine scroll states
  const [quotationsList, setQuotationsList] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [quotationsLoading, setQuotationsLoading] = useState(false);
  const listRef = useRef<HTMLDivElement>(null);

  const fetchQuotations = async () => {
    setQuotationsLoading(true);
    try {
      const res = await protectedAxiosInstance.get("/admin/quotation", {
        params: {
          length: 10,
          page,
        },
      });

      const newQuotations = res.data.data.quotations;

      setQuotationsList((prevQuotations) =>
        page === 1 ? newQuotations : [...prevQuotations, ...newQuotations]
      );

      setTotalPage(res.data.data.pagination.total_pages);
      setQuotationsLoading(false);
    } catch (error) {
      console.error("Error fetching quotations:", error);
      setQuotationsLoading(false);
    }
  };

  // Debounced search and initial fetch
  const [time, setTime] = useState(500);
  const debouncedFetchQuotations = _debounce(fetchQuotations, time);

  // Effect for page changes
  useEffect(() => {
    setTime(10);
    const fetchData = async () => {
      await debouncedFetchQuotations();
    };
    fetchData();
    return () => {
      debouncedFetchQuotations.cancel();
    };
  }, [page]);

  // Infinite scroll handler
  const handleScroll = useCallback(() => {
    if (!listRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = listRef.current;

    if (
      scrollHeight - scrollTop - clientHeight < 20 &&
      page < totalPage &&
      !quotationsLoading
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [page, totalPage, quotationsLoading]);

  function onQuotationClick(projectData: any, id: any) {
    setItemsType("project");
    setQuotationDetails(id);
    setProjectDetails(projectData);
    fetchProjectsInfo(id);
  }

  function onClickBack() {
    if (itemsType === "project") {
      setItemsType("quotations");
    }
    if (itemsType === "floor") {
      setItemsType("project");
    }
    if (itemsType === "area") {
      setItemsType("floor");
    }
  }

  function onProjectClick() {
    setItemsType("floor");
  }

  function onFloorClick(floorData: any) {
    setFloorData(floorData);
    setFloorId(floorData?.id);
    setItemsType("area");
  }

  function onAreaClick(areaId: any) {
    setMoveCopyLoading(true);
    const params = {
      destination_quotation_id: quotationDetails,
      mode: actionType?.toUpperCase(),
    };
    const payload = {
      // project_id: projectDetails?.project_id,
      floor_id: floorId,
      area_id: areaId,
      quantity: itemCount,
    };

    protectedAxiosInstance
      .post(`/admin/quotation/${quotationId}/item/${itemId}/copy-to`, payload, {
        params: params,
      })
      .then((res) => {
        getSingleQuotationCartItems(quotationId);
        toast.success(
          `Item ${actionType === "move" ? "moved" : "copied"} successfully`
        );
      })
      .catch((error) => {
        console.log("Error in moving or copying : ", error);
        toast.error("There is an error Item is not moved!");
      })
      .finally(() => {
        setMoveCopyLoading(false);
        closeProjectSelection();
      });
  }

  function handleRegularQuotation(destinationId: any) {
    setMoveCopyLoading(true);
    const params = {
      destination_quotation_id: destinationId,
      mode: actionType?.toUpperCase(),
    };
    protectedAxiosInstance
      .post(
        `/admin/quotation/${quotationId}/item/${itemId}/copy-to`,
        { quantity: itemCount },
        { params: params }
      )
      .then((res) => {
        getSingleQuotationCartItems(quotationId);
        toast.success(
          `Item ${actionType === "move" ? "moved" : "copied"} successfully`
        );
      })
      .catch((error) => {
        console.log("Error in moving or copying: ", error);
        toast.error("There is an error Item is not moved!");
      })
      .finally(() => {
        setMoveCopyLoading(false);
        closeProjectSelection();
      });
  }

  function fetchProjectsInfo(id: any) {
    protectedAxiosInstance(`/admin/quotation/${id}/project-info`)
      .then((res) => {
        setProjectData(res?.data?.data?.project);
      })
      .catch((error) => {
        console.log("ERROR WHILE FETCHING :", error);
      });
  }

  useEffect(() => {
    if (updateActionType) {
      updateActionType("move");
    }
  }, []);

  return (
    <div
      className={`${top && type !== "project" && "bottom-[45px]"} ${
        type === "project" && `lg:w-64`
      } ${
        type === "product" &&
        `fixed bottom-0 w-screen lg:w-64  lg:top-auto lg:bottom-[100%]  lg:absolute`
      } overflow-y-auto shadow-lg divide-y divide-pot-grey3 bg-white border border-pot-grey3 `}
    >
      <div
        className={` sticky top-0 bg-pot-grey3 capitalize py-2 flex items-center px-3 gap-x-2 fill-pot-black text-pot-black font-gilroy-bold text-sm  whitespace-normal overflow-wrap break-word word-break break-all ${
          selected?.project ? "cursor-pointer " : "cursor-auto "
        }`}
        onClick={onClickBack}
        // onClick={handleBack}
      >
        {itemsType !== "quotations" && (
          <button>
            <HipchatChevronLeftIcon />
          </button>
        )}

        {itemsType}
      </div>
      {newField && (
        <EditableField
          defaultValue="Untitled"
          close={() => setNewField(false)}
          fieldType={selectedType}
          selectedData={selected}
        />
      )}

      <div
        ref={listRef}
        onScroll={handleScroll}
        className="max-h-[200px] overflow-y-auto custom-scrollbar flex flex-col"
      >
        {itemsType === "quotations" &&
          quotationsList?.map((quotation: any) => (
            <button
              key={quotation?.id}
              className="w-full text-left px-3 py-[7px] hover:bg-c-gray-1 text-sm whitespace-normal overflow-wrap break-word word-break break-all font-gilroy-medium"
              onClick={() => {
                if (quotation?.kind === "PROJECT") {
                  onQuotationClick(
                    {
                      project_id: quotation?.project_id,
                      project_name: quotation?.project_name,
                    },
                    quotation?.id
                  );
                }
                if (quotation?.kind === "REGULAR") {
                  handleRegularQuotation(quotation?.id);
                }
              }}
            >
              #{quotation?.num} ({quotation?.kind})
            </button>
          ))}

        {quotationsLoading && (
          <div className="text-center py-2 text-gray-500">Loading...</div>
        )}

        {itemsType === "project" && (
          <button
            className="w-full text-left px-3 py-[7px] hover:bg-c-gray-1 text-sm whitespace-normal overflow-wrap break-word word-break break-all font-gilroy-medium"
            onClick={onProjectClick}
          >
            {projectDetails?.project_name}
          </button>
        )}

        {itemsType === "floor" && (
          <>
            {projectData?.floors?.length > 0 ? (
              projectData?.floors?.map((floor: any) => {
                return (
                  <button
                    key={floor?.id}
                    className="w-full text-left px-3 py-[7px] hover:bg-c-gray-1 text-sm whitespace-normal overflow-wrap break-word word-break break-all font-gilroy-medium"
                    onClick={() => onFloorClick(floor)}
                  >
                    {floor?.name}
                  </button>
                );
              })
            ) : (
              <div>No Floors available</div>
            )}
          </>
        )}

        {itemsType === "area" && (
          <>
            {floorData?.areas?.length > 0 ? (
              floorData?.areas?.map((area: any) => {
                return (
                  <button
                    key={area?.id}
                    className="w-full text-left px-3 py-[7px] hover:bg-c-gray-1 text-sm whitespace-normal overflow-wrap break-word word-break break-all font-gilroy-medium"
                    onClick={() => onAreaClick(area?.id)}
                  >
                    {area?.name}
                  </button>
                );
              })
            ) : (
              <div>No Areas Available</div>
            )}
          </>
        )}
      </div>

      {showCopyItem && (
        <div
          className={`w-full h-[30px] sticky bottom-0 flex justify-between text-black font-gilroy-medium text-xs`}
        >
          <button
            className={`py-2 px-3 w-full h-full flex gap-2.5 items-center start ${
              actionType === "move" ? "bg-[#EEEEEE]" : "bg-pot-grey3"
            }`}
            onClick={() => updateActionType("move")}
          >
            {actionType === "move" && (
              <span>
                <TickIcon width="16" />
              </span>
            )}
            <span>Move Item</span>
          </button>
          <button
            className={`py-2 px-3 w-full h-full flex gap-2.5 items-center start ${
              actionType === "copy" ? "bg-[#EEEEEE]" : "bg-pot-grey3 "
            }`}
            onClick={() => updateActionType("copy")}
          >
            {actionType === "copy" && (
              <span>
                <TickIcon width="16" />
              </span>
            )}

            <span>Copy Item</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default DropdownBox;
