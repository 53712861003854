import { LegendData } from "./data";
import { GetUserPermissions } from "./UserInfo";

export const truncateFilename = (filename: string, fileLength: number) => {
  if (filename.length > fileLength) {
    return `...${filename.slice(-fileLength)}`;
  }
  return filename;
};

export const formatFileSize = (value: number, unit?: "B" | "KB" | "MB") => {
  const units = ["B", "KB", "MB"];
  let fileSize = value;

  // Convert initial value to bytes if needed
  if (unit === "KB") {
    fileSize *= 1024; // Convert KB to bytes
  } else if (unit === "MB") {
    fileSize *= 1024 * 1024; // Convert MB to bytes
  }

  let index = 0;
  // Convert bytes to the highest unit possible
  while (fileSize >= 1024 && index < units.length - 1) {
    fileSize /= 1024;
    index++;
  }

  return `${fileSize.toFixed(2)} ${units[index]}`;
};

export const getCurrentTimeISO = () => {
  const now = new Date();
  const isoString = now.toISOString();
  return isoString.replace("Z", "+00:00");
};

// 25th March ‘23
export const formatDate1 = (isoString: any) => {
  // Parse the provided ISO 8601 timestamp
  const date = new Date(isoString);

  // Extract day, month, and year
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear().toString(); // Get last two digits of the year

  // Determine the day suffix
  let daySuffix;
  if (day >= 11 && day <= 13) {
    daySuffix = "th";
  } else {
    switch (day % 10) {
      case 1:
        daySuffix = "st";
        break;
      case 2:
        daySuffix = "nd";
        break;
      case 3:
        daySuffix = "rd";
        break;
      default:
        daySuffix = "th";
    }
  }

  // Construct the final formatted string
  const finalFormattedDate = `${day}${daySuffix} ${month}, ${year}`;

  return finalFormattedDate;
};

// 23-Feb-2023
export const formatDate2 = (isoString: string) => {
  const date = new Date(isoString);

  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "short",
    year: "numeric",
  };

  const formattedDate = date
    .toLocaleDateString("en-GB", options)
    .replace(/ /g, "-");
  return formattedDate;
};

export const formatDate = (dateString: any) => {
  if (!dateString) return "-";
  const date = new Date(dateString);
  const day = date.getDate();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const removeExtraSpaces = (text: any) => {
  if (typeof text === "string") {
    return text.trim().replace(/\s+/g, " ");
  }
  return text;
};

export const removeExtraSpacesfromObject = (obj: any) => {
  if (typeof obj === "string") {
    return obj.trim().replace(/\s+/g, " ");
  } else if (typeof obj === "object" && obj !== null) {
    for (let key in obj) {
      obj[key] = removeExtraSpacesfromObject(obj[key]);
    }
  }
  return obj;
};

export const formattedPrice = (value: any, minimumFractionDigits = 2) => {
  if (typeof value === "number") {
    return value.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: minimumFractionDigits,
    });
  } else if (typeof value === "string") {
    const numValue = parseFloat(value);
    if (!isNaN(numValue)) {
      return numValue.toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: minimumFractionDigits,
      });
    }
  }
  return value;
};

export const applyLightningControlCategoryColor = (inputCategory: string) => {
  const foundLegend = LegendData.find((legend) => {
    if (legend?.category === inputCategory) {
      return legend;
    }
  });
  return foundLegend?.colour;
};

export const checkPermissions = (
  str?: string,
  showToast = false,
  toast?: any
) => {
  const availablePermissions = GetUserPermissions();
  if (!availablePermissions.length) {
    return true;
  }

  let hasAccess = false;
  hasAccess = availablePermissions?.includes(str);

  if (showToast && toast && !hasAccess) {
    showNoAccessToast(toast);
  }

  return hasAccess;
};

export const showNoAccessToast = (toast: any) => {
  // Check if toast has already been shown within the last 5 seconds
  const toastShownTimestamp = localStorage.getItem("toastShown");
  const currentTime = Date.now();

  if (
    !toastShownTimestamp ||
    currentTime - parseInt(toastShownTimestamp, 10) > 5000
  ) {
    toast?.error(
      "Sorry, the page you tried to access is restricted. We've redirected you for security reasons. If you need access or believe this is an error, please contact support.",
      { timeout: 5000, variant: "error" }
    );

    // Update the timestamp in localStorage
    localStorage.setItem("toastShown", currentTime.toString());
  }
};
