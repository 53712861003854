import React from "react";
interface FolderIconProps {
  size?: string;
  color?: string;
}

const FolderIcon = ({ size = "16", color = "#383838" }: FolderIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6654 3.66676H8.4787L8.26536 3.0001C8.12706 2.60892 7.87053 2.27043 7.5313 2.03154C7.19207 1.79264 6.78694 1.66516 6.37203 1.66676H3.33203C2.8016 1.66676 2.29289 1.87748 1.91782 2.25255C1.54274 2.62762 1.33203 3.13633 1.33203 3.66676V12.3334C1.33203 12.8639 1.54274 13.3726 1.91782 13.7476C2.29289 14.1227 2.8016 14.3334 3.33203 14.3334H12.6654C13.1958 14.3334 13.7045 14.1227 14.0796 13.7476C14.4546 13.3726 14.6654 12.8639 14.6654 12.3334V5.66676C14.6654 5.13633 14.4546 4.62762 14.0796 4.25255C13.7045 3.87748 13.1958 3.66676 12.6654 3.66676ZM13.332 12.3334C13.332 12.5102 13.2618 12.6798 13.1368 12.8048C13.0117 12.9299 12.8422 13.0001 12.6654 13.0001H3.33203C3.15522 13.0001 2.98565 12.9299 2.86063 12.8048C2.7356 12.6798 2.66536 12.5102 2.66536 12.3334V3.66676C2.66536 3.48995 2.7356 3.32038 2.86063 3.19536C2.98565 3.07033 3.15522 3.0001 3.33203 3.0001H6.37203C6.51179 2.99974 6.64813 3.04331 6.76178 3.12466C6.87543 3.20601 6.96064 3.32102 7.00536 3.45343L7.36536 4.54676C7.41009 4.67918 7.4953 4.79419 7.60894 4.87554C7.72259 4.95688 7.85893 5.00046 7.9987 5.0001H12.6654C12.8422 5.0001 13.0117 5.07033 13.1368 5.19536C13.2618 5.32038 13.332 5.48995 13.332 5.66676V12.3334Z"
        fill={color}
      />
      <path
        d="M9.94608 8.92281C9.91436 9.00464 9.86678 9.07941 9.80608 9.14281L8.47275 10.4761C8.41078 10.5386 8.33704 10.5882 8.2558 10.6221C8.17456 10.6559 8.08743 10.6733 7.99942 10.6733C7.91141 10.6733 7.82427 10.6559 7.74303 10.6221C7.66179 10.5882 7.58806 10.5386 7.52608 10.4761C7.4636 10.4142 7.414 10.3404 7.38016 10.2592C7.34631 10.178 7.32889 10.0908 7.32889 10.0028C7.32889 9.9148 7.34631 9.82766 7.38016 9.74642C7.414 9.66518 7.4636 9.59145 7.52608 9.52948L7.72608 9.33614L5.99942 9.33614C5.82261 9.33614 5.65304 9.2659 5.52801 9.14088C5.40299 9.01586 5.33275 8.84629 5.33275 8.66948C5.33275 8.49266 5.40299 8.3231 5.52801 8.19807C5.65304 8.07305 5.82261 8.00281 5.99942 8.00281L7.72608 8.00281L7.52608 7.80948C7.40055 7.68394 7.33002 7.51368 7.33002 7.33614C7.33002 7.15861 7.40055 6.98834 7.52608 6.86281C7.65162 6.73727 7.82188 6.66675 7.99942 6.66675C8.17695 6.66675 8.34722 6.73727 8.47275 6.86281L9.80608 8.19614C9.86678 8.25954 9.91436 8.33431 9.94608 8.41614C10.0128 8.57845 10.0128 8.7605 9.94608 8.92281Z"
        fill={color}
      />
    </svg>
  );
};

export default FolderIcon;
