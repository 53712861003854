import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import HipchatChevronDownIcon from "../assets/HipchatChevronDownIcon";
import { useRecoilState } from "recoil";
import { countryAtom } from "../atom";
import { getCountryCallingCode } from "libphonenumber-js";
import useOnClickOutside from "../hooks/useClickOutside";

type CountryCodeOption = {
  label: string;
  value: string;
};
// const countryCodes: CountryCodeOption[] = [
//   { value: "+247", label: "Ascension" },
//   { value: "+297", label: "Aruba" },
//   { value: "+374", label: "Argentina" },
//   { value: "+1264", label: "Anguilla" },
//   { value: "+355", label: "Albania" },
//   { value: "+91", label: "India" },
//   { value: "+1", label: "United States" },
//   { value: "+44", label: "United Kingdom" },
// ];

type SelectCountryCodeProps = {
  onChange: (value: string) => void;
  setCountryCode: (value: string) => void;
  country_code_value?: string;
  disabled?: boolean;
};

const SelectCountryCode = ({
  country_code_value,
  onChange,
  setCountryCode,
  disabled = false,
}: SelectCountryCodeProps) => {
  const [countryData, setCountryData] = useRecoilState(countryAtom);
  const countryCodes = countryData
    .filter(
      (el: any) =>
        el.iso2 !== "AQ" &&
        el.iso2 !== "BV" &&
        el.iso2 !== "TF" &&
        el.iso2 !== "HM" &&
        el.iso2 !== "PN" &&
        el.iso2 !== "GS" &&
        el.iso2 !== "UM"
    )
    .map((el: any) => ({
      value: `+${getCountryCallingCode(el.iso2)}`,
      label: el.name,
    }));

  const [selected, setSelected] = useState<CountryCodeOption | null>(
    country_code_value
      ? countryCodes.find(
          (option: any) => option.value === country_code_value
        ) || null
      : null
  );
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option: CountryCodeOption) => {
    setSelected(option);
    setIsOpen(false);
    onChange(option.value);
    setCountryCode(option.value);
  };
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };
  const filteredOptions = countryCodes.filter((option: any) =>
    option.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // console.log(country_code_value, 'country_code_value');
  const countryCodeRef = useRef(null);

  useOnClickOutside(countryCodeRef, () => setIsOpen(false));
  return (
    <div
      className={`relative inline-block text-left min-w-[4.5rem] ${
        disabled ? "opacity-30 " : "opacity-100"
      }`}
      ref={countryCodeRef}
    >
      <div>
        <span className="rounded-md shadow-sm ">
          <button
            type="button"
            className={`inline-flex justify-center items-center gap-1 px-1 py-[0.35rem] w-full rounded-md border-2 border-gray-300 bg-white text-sm font-medium focus:outline-none ${
              disabled && "cursor-not-allowed"
            }`}
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span className={`text-gray-500 ml-2`}>
              {selected ? selected.value : "+91"}
            </span>
            <motion.div
              animate={{ rotate: isOpen && !disabled ? 180 : 0 }}
              className="w-fit h-fit flex items-center justify-center"
            >
              <HipchatChevronDownIcon color="black" />
            </motion.div>
          </button>
        </span>
      </div>

      {isOpen && !disabled && (
        // origin-top-right absolute sm:right-0 sm:left-0 mt-2 w-full sm:w-auto sm:max-w-xs rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none
        <div className="origin-top-right h-60  absolute z-[100] sm:right-0 sm:left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ">
          <div
            className="py-1 h-full overflow-hidden overflow-y-auto no-scrollbar"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
            onChange={handleSearchChange}
          >
            {countryCodes.map((option: any) => (
              <button
                key={option.value}
                className={`${
                  selected?.value === option.value
                    ? "  bg-gray-100 text-gray-900"
                    : "text-gray-700"
                } flex w-full text-left px-4 py-2 text-sm  justify-between `}
                role="menuitem"
                onClick={() => handleOptionClick(option)}
              >
                <span> {option.value}</span>
                <span className="text-left flex justify-start ">
                  {option.label.length > 10
                    ? option.label.substring(0, 15) + "..."
                    : option.label}
                </span>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectCountryCode;
