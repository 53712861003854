import { useCallback, useEffect, useRef, useState } from "react";
import Form from "./productcomponents/Form";
import Stages from "./productcomponents/Stages";
import Button from "../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { protectedAxiosInstance } from "../../api/axiosManagement";
import NewIncompatibility from "./productcomponents/NewIncompatibility";
import Specification from "./productcomponents/Specification";
import { removeEmptyProperties } from "../../utils/validations";
import ErrorMessage from "../../components/ErrorMessage";
import { removeExtraSpacesfromObject } from "../../utils/helper";
import ProductFiles from "./productcomponents/ProductFiles";

export default function CreateEditProduct() {
  const [formData, setFormData] = useState<any>({});
  const [editIds, setEditIds] = useState<any>(null);
  const [errors, setErrors] = useState<any>({});
  const navigate = useNavigate();
  const { id: productID } = useParams();
  const [loading, setLoading] = useState(true);
  const [incompatibility, setIncompatibility] = useState<any>([{}]);
  // refs
  const codeRef = useRef<any>(null);
  const nameRef = useRef<any>(null);
  const descriptionRef = useRef<any>(null);
  const primaryImageRef = useRef<any>(null);
  const brandRef = useRef<any>(null);
  const seriesRef = useRef<any>(null);
  const categoryRef = useRef<any>(null);
  const basePriceRef = useRef<any>(null);
  const fixedDetailsRef = useRef<any>(null);
  const stagesRef = useRef<any>(null);
  const positionRef = useRef<any>(null);
  const productFileRef = useRef<any>([]);

  const targetRefs: any = {
    code: codeRef,
    name: nameRef,
    description: descriptionRef,
    primary_image: primaryImageRef,
    brand_id: brandRef,
    series_id: seriesRef,
    category_id: categoryRef,
    base_price: basePriceRef,
    fixed_details: fixedDetailsRef,
    stages: stagesRef,
    position: positionRef,
    documents: productFileRef,
  };
  const errorOrder = [
    "code",
    "name",
    "description",
    "primary_image",
    "brand_id",
    "series_id",
    "category_id",
    "base_price",
    "fixed_details",
    "stages",
    "position",
  ];
  const handleBackendError = (error: any) => {
    for (const key of errorOrder) {
      if (key in error) {
        const ref = targetRefs[key];
        if (ref && ref.current) {
          ref.current.scrollIntoView({ behavior: "smooth" });
          return;
        }
      }
    }
  };

  useEffect(() => {
    if (productID) {
      if (formData.name) {
        document.title = `Product - ${formData.name}`;
      } else {
        document.title = `Product - ...`;
      }
    }
  }, [formData.name, productID]);

  const create = async (payload: any) => {
    // delete incompatible key as it is unknown field for post
    delete payload?.incompatible_options;
    try {
      const response = await protectedAxiosInstance.post(
        `/admin/products`,
        payload
      );
      if (response?.data?.data) {
        toast.success("Product created successfully");
        navigate(`/products/${response?.data?.data?.product?.id}`);
      }
    } catch (e: any) {
      toast.error("Failed to create product");
      console.error(e?.data?.errors, "err");
      setErrors(e?.data?.errors || {});
      handleBackendError(e?.data?.errors || {});
    } finally {
      setLoading(false);
    }
  };

  const edit = async (payload: any) => {
    try {
      const { data: response } = await protectedAxiosInstance.put(
        `/admin/products/${productID}`,
        payload
      );

      if (response?.data) {
        toast.success("Product updated successfully");
        fetchProduct();
      }
    } catch (e: any) {
      toast.error("Failed to update product");
      console.error(e?.data?.errors, "err");
      setErrors(e?.data?.errors || {});
      handleBackendError(e?.data?.errors || {});
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setErrors({});
    setLoading(true);
    setEditIds(null);

    const settableFormData = structuredClone(formData);
    removeExtraSpacesfromObject(settableFormData);

    delete settableFormData?.brand;
    delete settableFormData?.category;
    delete settableFormData?.series;
    if (!settableFormData.code) {
      settableFormData.code = null;
    }
    if (!settableFormData.position) {
      settableFormData.position = null;
    }
    if (settableFormData.base_price) {
      settableFormData.base_price = parseFloat(settableFormData.base_price);
    }
    if (settableFormData.image) {
      delete settableFormData.image;
    }
    // Do not change the order
    if (settableFormData.fixed_details) {
      const obj: any = {};
      settableFormData.fixed_details.forEach((el: any) => {
        if (el?.key && el?.value) {
          obj[el.key] = el.value;
        }
      });
      settableFormData.fixed_details = obj;
    }

    if (!settableFormData.images) {
      settableFormData.images = [];
    } else {
      settableFormData.images = settableFormData.images.filter(
        (each: any) => each !== settableFormData.primary_image
      );
    }

    settableFormData?.stages?.forEach((stage: any) => {
      delete stage?.added;
      stage?.variants?.forEach((variant: any, id: number) => {
        if (variant?.added) {
          delete variant.id;
          delete variant.added;
        }

        variant?.options?.forEach((option: any, idx: number) => {
          if (option.added) {
            delete option.id;
            ``;
            delete option.added;
          }
          delete option.type;
          if (!option.price) {
            option.price = 0;
          }
        });
      });
    });

    delete settableFormData.discount;
    settableFormData.is_active = true;

    const updatedIncompatibility = incompatibility
      ?.map((el: any) => {
        const values = Object.values(el)[0] as string[];
        const nonEmptyValues = values?.filter((value) => value?.trim() !== "");
        return nonEmptyValues?.length > 0
          ? { [Object.keys(el)[0]]: nonEmptyValues }
          : null;
      })
      ?.filter(Boolean);

    settableFormData.incompatible_options = [...updatedIncompatibility];
    setIncompatibility([...updatedIncompatibility]);

    if (!settableFormData.incompatible_options) {
      settableFormData.incompatible_options = [];
      setIncompatibility([{}]);
    }

    settableFormData.stages = settableFormData.stages.map((stage: any) => {
      if (!Array.isArray(stage.variants)) {
        stage.variants = [];
      }

      stage.variants = stage.variants.map((variant: any) => {
        if (!Array.isArray(variant.options)) {
          variant.options = [];
        }

        const updatedOptions = variant.options.map(
          (option: any, optionIndex: any) => {
            return {
              ...option,
              position: optionIndex,
            };
          }
        );

        return { ...variant, options: updatedOptions };
      });

      return { ...stage };
    });

    if (!settableFormData?.specification) {
      settableFormData.specification = {
        color_type: [],
        control_type: [],
        watt_range: null,
      };
    }

    removeEmptyProperties(settableFormData?.specification?.watt_range);
    if (
      settableFormData?.specification?.watt_range &&
      Object.keys(settableFormData.specification.watt_range).length === 0
    ) {
      settableFormData.specification.watt_range = null;
    }
    if (
      settableFormData?.specification?.watt_range?.from_val &&
      !settableFormData?.specification?.watt_range?.to_val
    ) {
      settableFormData.specification.watt_range.to_val = null;
    }
    if (
      !settableFormData?.specification?.watt_range?.from_val &&
      settableFormData?.specification?.watt_range?.to_val
    ) {
      settableFormData.specification.watt_range.from_val = null;
    }

    if (productID) {
      delete settableFormData.id;
      settableFormData.stages?.forEach((stage: any) => {
        stage?.variants?.forEach((variant: any, variantId: number) => {
          variant?.options?.forEach((option: any, optionId: number) => {
            if (option.incompatible_option_ids) {
              delete option.incompatible_option_ids;
            }
          });
        });
      });

      edit(settableFormData);
    } else {
      create(settableFormData);
    }
  };

  const fetchProduct = useCallback(async () => {
    setLoading(true);
    try {
      const { data: response } = await protectedAxiosInstance.get(
        `/admin/products/${productID}`
      );
      const { product } = response.data;

      product.fixed_details = Object.keys(product.fixed_details).map(
        (each) => ({
          key: each,
          value: product.fixed_details[each],
        })
      );
      product.images = [product.primary_image, ...product.images];
      if (product?.brand) {
        product.brand_id = product.brand.id;
        delete product.brand;
      }
      if (product?.series) {
        product.series_id = product.series.id;
        delete product.series;
      }
      if (product?.category) {
        product.category_id = product.category.id;
        delete product.category;
      }

      setFormData(product);
      setIncompatibility(product.incompatible_options || [{}]);

      setEditIds({
        brand_id: product.brand_id,
        category_id: product.category_id,
        series_id: product.series_id,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [productID]);

  useEffect(() => {
    if (productID) {
      fetchProduct();
    } else {
      setLoading(false);
    }
  }, [fetchProduct, productID]);

  // For variant list in variant choose popup
  const [variantList, setVariantList] = useState<any>([]);
  const fetchVariants = async () => {
    try {
      const { data: response } = await protectedAxiosInstance.get(
        `/admin/products/variant-template`,
        {
          params: {
            length: 100,
          },
        }
      );
      const { variant_template } = response.data;
      setVariantList(
        variant_template.map((each: any) => ({
          label: each.name,
          value: each.id,
        }))
      );
    } catch (error) {
      setVariantList([]);
      console.error("Error in getting list");
    }
  };

  useEffect(() => {
    fetchVariants();
  }, []);

  return (
    <>
      <div className="h-[calc(100vh-2.5rem)] no-scrollbar w-full flex flex-col justify-start items-start">
        {/* heading */}
        <div className="bg-white border-b border-[#ECECEC] w-full pt-4 pb-4  px-4">
          <h2 className="font-semibold">
            {productID ? "Edit" : "Create new"} Product
          </h2>
        </div>

        <div className="flex w-full justify-between  flex-1  overflow-y-auto custom-scrollbar  py-3">
          <div className="w-[60%] overflow-y-auto custom-scrollbar px-4">
            <Form
              editIds={editIds}
              loading={loading}
              setErrors={setErrors}
              errors={errors}
              setForm={setFormData}
              form={formData}
              codeRef={codeRef}
              nameRef={nameRef}
              descriptionRef={descriptionRef}
              primaryImageRef={primaryImageRef}
              brandRef={brandRef}
              seriesRef={seriesRef}
              categoryRef={categoryRef}
              basePriceRef={basePriceRef}
              fixedDetailsRef={fixedDetailsRef}
              positionRef={positionRef}
            />
            <div className="border-y" ref={stagesRef}>
              <div className=" mb-6">
                <h2 className="font-semibold pt-6 flex justify-start">
                  Add Customization
                </h2>
                <p className="mb-2">
                  <ErrorMessage
                    error={errors?.stages?.length && errors?.stages}
                  />
                </p>
                <Stages
                  errors={errors}
                  setErrors={setErrors}
                  formData={formData}
                  setFormData={setFormData}
                  variantList={variantList}
                />
              </div>
            </div>

            <div className=" py-6 border-t w-[95%]">
              {productID && (
                <div>
                  <NewIncompatibility
                    incompatibility={incompatibility}
                    setIncompatibility={setIncompatibility}
                    formData={formData}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="w-[38%] max-h-[500px] px-4 flex flex-col gap-0">
            {/* Specification */}
            <Specification
              setFormData={setFormData}
              formData={formData}
              loading={loading}
              setErrors={setErrors}
              errors={errors}
            />
            <ProductFiles
              setFormData={setFormData}
              formData={formData}
              loading={loading}
              setErrors={setErrors}
              errors={errors}
              productFileRef={productFileRef}
              form={formData}
              setForm={setFormData}
            />
          </div>
        </div>

        {/* footer */}
        <div className="flex gap-x-4 w-[100%] py-3 border-t  border-[#ECECEC] px-4">
          <Button
            variant="primary"
            label={productID ? "Edit product" : "Create product"}
            onClick={() => {
              handleSubmit();
            }}
            disabled={loading}
          />
          <Button
            variant="secondary-outline"
            label="Cancel"
            onClick={() => navigate(`/products`)}
          />
        </div>
      </div>
    </>
  );
}
