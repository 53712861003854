import React, { useState, useRef, useEffect } from "react";
import OrderDetailsCard from "./components/OrderDetailsCard";
import calenderIcon from "../../assets/svg/clenderIcon.svg";
import TotalProfessionals from "./components/TotalProfessionals";
import OrderStatusCard from "./components/OrderStatusCard";
import OrderAddressCard from "./components/OrderAddressCard";
import OrderProjectDetails from "./components/OrderProjectDetails";
import ProjectDetailsTimeline from "../ProjectManagment/projectdetails/ProjectDetailsTimline";
import OrderTimelineRequested from "./components/OrderTimelineRequested";
import { useParams } from "react-router-dom";
import {
  getOrderItems,
  getOrderTimeline,
  getSingleOrderDetails,
} from "../../api/orderManagement";
import { useRecoilState, useRecoilValue } from "recoil";
import { ordeAppConfigAtom, titleAtom } from "../../atom";
import { getSingleProject } from "../../api/project";
import useSeparateOrdersByFloor from "../../hooks/useSeparateOrdersByFloor";
import { getSingleCustomer } from "../../api/customers";
import OrderCreateUserDetails from "./components/OrderCreateUserDetails";
import { getPaymentDetails, postPaymentStatus } from "../../api/payment";
import PaymentDetails from "./components/PaymentDetails";
import TimerIcon from "../../assets/svg-tsx/TimerIcon";
import loadingSpinner from "../../assets/images/LoadingSpinner.gif";
import AssignPartner from "./components/AssignPartner";

export const getStatusBgColor = (Status: string): string => {
  switch (Status) {
    case "PARTIALLY_PAID":
      return "bg-[#EBFFF2]"; // Light green background for PARTIALLY PAID
    case "PAID":
      return "bg-[#42B86D]"; // Green background for PAID
    case "FAILED":
      return "bg-[#FFB4A1]"; // Red background for FAILED
    case "DRAFT":
      return "bg-[#EDEDED]"; // Grey background for DRAFT
    case "PENDING":
      return "bg-[#FFD194]"; // Yellow background for PENDING
    case "AWAITING_TIMELINE":
    case "AWAITING_TIMELINE_CONFIRMATION":
    case "OUT_FOR_DELIVERY":
      return "bg-[#DBD6F6]"; // Purple background for AWAITING statuses and OUT FOR DELIVERY
    case "PROCESSING":
      return "bg-[#FFD194]"; // Yellow background for PROCESSING
    case "PARTIALLY_DELIVERED":
    case "DELIVERED":
    case "REFUNDED":
      return "bg-[#42B86D]"; // Green background for PARTIALLY DELIVERED, DELIVERED, and REFUNDED
    case "CANCELLED":
      return "bg-[#FFB4A1]"; // Purple background for REFUNDED
    default:
      return "bg-[#EDEDED]"; // Default grey background
  }
};

function SingleOrderDetails() {
  const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState<any>();
  const [projectDetails, setProjectDetails] = useState<any>();
  const [orderTimeline, setOrderTimeline] = useState<any>();
  const [selectedValue, setSelectedValue] = useState("");
  const [inputVal, setInputVal] = useState("");
  const [selectedOrderStatus, setSelectedOrderStatus] = useState("");
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("");
  const [tableData, setTableData] = useState<any>();
  const [timelineData, setTimelineData, handleDelete, handleQuantity, trigger] =
    useSeparateOrdersByFloor(tableData);
  const [userDetails, setUserDetails] = useState<any>();
  const [professionalDetails, setProfessionalDetails] = useState<any>();
  const [paymentDetails, setPaymentDetails] = useState<any>();
  const [paymentModal, setPaymentModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (tableData) {
      trigger();
    }
  }, [tableData]);

  const getSingleOrderApi = async () => {
    setLoading(true);
    const queryParams = { search: inputVal };
    getSingleOrderDetails(id, queryParams)
      .then((res) => {
        setOrderDetails(res?.data?.order);
        setSelectedOrderStatus(res?.data?.order?.status);
        setSelectedPaymentStatus(res?.data?.order?.payment_status);
      })
      .catch((err) => {
        console.error(err, "err");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSingleOrderApi();
  }, [id]);

  // Useeffect to get the project details
  useEffect(() => {
    if (orderDetails?.owner === "PROJECT" && orderDetails?.owner_id) {
      getSingleProject(orderDetails?.owner_id).then((res: any) => {
        // set project details into projectDetails state
        setProjectDetails(res?.data?.project);
      });
    }
    getOrderItems(id).then((res: any) => {
      setTableData(res.data);
    });
  }, [orderDetails?.id]);

  const handleOrderStatusChange = (value: string) => {
    setSelectedOrderStatus(value);
  };

  const handlePaymentStatusChange = (value: string) => {
    setSelectedPaymentStatus(value);
  };

  useEffect(() => {
    if (selectedPaymentStatus) {
      const payload = {
        payment_status: selectedPaymentStatus,
      };
      postPaymentStatus(id, payload)
        .then((response) => {})
        .catch((err) => {
          console.error(err);
        });
    }
  }, [selectedPaymentStatus]);
  useEffect(() => {
    if (selectedOrderStatus) {
      console.log("order-status");

      // const payload = {
      //   payment_status: selectedPaymentStatus,
      // };
      // postPaymentStatus(id, payload)
      //   .then((response) => {})
      //   .catch((err) => {
      //     console.error(err);
      //   });
    }
  }, [selectedOrderStatus]);

  const orderAppConfig = useRecoilValue(ordeAppConfigAtom);
  const orderStatusOptions = orderAppConfig?.order_status?.map(
    (status: any) => ({
      label: status,
      value: status,
    })
  );

  const paymentStatusOptions = orderAppConfig?.payment_status?.map(
    (status: any) => ({
      label: status,
      value: status,
    })
  );

  useEffect(() => {
    // const queryParams = { search: inputVal };
    getOrderTimeline(id)
      .then((res) => {
        setOrderTimeline(res.data);
      })
      .catch((err) => {
        console.error(err, "err");
      });
  }, []);

  function getDaySuffix(day: any) {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  function formatDate(dateString: any, format: string = "default") {
    const date = new Date(dateString);

    // Add IST offset (5 hours and 30 minutes)
    date.setHours(date.getHours() + 5);
    date.setMinutes(date.getMinutes() + 30);

    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "long" });
    const year = date.getFullYear();

    // Extract the day and add 'st', 'nd', or 'rd' as appropriate
    const daySuffix = getDaySuffix(day);

    const hour = date.getHours();
    const minute = date.getMinutes();
    const ampm = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 === 0 ? "12" : (hour % 12).toString();
    const formattedMinute = minute < 10 ? `0${minute}` : minute.toString();

    const formattedDate = `${day}${daySuffix} ${month} ${year}`;
    const formattedTime = `${formattedHour}:${formattedMinute} ${ampm}`;

    if (format === "date") {
      return formattedDate;
    } else if (format === "time") {
      return formattedTime;
    } else {
      return { date: formattedDate, time: formattedTime };
    }
  }

  const orderFormattedDate: any = orderDetails?.order_date
    ? formatDate(orderDetails?.order_date, "date")
    : "-";
  const orderFormattedTime: any = orderDetails?.order_date
    ? formatDate(orderDetails?.order_date, "time")
    : "-"; // Get only the time

  // Get user details
  useEffect(() => {
    if (orderDetails?.ordered_for) {
      getSingleCustomer(orderDetails?.ordered_for)
        .then((res) => {
          setUserDetails(res?.data?.customer);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [orderDetails]);

  // Get professional details
  useEffect(() => {
    if (orderDetails?.professional_id) {
      getSingleCustomer(orderDetails?.professional_id)
        .then((res) => {
          setProfessionalDetails(res?.data?.customer);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [orderDetails?.id]);

  useEffect(() => {
    getPaymentDetails(id)
      .then((res) => {
        setPaymentDetails(res.data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  const handlePaymentDetailsModal = () => {
    setPaymentModal(true);
  };

  useEffect(() => {
    if (orderDetails?.status === "DRAFT") {
      document.title = `Orders - ${orderDetails?.status}`;
    } else if (orderDetails?.order_no) {
      document.title = `Orders - ${orderDetails?.order_no}`;
    } else {
      document.title = `Orders - ...`;
    }
  }, [orderDetails?.status, orderDetails?.order_no]);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      {loading ? (
        <div className=" min-h-[90vh] flex items-center justify-center">
          <img src={loadingSpinner} alt="loading" className="h-auto w-[10vh]" />
        </div>
      ) : (
        <div className="w-full flex flex-col h-auto">
          <div className="row1 w-full flex flex-row h-full">
            <div className="w-[88%] h-auto pt-3  flex flex-col">
              <div className="w-full pl-5 pb-2">
                <div className="flex gap-7 font-medium leading-5 tracking-tighter items-center ">
                  <p className="text-[14px] font-gilroy-medium">
                    Order ID:{" "}
                    <span className="ml-1">
                      #{orderDetails?.order_no || "-"}
                    </span>
                  </p>
                  <div
                    className="flex gap-3"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="relative h-full justify-center items-center">
                      <button className="flex items-center">
                        <img src={calenderIcon} alt="calender-icon" />
                      </button>

                      {isHovered && (
                        <div className="absolute bottom-full top-[130%] left-[45%] transform -translate-x-1/2 flex justify-center items-center gap-3 px-2 py-2 h-8  text-center w-36 bg-black  text-white text-[14px] font-gilroy-bold shadow-lg rounded-md ">
                          <p className="text-[12px]">
                            Order placed at this date
                          </p>
                          <div className="tooltip-arrow"></div>
                        </div>
                      )}
                    </div>
                    <div className="flex items-center gap-3">
                      <p className="text-[#AEAEAE] text-[12px]">
                        {orderFormattedDate}
                      </p>
                      <p className="text-[#AEAEAE] text-[12px]">
                        {orderFormattedTime}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <OrderDetailsCard
                orderDetails={orderDetails}
                orderFormattedDate={orderFormattedDate}
                orderFormattedTime={orderFormattedTime}
              />
              <div className=" w-full h-full flex flex-row border-b">
                <div className="text-left flex flex-col w-[500px] max-w-[500px]  h-auto justify-between px-4 py-4 border-r">
                  <div className="flex justify-between  items-center text-start mb-3">
                    <p className="text-black font-gilroy-semi-bold text-xs">
                      Payment Info
                    </p>
                    <div>
                      <p
                        className={`cursor-pointer flex gap-3 items-center py-1 px-2 text-xs bg-pot-grey5 text-white border-2 border-pot-grey5 stroke-white ${"hover:bg-white hover:text-black hover:border-2 hover:stroke-black hover:border-pot-grey5 rounded-md "} active:bg-pot-grey2 active:text-white active:stroke-white ease-in-out transition`}
                        onClick={handlePaymentDetailsModal}
                      >
                        <TimerIcon />
                        <span>Payment History</span>
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between   items-center text-start ">
                    <p className="text-[#AEAEAE]  w-[40%] text-[12px] leading-[12px] font-gilroy-medium ">
                      No. of Items
                    </p>

                    <div className="flex justify-start gap-8 items-center w-[60%]">
                      <p className="text-[#AEAEAE] text-center">-</p>
                      <p className="text-[12px] leading-[12px] font-gilroy-semi-bold">
                        {orderDetails?.line_item_count.toLocaleString()}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between  items-center text-start ">
                    <p className="text-[#AEAEAE]  w-[40%]  text-[12px] leading-[12px] font-gilroy-medium ">
                      Base Price
                    </p>
                    <div className="flex justify-start gap-8 items-center w-[60%]">
                      <p className="text-[#AEAEAE] text-center">-</p>
                      <p className="text-[12px] leading-[12px] font-gilroy-semi-bold">
                        ₹
                        {(orderDetails?.sub_total / 100).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                          }
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between  items-center text-start ">
                    <p className="text-[#AEAEAE] text-[12px] leading-[12px] font-gilroy-medium  w-[40%]">
                      Discount (-)
                    </p>
                    <div className="flex justify-start gap-8 items-center w-[60%]">
                      <p className="text-[#AEAEAE] text-center">-</p>
                      <p className="text-[12px] leading-[12px] font-gilroy-semi-bold">
                        ₹
                        {(
                          orderDetails?.discount_professional_referral / 100
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="flex  justify-between    items-center text-start ">
                    <p className="text-[#AEAEAE]  text-[12px] leading-[12px] font-gilroy-medium  w-[40%]">
                      Total Tax
                    </p>
                    <div className="flex justify-start gap-8 items-center w-[60%] text-left">
                      <p className="text-[#AEAEAE] ">-</p>
                      <p className="text-[12px] leading-[12px] font-gilroy-semi-bold">
                        ₹
                        {(
                          (orderDetails?.tax_cgst_total +
                            orderDetails?.tax_igst_total +
                            orderDetails?.tax_sgst_total) /
                          100
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between    items-center text-start ">
                    <p className="text-[#AEAEAE]  text-[12px] leading-[12px] font-gilroy-medium  w-[40%]">
                      Grand Total
                    </p>
                    <div className="flex justify-start gap-8 items-center w-[60%]">
                      <p className="text-[#AEAEAE]  text-center">-</p>
                      <p className="text-[12px] leading-[12px] font-gilroy-semi-bold">
                        ₹
                        {(orderDetails?.grand_total / 100).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                          }
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center text-start ">
                    <p className="text-[#AEAEAE]  text-[12px] leading-[12px] font-gilroy-medium  w-[40%]">
                      Total Amount Paid
                    </p>

                    <div className="flex justify-start gap-8 items-center  w-[60%]">
                      <p className="text-[#AEAEAE] ">-</p>
                      <p className="text-[12px] leading-[12px] font-gilroy-semi-bold">
                        ₹
                        {(orderDetails?.amount_paid / 100).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                          }
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-full flex gap-5 ">
                  <div className="w-full flex justify-between">
                    <div className="flex-1 border-r">
                      <OrderAddressCard
                        orderDetails={orderDetails}
                        setOrderDetails={setOrderDetails}
                        title="Shipping Address"
                        heading={orderDetails?.address_delivery?.full_name}
                        content={[
                          { line1: orderDetails?.address_delivery?.street_1 },
                          { line2: orderDetails?.address_billing?.street_2 },
                          { city: orderDetails?.address_delivery?.city },
                          {
                            pincode:
                              orderDetails?.address_delivery?.postal_code,
                          },
                          { state: orderDetails?.address_delivery?.state },
                          { country: orderDetails?.address_billing?.country },
                        ]}
                      />
                    </div>
                    <div className="flex-1">
                      <OrderAddressCard
                        orderDetails={orderDetails}
                        setOrderDetails={setOrderDetails}
                        title="Billing Address"
                        heading={orderDetails?.address_billing?.full_name}
                        content={[
                          { line1: orderDetails?.address_billing?.street_1 },
                          { line2: orderDetails?.address_billing?.street_2 },
                          { city: orderDetails?.address_billing?.city },
                          {
                            pincode: orderDetails?.address_billing?.postal_code,
                          },
                          { state: orderDetails?.address_billing?.state },
                          { country: orderDetails?.address_billing?.country },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[22%] border-l-2 flex flex-col h-auto">
              <div className="h-full border-b">
                <OrderCreateUserDetails
                  userData={userDetails}
                  orderDetails={orderDetails}
                  formatDate={formatDate}
                />
              </div>
              <div className="h-[20%] border-b">
                <AssignPartner orderId={id}
                 orderDetails={orderDetails} 
                 getSingleOrderApi={getSingleOrderApi} 
                />
              </div>
            </div>
          </div>
          <div>
            <div className="flex flex-row h-[160px] border-b">
              <div className=" w-[88%] flex flex-row h-auto ">
                <div className="w-full h-auto flex flex-row justify-between">
                  <div className="w-[350px] max-w-[400px] p-4 flex flex-col border-r space-y-2">
                    <OrderStatusCard
                      options={orderStatusOptions}
                      title={"Order status"}
                      onChange={handleOrderStatusChange}
                      selectedValue={selectedOrderStatus} // Pass the selectedOrderStatus
                      icon={
                        <div
                          className={`w-3 h-3 ${getStatusBgColor(
                            selectedOrderStatus
                          )} rounded-full m-auto`}
                        ></div>
                      }
                    />
                    <OrderStatusCard
                      options={paymentStatusOptions}
                      title={"Payment status"}
                      onChange={handlePaymentStatusChange}
                      selectedValue={selectedPaymentStatus}
                      textBg={`${getStatusBgColor(
                        selectedPaymentStatus
                      )} px-2 py-0.5 rounded-md`}
                      // icon={<div className="w-3 h-3"></div>}
                    />
                  </div>
                  <div className="w-full p-4">
                    <OrderProjectDetails projectDetails={projectDetails} />
                  </div>
                </div>
              </div>
              <div className="w-[22%] flex flex-col h-full border-l-2">
                <TotalProfessionals
                  data={professionalDetails}
                  orderDetails={orderDetails}
                  setOrderDetails={setOrderDetails}
                />
              </div>
            </div>
            <div className="m-4">
              <OrderTimelineRequested
                data={timelineData}
                orderId={id}
                setTimelineData={setTimelineData}
                handleDelete={handleDelete}
                handleQuantity={handleQuantity}
                setTableData={setTableData}
                setOrderDetails={setOrderDetails}
                orderDetails={orderDetails}
                projectDetails={projectDetails}
              />
            </div>
            <div className="m-4">
              <ProjectDetailsTimeline
                orderTimeline={orderTimeline}
                formatDate={formatDate}
              />
            </div>
          </div>
          {paymentModal && (
            <PaymentDetails
              setPaymentModal={setPaymentModal}
              data={paymentDetails}
            />
          )}
        </div>
      )}
    </>
  );
}

export default SingleOrderDetails;
