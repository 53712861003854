import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import uploadIcon from "../assets/svg/uploadfile.svg";
import { motion } from "framer-motion";
import StarIcon from "../assets/StarIcon";
import CrossIcon from "../assets/CrossIcon";
import { protectedAxiosInstance } from "../api/axiosManagement";
import LoadingSpinner from "../assets/images/LoadingSpinner.gif";
import { toast } from "react-toastify";
import ErrorMessage from "./ErrorMessage";

type ImageUploadPropType = {
  isMulti?: boolean;
  kind: "PRODUCT" | "CATEGORY" | "CP-LOGO" | "ITEM_PRODUCT";
  setter: (values: string | string[]) => void;
  imageList: string[];
  primary?: string;
  primarySetter?: (value: string) => void;
  errors?: string[];
  removeError?: () => void;
  maxSize?: number;
  disabled?: boolean;
  required?: boolean;
  label?: string | undefined;
  targetRef?: any;
  isSelectTypeButton?: boolean;
};

/**
 *
 * for imageList in single upload memoize it with an array because it only accept as an array
 * ie. const imageList = useMemo(() => [image], [image])
 */
const ImageUpload = ({
  isMulti = false,
  kind,
  setter,
  imageList,
  primary = "",
  primarySetter = () => {},
  errors = [],
  removeError = () => {},
  maxSize = 20,
  disabled = false,
  required = false,
  label,
  targetRef,
  isSelectTypeButton = false,
}: ImageUploadPropType) => {
  const [showUploadDiv, setShowUploadDiv] = useState(true);
  const [primaryId, setPrimaryId] = useState(0);
  const [mouseOnImage, setMouseOnImage] = useState("");
  const [loading, setLoading] = useState(false);

  const images = Array.from({ length: 10 }).map(
    () => "categories/dd204d65-633e-48f3-8d67-56f14e786b88.jpg"
  );

  let moduleUrl: any;

  if (kind === "CP-LOGO") {
    moduleUrl = "accounts";
  } else if (kind === "ITEM_PRODUCT") {
    moduleUrl = "orders";
  } else {
    moduleUrl = "products";
  }

  const get_signed = async (extension: string) => {
    const payload = {
      extension,
      kind,
    };
    const { data: response } = await protectedAxiosInstance.post(
      `${process.env.REACT_APP_API_DOMAIN}/admin/${moduleUrl}/generate-upload-url`, //eslint-disable-line
      payload
    );

    return response.data;
  };

  const signed_to_s3 = async (acceptedFile: File) => {
    const extension = acceptedFile.name.split(".").at(-1)?.toLowerCase();
    const { file_path, upload_url } = await get_signed(extension as string);

    const file = new FormData();
    file.append("file", acceptedFile);

    let contentType: string;

    if (extension === "jpeg" || extension === "jpg") {
      contentType = "image/jpeg";
    } else if (extension === "png") {
      contentType = "image/png";
    } else if (extension === "webp") {
      contentType = "image/webp";
    } else {
      contentType = "";
    }

    const response = await fetch(upload_url, {
      method: "PUT",
      body: acceptedFile,
      headers: {
        "Content-Type": contentType,
      },
    });

    return { response, file_path };
  };

  const onDrop = async (acceptedFiles: File[], rejectedFiles: any) => {
    // Handle rejected files for size and type errors
    if (
      rejectedFiles.some((file: any) =>
        file.errors?.some((error: any) => error.code === "file-too-large")
      )
    ) {
      toast.error("File size exceeded.");
    }

    if (
      rejectedFiles.some((file: any) =>
        file.errors?.some((error: any) => error.code === "file-invalid-type")
      )
    ) {
      toast.error(
        "Invalid file format selected. Only .jpg, .jpeg, .webp, .png are accepted."
      );
    }

    // Return early if no accepted files
    if (acceptedFiles.length === 0) return;

    setLoading(true);

    // Handle any existing errors
    if (errors.length) {
      removeError();
    }

    // Check for oversized files
    const maxSizeBytes = maxSize * 1024 * 1024;
    const oversizedFiles = acceptedFiles.filter(
      (file) => file.size > maxSizeBytes
    );

    if (oversizedFiles.length > 0) {
      toast.error("File exceed the maximum size limit.");
      setLoading(false);
      return;
    }

    const validFiles: File[] = [];
    const invalidFiles: File[] = [];

    // const checkFileDimensions = (file: File): Promise<boolean> => {
    //   return new Promise((resolve) => {
    //     const reader = new FileReader();

    //     reader.onload = (event: ProgressEvent<FileReader>) => {
    //       if (event.target?.result) {
    //         const img = new Image();

    //         img.onload = () => {
    //           const { naturalWidth, naturalHeight } = img;
    //           resolve(naturalWidth === naturalHeight);
    //         };

    //         img.src = event.target.result as string;
    //       } else {
    //         resolve(false);
    //       }
    //     };

    //     reader.readAsDataURL(file);
    //   });
    // };

    // Check dimensions of each file
    for (const file of acceptedFiles) {
      // const isValid = await checkFileDimensions(file);
      validFiles.push(file);
      // if (isValid) {
      // validFiles.push(file);
      // } else {
      //   invalidFiles.push(file);
      // }
    }

    if (invalidFiles.length > 0) {
      const message = `Please upload square image${
        isMulti ? "s" : ""
      } with a 1:1 ratio. The best size is 1080 x 1080 pixels.`;
      toast.error(message);
    }

    if (validFiles.length === 0) {
      setLoading(false);
      return;
    }

    // Upload the valid files
    try {
      if (!showUploadDiv) return;

      if (!isMulti) {
        const { response, file_path } = await signed_to_s3(validFiles[0]);
        setter(file_path);
        setShowUploadDiv(false);
      } else {
        const pathList = await Promise.all(
          validFiles.map(async (file) => {
            const { response, file_path } = await signed_to_s3(file);
            return file_path;
          })
        );
        setter([...(imageList || []), ...pathList]);

        if (!primary) {
          primarySetter(pathList[0]);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (image: string) => {
    if (errors.length) {
      removeError();
    }
    if (isMulti) {
      const filteredList = imageList.filter((each) => each !== image);

      if (primary === image) {
        if (filteredList.length) {
          primarySetter(filteredList[0]);
        } else {
          primarySetter("");
        }
      }
      setter(filteredList);
    } else {
      setter("");
      primarySetter("");
      setShowUploadDiv(true);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      accepted: [".jpg", ".jpeg", ".png", ".webp"],
    },
    onDrop,
    maxSize: 20 * 1024 * 1024,
  });

  useEffect(() => {
    if (imageList?.length && !isMulti) {
      setShowUploadDiv(false);
    }
  }, [imageList]);
  return (
    <div ref={targetRef} className="min-h-[140px]">
      {label && (
        <div className="flex items-center text-center pb-1 text-[12px] gap-2">
          <h4 className="text-[12px] font-gilroy-bold">
            {label}
            {required && <span className="text-red-500 text-xs ml-1">*</span>}
          </h4>
        </div>
      )}
      {showUploadDiv && !loading && (
        <div
          {...getRootProps()}
          className={`flex items-center justify-center w-full mb-2 ${
            disabled ? " opacity-30" : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {isSelectTypeButton ? (
            <label>
              <>
                <div className="cursor-pointer flex items-center justify-center bg-[#E4E4E4] rounded-md w-[145px] h-[40px]">
                  <span className="font-gilroy-semi-bold text-sm">
                    Upload File
                  </span>
                </div>
                <input {...getInputProps()} />
                <span className="text-[10px] font-gilroy-regular leading-4">
                  File supported : png/jpeg/jpg.
                </span>
              </>
            </label>
          ) : (
            <div>
              <label
                className={`flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed  bg-white  ${
                  disabled
                    ? "cursor-not-allowed"
                    : "cursor-pointer hover:bg-gray-100"
                }`}
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6 px-10 gap-1">
                  <img src={uploadIcon} alt="upload file" />
                  <p className="text-sm text-gray-500">
                    Drag & Drop or{" "}
                    <span className="font-gilroy-bold text-pot-yellow">
                      Choose
                    </span>{" "}
                    file to upload{" "}
                  </p>
                  <p className="text-xs text-gray-500">
                    Upload JPEG, JPG, PNG, WEBP (Max file size: 20 MB)
                  </p>
                  {/* <p className="text-xs text-gray-500">
                Please upload square image{isMulti ? "s" : ""} with a 1:1 ratio.
                The best size is 1080 x 1080 pixels.
              </p> */}
                </div>
                <input {...getInputProps()} />
              </label>
            </div>
          )}
        </div>
      )}

      <ErrorMessage error={errors} />

      {loading ? (
        <img src={LoadingSpinner} alt="spinner" className="h-[20%] w-[20%]" />
      ) : (
        <div className="grid grid-cols-5 w-full gap-3">
          {imageList?.map((each) => {
            return (
              <div
                onMouseEnter={() => setMouseOnImage(each)}
                onMouseLeave={() => setMouseOnImage("")}
                onClick={() => {
                  primarySetter(each);
                }}
                key={each}
                className={`p-1 relative border-2 border-white`}
              >
                {isMulti && each === primary && (
                  <motion.div
                    layoutId="primary_image"
                    className="absolute top-0 left-0 w-full h-full shadow-[0px_0px_0px_4px_#D4A15E]"
                  >
                    <div className="absolute top-[1px] left-[1px] w-fit h-fit bg-pot-yellow text-white rounded-full text-[7px] px-1 py-1">
                      <StarIcon color="white" />
                    </div>
                  </motion.div>
                )}
                {mouseOnImage === each && (
                  <div className="absolute top-0 left-0 w-full h-full ">
                    <motion.button
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleDelete(each);
                      }}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="scale-75 absolute z-20 -top-1 -right-1 w-fit h-fit stroke-white p-2 bg-black rounded-full"
                    >
                      <CrossIcon />
                    </motion.button>
                  </div>
                )}
                <img
                  src={`${process.env.REACT_APP_BUCKET}/${each}`} //eslint-disable-line
                  alt="picture"
                  className="w-full h-full object-contain"
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
