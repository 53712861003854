import React, { useEffect, useState } from "react";
import editPen from "../../../../../assets/svg/pen.svg";
import { Accordian } from "../../../../../components/Accordian";
import { useParams } from "react-router-dom";
import { getCustomersAddressList } from "../../../../../api/orderManagement";
import AddressForm from "../../../../../components/AddressForm";

// Define interface for address data based on the sample payload
interface AddressType {
  id: string;
  full_name: string;
  phone: string;
  street_1: string;
  street_2?: string;
  city: string;
  state: string;
  country: string;
  postal_code: string;
  kind: "SHIPPING" | "BILLING";
  tag: "HOME" | "WORK" | "OTHERS";
}

function Address() {
  const [loading, setLoading] = useState(false);
  const [addressData, setAddressData] = useState<AddressType[]>([]);
  const [openAddressForm, setOpenAddressForm] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const { id: customerID } = useParams();

  const fetchAddresses = async () => {
    setLoading(true);
    try {
      const res = await getCustomersAddressList(customerID);
      setAddressData(res.data.address);
    } catch (error) {
      console.error("Error fetching address list:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, [customerID]);

  const handleAddAddress = () => {
    setSelectedAddress(null);
    setOpenAddressForm(true);
  };

  const handleEditAddress = (address: AddressType) => {
    setSelectedAddress(address);
    setOpenAddressForm(true);
  };

  return (
    <>
      <div className="relative">
        <Accordian
          label="Add address"
          heading="+ Add address"
          onClick={handleAddAddress}
        >
          <div>
            <div className="grid grid-cols-3">
              {!addressData.length ? (
                <div className="col-span-3 p-4">
                  <p className="text-center font-gilroy-semi-bold">
                    No Address
                  </p>
                </div>
              ) : (
                <>
                  {addressData?.map((address) => (
                    <div
                      key={address.id}
                      className="border-x border-y px-5 py-5"
                    >
                      <div className="flex justify-between pb-3">
                        <p className="bg-[#EDEDED] text-[14px] font-medium px-2 py-0.5 text-[#4E4E4E] rounded-md">
                          {address.tag}
                        </p>
                        <button
                          onClick={() => handleEditAddress(address)}
                          className="hover:opacity-80"
                        >
                          <img src={editPen} alt="edit-pen" />
                        </button>
                      </div>

                      <div className="ml-1 flex flex-col text-[11px] leading-5 tracking-[-0.08px] text-start">
                        <div className="flex flex-row gap-4">
                          <p className="font-medium">{address.full_name}</p>
                          <p className="text-gray-600">{address.phone}</p>
                        </div>
                        <p>{address.street_1}</p>
                        {address.street_2 && <p>{address.street_2}</p>}
                        <p>
                          {address.city}, {address.state}
                        </p>
                        <p>{address.postal_code}</p>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </Accordian>

        {openAddressForm && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <AddressForm
              addressData={selectedAddress}
              setOpenInputAddress={setOpenAddressForm}
              setTargetAddress={setSelectedAddress}
              customerId={customerID || ""}
              getAddressList={fetchAddresses}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default Address;
