import HipchatChevronLeftIcon from "../../../assets/HipchatChevronLeftIcon";
import HipchatChevronRightIcon from "../../../assets/HipchatChevronRightIcon";
import React, { useEffect, useRef, useState } from "react";
import Button from "../../../components/Button";
import TrashIcon from "../../../assets/TrashIcon";
import PlusIcon from "../../../assets/PlusIcon";
import LoadingSpinner from "../../../assets/images/LoadingSpinner.gif";
import CarretIcon from "../../../assets/svg-tsx/CarretIcon";
import {
  applyLightningControlCategoryColor,
  formattedPrice,
} from "../../../utils/helper";
import { quickListAtom } from "../../../atom";
import { AreaType, ProjectsType } from "../../../types";
import { CartProjectsAtom } from "../../../atom";
import { useRecoilState } from "recoil";
import { Link, useNavigate } from "react-router-dom";
import { FloorType } from "../../../types";
import useScrollOutside from "../../../hooks/useScrollOutside";
import useClickOutside from "../../../hooks/useClickOutside";
import { protectedAxiosInstance } from "../../../api/axiosManagement";
import FolderIcon from "../../../assets/svg-tsx/FolderIcon";
import DropdownBox from "../../../components/ProjectSetter/DropdownBox";
import { toast } from "react-toastify";

export default function ProjectQuotationTable({
  data,
  setCartSpecificProjectData,
  selectedProject,
  floorSelector,
  calculateCost,
  enableCheckout,
  cartSpecificData,
  setCartSpecificData,
  projectSelectionIndex,
  setProjectSelectionIndex,
  cartSpecificProjectData,
  showBanner,
  loading,
  bottomBarHeight,
  quotationId,
  getSingleQuotationCartItems,
  projectId,
}: any) {
  const [floor, setFloor] = useState<FloorType | null>(null);
  const [project, setProject] = useState<ProjectsType | null>(null);
  const [area, setArea] = useState<AreaType | null>(null);
  //   // const [options, setOptions] = useState<any>(null);
  const [quickList, setQuickList] = useRecoilState(quickListAtom);
  //   const [stage, setStage] = useState(-1);
  const [openProjectSelection, setOpenProjectSelection] = useState(false);
  const [currentRow, setCurrentRow] = useState<any>();

  const [dropdownPosition, setDropdownPosition] = useState<any>();
  const actionRefs = useRef<any>([]);
  const dropDownRef = useRef<any>(null);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [actionType, setActionType] = useState<string>("move");
  const [sequenceChangeLoading, setSequenceChangeLoading] = useState(false);
  const [floorSequenceLoading, setFloorSequenceLoading] = useState(false);
  const [areaSequenceLoading, setAreaSequenceLoading] = useState(false);

  function updateActionType(actionName: string) {
    setActionType(actionName);
  }

  function closeProjectSelection() {
    setOpenProjectSelection(false);
  }

  const calculateItemIndex = (itemId: any) => {
    // Flatten the data array to the level of individual items
    const flattenedItems = data?.flatMap((floor: any) =>
      floor.areas.flatMap((area: any) => area.items)
    );
    // Find the index of the item with the given itemId
    const itemIndex = flattenedItems.findIndex(
      (item: any) => item.id === itemId
    );
    return itemIndex;
  };

  const handleActionClick = (item: any) => {
    const index = calculateItemIndex(item?.id);
    setCurrentRow(item.id);

    const midpoint = window.innerHeight / 2;

    const folderIconPosition =
      actionRefs.current[index].getBoundingClientRect().top - window.scrollY;

    if (folderIconPosition < midpoint) {
      setDropdownPosition({
        top:
          actionRefs.current[index].getBoundingClientRect().top -
          window.scrollY -
          (dropDownRef.current ? dropDownRef.current.offsetHeight : 0),

        right:
          window.innerWidth -
          actionRefs.current[index].getBoundingClientRect().right +
          window.scrollX,
        openAt: "top",
      });
    } else {
      setDropdownPosition({
        bottom:
          window.innerHeight -
          actionRefs.current[index].getBoundingClientRect().top +
          window.scrollY,

        right:
          window.innerWidth -
          actionRefs.current[index].getBoundingClientRect().right +
          window.scrollX,
        openAt: "bottom",
      });
    }

    setOpenProjectSelection(true);
  };

  useClickOutside(dropDownRef, () => {
    setOpenProjectSelection(false);
    setProjectSelectionIndex(null);
  });
  useScrollOutside(dropDownRef, () => {
    setOpenProjectSelection(false);

    setProjectSelectionIndex(null);
  });

  useEffect(() => {
    protectedAxiosInstance
      .get("/admin/quotation")
      .then((res) => {
        setQuickList(res?.data?.data?.quotations);
      })
      .catch((err) => {
        console.error("Error getting quickList data", err);
      });
  }, []);

  useEffect(() => {
    if (quickList?.length > 0) {
      setProject((prev: any) => {
        return quickList?.find((el: any) => el.id === selectedProject);
      });
    }
  }, [openProjectSelection]);

  const handleDelete = (id: any) => {
    protectedAxiosInstance
      .delete(`/admin/quotation/${quotationId}/item/${id}`)
      .then(() => {
        getSingleQuotationCartItems(quotationId);
        toast.success("Item Deleted Successfully");
      })
      .catch((error) => {
        toast.error("Error in Deleting Item");
      });
  };

  const handleFloorSequenceChange = (sequence: any, floorId: any) => {
    setFloorSequenceLoading(true);
    const userType: any = {};
    userType.owner = "PROJECT";
    userType.owner_id = selectedProject;

    const payload = {
      floors: [
        {
          id: floorId,
          sequence: sequence,
        },
      ],
    };

    protectedAxiosInstance
      .post(`/projects/${projectId}/change-floor-sequence`, payload)
      .then((res: any) => {
        getSingleQuotationCartItems(quotationId);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setFloorSequenceLoading(false);
      });
  };

  const handleAreaSequence = (sequence: any, areaId: any, floorId: any) => {
    setAreaSequenceLoading(true);

    const payload = {
      areas: [
        {
          id: areaId,
          sequence: sequence,
        },
      ],
    };

    protectedAxiosInstance
      .post(`/projects/${projectId}/change-area-sequence/${floorId}`, payload)
      .then((res: any) => {
        getSingleQuotationCartItems(quotationId);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setAreaSequenceLoading(false);
      });
  };

  const handleItemSequenceChange = (
    sequence: any,
    itemId: any,
    quantity: any,
    floorId: any,
    areaId: any
  ) => {
    setSequenceChangeLoading(true);

    protectedAxiosInstance
      .put(`/admin/quotation/${quotationId}/item/${itemId}`, {
        sequence,
        quantity,
        floor_id: floorId,
        area_id: areaId,
      })
      .then((res) => {
        setSequenceChangeLoading(false);
        getSingleQuotationCartItems(quotationId);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSequenceChangeLoading(false);
      });
  };

  // new implementation
  const timeoutRef = useRef<any>({});

  const handleQuantityChange = (
    id: any,
    value: any,
    floorId: any,
    areaId: any
  ) => {
    const updatedCartData = data?.map((floor: any) => {
      // Map through each floor's areas
      const allAreas = floor?.areas?.map((area: any) => {
        // Map through each area's items and update the quantity if the id matches
        const updatedItems = area?.items?.map((item: any) => {
          // Check if the current item ID matches the target ID
          if (item.id === id) {
            // Update the quantity and return the updated item
            return { ...item, quantity: item?.quantity + value };
          }
          // Return the item as is if the ID doesn't match
          return item;
        });

        // Return the area with the updated items
        return { ...area, items: updatedItems };
      });

      // Return the floor with the updated areas
      return { ...floor, areas: allAreas };
    });

    // Find the last (current) value for the specific item
    const lastValue = updatedCartData
      .flatMap((floor: any) => floor.areas)
      .flatMap((area: any) => area.items)
      .find((item: any) => item.id === id)?.quantity;

    setCartSpecificProjectData(updatedCartData);

    if (timeoutRef.current[id]) {
      clearTimeout(timeoutRef.current[id]);
    }

    timeoutRef.current[id] = setTimeout(() => {
      updateQuantity(id, lastValue, floorId, areaId);
    }, 500);
  };

  const updateQuantity = async (
    id: any,
    quantity: any,
    floorId: any,
    areaId: any
  ) => {
    try {
      await protectedAxiosInstance.put(
        `/admin/quotation/${quotationId}/item/${id}`,
        { quantity, floor_id: floorId, area_id: areaId }
      );
      calculateCost();
    } catch (error) {
      console.error("Error updating quantity : ", error);
    }
  };
  // new implementation

  useEffect(() => {
    if (!openProjectSelection) {
      setFloor(null);
      setProject(null);
      setArea(null);
    }
  }, [openProjectSelection]);

  if (loading) {
    return (
      <div className="h-[80%] flex items-center justify-center">
        <img
          src={LoadingSpinner}
          alt="Loading spinner"
          width={70}
          height={70}
        />
      </div>
    );
  }

  if (!loading && data?.length === 0) {
    return (
      <p
        className={`flex justify-center items-center h-auto  ${
          showBanner ? "my-[15dvh]" : "mt-[25dvh]"
        } text-gray-500 text-md`}
      >
        {" "}
        There are no items in this cart
      </p>
    );
  }

  let showTrackShape = false;
  let showTrackDimension = false;
  let showControllerQuantity = false;

  data?.map((el: any, floorIndex: number, floorArr: any) => {
    el?.areas?.map((area: any) => {
      area?.items?.map((item: any) => {
        if (item?.cart_properties?.track_shape) {
          showTrackShape = true;
        }
        if (item?.cart_properties?.track_dimension) {
          showTrackDimension = true;
        }
        if (item?.cart_properties?.track_driver_quantity) {
          showControllerQuantity = true;
        }
      });
    });
  });

  const showArrowsOnFloor = data?.length > 1;

  // Calculate running count for numbering within a floor
  const calculateRunningCountInFloor = (
    floor: any,
    currentAreaIndex: number,
    currentItemIndex: number
  ) => {
    let count = 0;

    floor.areas?.forEach((area: any, areaIndex: number) => {
      if (areaIndex < currentAreaIndex) {
        // Count all items in previous areas
        area.items?.forEach((item: any) => {
          if (item.part_list?.length > 0) {
            // For items with part list, only count the part list items (skip header)
            count += item.part_list.length;
          } else {
            // For regular items without part list, count them
            count++;
          }
        });
      } else if (areaIndex === currentAreaIndex) {
        // Count items up to current item in current area
        area.items?.forEach((item: any, itemIndex: number) => {
          if (itemIndex < currentItemIndex) {
            if (item.part_list?.length > 0) {
              // For items with part list, only count the part list items (skip header)
              count += item.part_list.length;
            } else {
              // For regular items without part list, count them
              count++;
            }
          }
        });
      }
    });

    return count;
  };

  return (
    <div
      className="text-sm mx-4 text-gray-700 min-h-[700px] pb-0 overflow-y-clip custom-scrollbar"
      ref={scrollContainerRef}
    >
      <table className="mb-[6rem] w-full border border-c-gray-4  border-collapse text-[0.7rem] text-center  table-auto">
        <tbody>
          {data?.map((el: any, floorIndex: number, floorArr: any) => {
            let totalItemIndex = 0;
            const isFirstFloor = floorIndex === 0;
            const isLastFloor = floorIndex === data?.length - 1;

            const floorSequenceIndex = floorIndex + 1;
            const floorId = el?.id;
            return (
              <React.Fragment key={el?.id}>
                {/* Floor Name */}
                <tr>
                  <td colSpan={20}>
                    <div className="w-full relative text-[14px] leading-[16.41px] bg-pot-black text-white py-1.5 font-gilroy-bold text-center ">
                      <div className="absolute z-20 left-2 top-1/2 transform -translate-y-1/2 flex">
                        {floorSequenceLoading ? (
                          <div className="w-[30px]">
                            <img
                              src={LoadingSpinner}
                              alt="Loading Spinner"
                              width={20}
                              height={20}
                              className="w-8 h-8"
                            />
                          </div>
                        ) : (
                          showArrowsOnFloor && (
                            <>
                              <button
                                className="text-white rotate-180 disabled:text-opacity-50 disabled:cursor-not-allowed"
                                type="button"
                                disabled={isFirstFloor}
                                onClick={() =>
                                  handleFloorSequenceChange(
                                    floorSequenceIndex - 1,
                                    el?.id
                                  )
                                }
                              >
                                <CarretIcon />
                              </button>
                              <button
                                className="text-white disabled:text-opacity-50 disabled:cursor-not-allowed"
                                type="button"
                                disabled={isLastFloor}
                                onClick={() =>
                                  handleFloorSequenceChange(
                                    floorSequenceIndex + 1,
                                    el?.id
                                  )
                                }
                              >
                                <CarretIcon />
                              </button>
                            </>
                          )
                        )}
                      </div>
                      <p className=" sticky left-0 max-w-[96vw]">
                        {el?.name ? el?.name : "No Floor"}
                      </p>
                    </div>
                  </td>
                </tr>

                {/* Heading */}
                <tr className="border border-c-gray-4 [&>th]:px-2 [&>th]:py-1 font-gilroy-semi-bold [&>th]:border [&>th]:border-c-gray-4 [&>th]:whitespace-nowrap [&>th]:bg-c-gray-3">
                  <th>No.</th>
                  <th>Area</th>
                  <th>Code</th>
                  <th>Product Name</th>
                  <th>Image</th>
                  <th>Wattage</th>

                  <th>Control Type</th>

                  {showTrackShape && <th>Profile Shape</th>}

                  {showTrackDimension && <th>Profile Dimension</th>}
                  {showControllerQuantity && <th>Controller Quantity</th>}
                  <th>Light Colour</th>
                  <th>Body Colour</th>
                  <th>Reflector Colour</th>

                  <th>Unit Price</th>
                  <th>Quantity</th>
                  <th>Total Price</th>
                  <th>Action</th>
                </tr>
                {el.areas?.map((area: any, areaIndex: number, areaArr: any) => {
                  const isFirstArea = areaIndex === 0;
                  const isLastArea = areaIndex === el?.areas?.length - 1;

                  const areaSequenceIndex = areaIndex + 1;
                  const areaId = area?.id;
                  const showArrowOnAreas = el?.areas?.length > 1;
                  return (
                    <>
                      {area.items.map((item: any, itemIndex: any, arr: any) => {
                        totalItemIndex += 1;
                        const isFirstItem = itemIndex === 0;
                        const isLastItem =
                          itemIndex === area?.items?.length - 1;
                        const itemSequenceIndex = itemIndex + 1;
                        const showArrowOnItems = area?.items?.length > 1;

                        const calculatePreviousPartListCount = (
                          currentIndex: number
                        ) => {
                          let count = 0;
                          for (let i = 0; i < currentIndex; i++) {
                            if (area?.items[i]?.part_list?.length > 0) {
                              count += area?.items[i].part_list.length - 1; // Subtract 1 because the header counts as one item
                            }
                          }
                          return count;
                        };

                        const previousPartListCount =
                          calculatePreviousPartListCount(itemIndex);

                        const calculateSerialNumber = (
                          floorIndex: number,
                          areaIndex: number,
                          itemIndex: number,
                          partIndex?: number,
                          hasPartList: boolean = false
                        ) => {
                          // Get running count within current floor
                          const runningCount = calculateRunningCountInFloor(
                            data[floorIndex],
                            areaIndex,
                            itemIndex
                          );

                          if (hasPartList) {
                            // This is a header row for an item with part list - don't show number
                            return "";
                          } else if (partIndex !== undefined) {
                            // This is a part list item
                            return runningCount + partIndex + 1;
                          } else {
                            // This is a regular item without part list
                            return runningCount + 1;
                          }
                        };

                        const calculateTotalRowsInArea = (items: any) => {
                          return items.reduce((total: any, item: any) => {
                            // Check if part_list exists and has elements
                            if (
                              Array.isArray(item.part_list) &&
                              item.part_list.length > 0
                            ) {
                              return total + 1 + item.part_list.length + 1; // Item + parts
                            }
                            return total + 1; // Only item
                            // return item?.length + item?.part_list?.length;
                          }, 0);
                        };

                        const {
                          body_color,
                          code,
                          light_color,
                          lighting_control,
                          lighting_control_category,
                          reflector_color,
                          track_dimension,
                          track_driver_quantity,
                          track_shape,
                          wattage,
                        } = item?.cart_properties || {};

                        const srBackgroundColor =
                          applyLightningControlCategoryColor(
                            lighting_control_category
                          );

                        if (item?.part_list?.length > 0) {
                          // Calculate total rows in area including part lists
                          const totalRowsInArea = arr.reduce(
                            (total: number, currentItem: any) => {
                              return (
                                total + (currentItem.part_list?.length || 0) + 1
                              );
                            },
                            0
                          );
                          return (
                            <React.Fragment key={itemIndex}>
                              {/* header */}
                              <tr className="bg-c-gray-1 border border-c-gray-4 [&>td]:border [&>td]:border-c-gray-4 [&>td]:whitespace-nowrap [&>td]:px-2 [&>td]:py-1 h-[68px] group">
                                {/* No. */}
                                <td></td>

                                {/* Area column - only show for first item in area */}
                                {isFirstItem ? (
                                  <td rowSpan={calculateTotalRowsInArea(arr)}>
                                    <div className="flex justify-center items-center">
                                      <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                                        {areaSequenceLoading ? (
                                          <div className="w-[20px]">
                                            <img
                                              src={LoadingSpinner}
                                              alt="Loading Spinner"
                                              width={20}
                                              height={20}
                                              className="w-8 h-8"
                                            />
                                          </div>
                                        ) : (
                                          showArrowOnAreas && (
                                            <div>
                                              <button
                                                className="text-[#434343] rotate-180 disabled:text-opacity-50 disabled:cursor-not-allowed"
                                                type="button"
                                                disabled={isFirstArea}
                                                onClick={() =>
                                                  handleAreaSequence(
                                                    areaSequenceIndex - 1,
                                                    areaId,
                                                    floorId
                                                  )
                                                }
                                              >
                                                <CarretIcon />
                                              </button>
                                              <button
                                                className="text-[#434343] disabled:text-opacity-50 disabled:cursor-not-allowed"
                                                type="button"
                                                disabled={isLastArea}
                                                onClick={() =>
                                                  handleAreaSequence(
                                                    areaSequenceIndex + 1,
                                                    areaId,
                                                    floorId
                                                  )
                                                }
                                              >
                                                <CarretIcon />
                                              </button>
                                            </div>
                                          )
                                        )}
                                      </div>
                                      <p>{area?.name}</p>
                                    </div>
                                  </td>
                                ) : null}

                                <td></td>

                                {/* product name and details */}
                                <td colSpan={10} className="text-black">
                                  <div className="flex items-center justify-center">
                                    <div className="mr-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                                      {sequenceChangeLoading ? (
                                        <div className="w-[30px]">
                                          <img
                                            src={LoadingSpinner}
                                            alt="Loading Spinner"
                                            width={20}
                                            height={20}
                                            className="w-7 h-7"
                                          />
                                        </div>
                                      ) : (
                                        showArrowOnItems && (
                                          <div>
                                            <button
                                              className="text-[#434343] rotate-180 disabled:text-opacity-50 disabled:cursor-not-allowed"
                                              type="button"
                                              disabled={isFirstItem}
                                              onClick={() =>
                                                handleItemSequenceChange(
                                                  itemSequenceIndex - 1,
                                                  item?.id,
                                                  item?.quantity,
                                                  floorId,
                                                  areaId
                                                )
                                              }
                                            >
                                              <CarretIcon />
                                            </button>
                                            <button
                                              className="text-[#434343] disabled:text-opacity-50 disabled:cursor-not-allowed"
                                              type="button"
                                              disabled={isLastItem}
                                              onClick={() =>
                                                handleItemSequenceChange(
                                                  itemSequenceIndex + 1,
                                                  item?.id,
                                                  item?.quantity,
                                                  floorId,
                                                  areaId
                                                )
                                              }
                                            >
                                              <CarretIcon />
                                            </button>
                                          </div>
                                        )
                                      )}
                                    </div>
                                    <div>
                                      <p>
                                        <span className="font-gilroy-bold">
                                          {item?.product?.name} -
                                        </span>
                                        <span className="capitalize">
                                          {" "}
                                          ({" "}
                                          {
                                            item?.cart_properties
                                              ?.lighting_control
                                          }{" "}
                                          {item?.cart_properties
                                            ?.lighting_control && "- "}
                                          {item?.cart_properties?.track_shape} -{" "}
                                          {
                                            item?.cart_properties
                                              ?.track_dimension
                                          }
                                          )
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </td>

                                {/* Unit Price */}
                                <td>
                                  <div className="flex gap-2 justify-center">
                                    <p className="min-w-[80px]">
                                      {/* {item.price_unit.toLocaleString("en-IN")} */}
                                      {formattedPrice(item?.price_unit)}
                                    </p>
                                  </div>
                                </td>

                                {/* Quantity */}
                                <td>
                                  <div className="flex gap-2 justify-center items-center text-[1.2rem]">
                                    {item?.quantity > 1 ? (
                                      <div className="h-8 w-8 ">
                                        <Button
                                          disabled={item?.quantity === 1}
                                          variant="secondary-outline"
                                          label="-"
                                          onClick={() =>
                                            handleQuantityChange(
                                              item?.id,
                                              -1,
                                              floorId,
                                              areaId
                                            )
                                          }
                                          //   fullHeight={true}
                                          fullWidth={true}
                                          rounded={false}
                                          className="!h-8 !w-8"
                                          multiClickAllowed
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="px-[8px] py-[8px] h-8 w-8  flex md:gap-x-[1vw] justify-center items-center border-2 border-black cursor-pointer"
                                        onClick={() => handleDelete(item?.id)}
                                      >
                                        <TrashIcon />
                                      </div>
                                    )}
                                    <p className="w-8 font-gilroy-medium text-[12px] leading-[20px]">
                                      {item?.quantity}
                                    </p>
                                    <div className="h-8 w-8 ">
                                      <Button
                                        variant="secondary"
                                        label={
                                          <PlusIcon
                                            width="18px"
                                            height="18px"
                                            color="#FFFFFF"
                                          />
                                        }
                                        onClick={() =>
                                          handleQuantityChange(
                                            item?.id,
                                            1,
                                            floorId,
                                            areaId
                                          )
                                        }
                                        fullWidth={true}
                                        rounded={false}
                                        className="!h-8 !w-8"
                                        multiClickAllowed
                                      />
                                    </div>
                                  </div>
                                </td>

                                {/* Total Price */}
                                <td>
                                  <div className="flex gap-2 justify-center">
                                    <p className="min-w-[80px]">
                                      <span className="">&#x20B9;</span>
                                      {formattedPrice(
                                        item?.price_unit * item?.quantity || 0
                                      )}
                                    </p>
                                  </div>
                                </td>

                                {/* Actions */}
                                <td>
                                  <div className="flex gap-2 justify-center items-center relative">
                                    <div
                                      className="flex justify-center items-center mx-1 cursor-pointer"
                                      onClick={() => {
                                        handleActionClick(item);
                                      }}
                                      ref={(ref) => {
                                        actionRefs.current[
                                          calculateItemIndex(item?.id)
                                        ] = ref;
                                      }}
                                    >
                                      <FolderIcon />
                                    </div>
                                    {openProjectSelection &&
                                      currentRow === item?.id && (
                                        <div
                                          className="fixed z-50 "
                                          style={
                                            dropdownPosition?.openAt === "top"
                                              ? {
                                                  top: dropdownPosition.top,
                                                  right: dropdownPosition.right,
                                                }
                                              : {
                                                  bottom:
                                                    dropdownPosition.bottom,
                                                  right: dropdownPosition.right,
                                                }
                                          }
                                          ref={dropDownRef}
                                        >
                                          <DropdownBox
                                            type="project"
                                            mouseLocation={0}
                                            data={quickList}
                                            selected={{
                                              project,
                                              floor,
                                              area,
                                              setArea,
                                              setFloor,
                                              setProject,
                                            }}
                                            actionType={actionType}
                                            updateActionType={updateActionType}
                                            showCopyItem={true}
                                            quotationId={quotationId}
                                            itemId={item?.id}
                                            itemCount={item?.quantity}
                                            getSingleQuotationCartItems={
                                              getSingleQuotationCartItems
                                            }
                                            closeProjectSelection={
                                              closeProjectSelection
                                            }
                                          />
                                        </div>
                                      )}

                                    <div
                                      className="flex justify-center items-center mx-1 cursor-pointer"
                                      onClick={() => handleDelete(item?.id)}
                                    >
                                      <TrashIcon width="16" height="16" />
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              {/* part list */}
                              {item?.part_list?.map((part: any, index: any) => {
                                return (
                                  <tr
                                    className="border border-c-gray-4 [&>td]:border [&>td]:border-c-gray-4 [&>td]:whitespace-nowrap [&>td]:px-2 [&>td]:py-1 group hover:!bg-c-gray-1"
                                    key={index}
                                  >
                                    {/* No. */}
                                    <td
                                      style={{
                                        backgroundColor: srBackgroundColor,
                                      }}
                                    >
                                      {/* <p>{+itemIndex + index + 1}</p> */}
                                      <p>
                                        {calculateSerialNumber(
                                          floorIndex,
                                          areaIndex,
                                          itemIndex,
                                          index
                                        )}
                                      </p>
                                    </td>

                                    {/* Skip area column - it's handled by rowSpan above */}
                                    {/* {!isFirstItem && <td></td>} */}

                                    {/* Code */}
                                    <td>
                                      <p>
                                        {part?.cart_properties?.code || "-"}
                                      </p>
                                    </td>

                                    {/* Product Name*/}
                                    <td className="">
                                      <div className="h-full">
                                        {part?.product?.name ? (
                                          <p>{part?.product?.name}</p>
                                        ) : (
                                          <p>-</p>
                                        )}
                                      </div>
                                    </td>

                                    {/* Image */}
                                    <td>
                                      <div className="h-8 w-8 mx-auto">
                                        {part?.product?.primary_image ? (
                                          <img
                                            width={32}
                                            height={32}
                                            src={`${process.env.REACT_APP_BUCKET}/${part?.product?.primary_image}`}
                                            alt=""
                                            className="h-full w-full object-contain"
                                          />
                                        ) : (
                                          <p>-</p>
                                        )}
                                      </div>
                                    </td>

                                    {/* Wattage */}
                                    <td>
                                      <p>
                                        {part?.cart_properties?.wattage || "-"}
                                      </p>
                                    </td>

                                    {/* Control Type */}
                                    <td>
                                      <div className="flex items-center justify-center">
                                        <div
                                          style={{ backgroundColor: "green" }}
                                          className="h-8 w-8  m-auto hidden"
                                        ></div>
                                        <p>
                                          {part?.cart_properties
                                            ?.lighting_control || "-"}
                                        </p>
                                      </div>
                                    </td>

                                    {/* Profile Shape */}
                                    {showTrackShape && (
                                      <td>
                                        <div className="flex items-center justify-center">
                                          <p className=" text-left ">
                                            {part?.cart_properties
                                              ?.track_shape || "-"}
                                          </p>
                                        </div>
                                      </td>
                                    )}

                                    {/* Profile Dimension */}
                                    {showTrackDimension && (
                                      <td>
                                        <div className="flex gap-2 justify-center">
                                          <p>
                                            {part?.cart_properties
                                              ?.track_dimension || "-"}
                                          </p>
                                        </div>
                                      </td>
                                    )}

                                    {/* Controller Quantity */}
                                    {showControllerQuantity && (
                                      <td>
                                        <div className="flex gap-2 justify-center">
                                          <p>
                                            {part?.cart_properties
                                              ?.track_driver_quantity || "-"}
                                          </p>
                                        </div>
                                      </td>
                                    )}

                                    {/* Light Colour */}
                                    <td>
                                      <div className="flex gap-2 justify-center">
                                        <p>
                                          {part?.cart_properties?.light_color ||
                                            "-"}
                                        </p>
                                      </div>
                                    </td>

                                    {/* Body Colour  */}
                                    <td>
                                      <div className="flex gap-2 justify-center">
                                        <p>
                                          {part?.cart_properties?.body_color ||
                                            "-"}
                                        </p>
                                      </div>
                                    </td>

                                    {/* Reflector Body Colour */}
                                    <td>
                                      <div className="flex items-center justify-center">
                                        <p className=" text-left ">
                                          {part?.cart_properties
                                            ?.reflector_color || "-"}
                                        </p>
                                      </div>
                                    </td>

                                    {/* Unit Price */}
                                    <td>
                                      <div className="flex gap-2 justify-center">
                                        <p className="min-w-[80px]">
                                          {formattedPrice(part?.price_unit)}
                                        </p>
                                      </div>
                                    </td>

                                    {/* Quantity */}
                                    <td>
                                      <div className="flex gap-2 justify-center items-center text-[1.2rem]">
                                        <p className="w-8 font-gilroy-medium text-[12px] leading-[20px]">
                                          {part?.quantity}
                                        </p>
                                      </div>
                                    </td>

                                    {/* Total Price */}
                                    <td>
                                      <div className="flex gap-2 justify-center">
                                        <p className="min-w-[80px]">
                                          {/* {formattedPrice(part?.price_total)} */}
                                        </p>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}

                              <tr>
                                <td colSpan={18}>
                                  <div className="w-full h-2 flex justify-center items-center bg-[#C5C5C5]"></div>
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        }

                        return (
                          <tr
                            className="border border-c-gray-4 [&>td]:border [&>td]:border-c-gray-4 [&>td]:whitespace-nowrap [&>td]:px-2 [&>td]:py-1 group hover:!bg-c-gray-1"
                            key={item?.id}
                          >
                            {/* No. */}
                            <td
                              style={{
                                backgroundColor: srBackgroundColor,
                              }}
                            >
                              {/* <p>{+totalItemIndex}</p> */}
                              <p>
                                {calculateSerialNumber(
                                  floorIndex,
                                  areaIndex,
                                  itemIndex
                                )}
                              </p>
                            </td>
                            {/* Area */}

                            {itemIndex === 0 ? (
                              <td rowSpan={calculateTotalRowsInArea(arr)}>
                                <div className="flex justify-center items-center">
                                  <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                                    {areaSequenceLoading ? (
                                      <div className="w-[20px]">
                                        <img
                                          src={LoadingSpinner}
                                          alt="Loading Spinner"
                                          width={20}
                                          height={20}
                                          className="w-8 h-8"
                                        />
                                      </div>
                                    ) : (
                                      showArrowOnAreas && (
                                        <div>
                                          <button
                                            className="text-[#434343] rotate-180 disabled:text-opacity-50 disabled:cursor-not-allowed"
                                            type="button"
                                            disabled={isFirstArea}
                                            onClick={() =>
                                              handleAreaSequence(
                                                areaSequenceIndex - 1,

                                                areaId,
                                                floorId
                                              )
                                            }
                                          >
                                            <CarretIcon />
                                          </button>
                                          <button
                                            className="text-[#434343] disabled:text-opacity-50 disabled:cursor-not-allowed"
                                            type="button"
                                            disabled={isLastArea}
                                            onClick={() =>
                                              handleAreaSequence(
                                                areaSequenceIndex + 1,
                                                areaId,
                                                floorId
                                              )
                                            }
                                          >
                                            <CarretIcon />
                                          </button>
                                        </div>
                                      )
                                    )}
                                  </div>
                                  <p>{area?.name}</p>
                                </div>
                              </td>
                            ) : null}

                            {/* Code */}
                            <td>
                              <div className="flex gap-2 justify-center">
                                {code || "-"}
                              </div>
                            </td>

                            {/* Product Name */}
                            <td>
                              <div className="flex items-center justify-center">
                                <div className="mr-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                                  {sequenceChangeLoading ? (
                                    <div className="w-[30px]">
                                      <img
                                        src={LoadingSpinner}
                                        alt="Loading Spinner"
                                        width={20}
                                        height={20}
                                        className="w-7 h-7"
                                      />
                                    </div>
                                  ) : (
                                    showArrowOnItems && (
                                      <div>
                                        <button
                                          className="text-[#434343] rotate-180 disabled:text-opacity-50 disabled:cursor-not-allowed"
                                          type="button"
                                          disabled={isFirstItem}
                                          onClick={() =>
                                            handleItemSequenceChange(
                                              itemSequenceIndex - 1,
                                              item?.id,
                                              item?.quantity,
                                              floorId,
                                              areaId
                                            )
                                          }
                                        >
                                          <CarretIcon />
                                        </button>
                                        <button
                                          className="text-[#434343] disabled:text-opacity-50 disabled:cursor-not-allowed"
                                          type="button"
                                          disabled={isLastItem}
                                          onClick={() =>
                                            handleItemSequenceChange(
                                              itemSequenceIndex + 1,
                                              item?.id,
                                              item?.quantity,
                                              floorId,
                                              areaId
                                            )
                                          }
                                        >
                                          <CarretIcon />
                                        </button>
                                      </div>
                                    )
                                  )}
                                </div>

                                <div>
                                  {item?.product?.name ? (
                                    <p>{item.product.name}</p>
                                  ) : (
                                    <p>-</p>
                                  )}
                                </div>
                              </div>
                            </td>

                            {/* Image */}
                            <td>
                              <div className="h-8 w-8 mx-auto ">
                                {item?.product?.primary_image ? (
                                  <img
                                    width={32}
                                    height={32}
                                    src={`${process.env.REACT_APP_BUCKET}/${item.product.primary_image}`}
                                    alt=""
                                    className="h-full w-full object-contain"
                                  />
                                ) : (
                                  <p>-</p>
                                )}
                              </div>
                            </td>

                            {/* Wattage */}
                            <td>
                              <div className="flex gap-2 justify-center">
                                <p>{wattage || "-"}</p>
                              </div>
                            </td>

                            {/* Control Type */}
                            <td>
                              <div className="flex items-center justify-center">
                                <p>{lighting_control || "-"}</p>
                              </div>
                            </td>

                            {/* Profile Shape */}
                            {showTrackShape && (
                              <td>
                                <div className="flex items-center justify-center">
                                  <p className=" text-left ">
                                    {track_shape || "-"}
                                  </p>
                                </div>
                              </td>
                            )}

                            {/* Profile Dimension */}
                            {showTrackDimension && (
                              <td>
                                <div className="flex gap-2 justify-center">
                                  <p>{track_dimension || "-"}</p>
                                </div>
                              </td>
                            )}

                            {/* Controller Quantity */}
                            {showControllerQuantity && (
                              <td>
                                <div className="flex gap-2 justify-center">
                                  <p>{track_driver_quantity || "-"}</p>
                                </div>
                              </td>
                            )}

                            {/* Light Colour */}
                            <td>
                              <div className="flex gap-2 justify-center">
                                <p>{light_color || "-"}</p>
                              </div>
                            </td>

                            {/* Body Colour */}
                            <td>
                              <div className="flex gap-2 justify-center">
                                <p>{body_color || "-"}</p>
                              </div>
                            </td>

                            {/* Reflector Body Colour */}
                            <td>
                              <div className="flex items-center justify-center">
                                <p className="text-center">
                                  {reflector_color || "-"}
                                </p>
                              </div>
                            </td>

                            {/* Unit Price */}
                            <td>
                              <div className="flex gap-2 justify-center">
                                <p className="min-w-[80px]">
                                  {/* {item.price_unit.toLocaleString("en-IN")} */}
                                  {formattedPrice(item?.price_unit)}
                                </p>
                              </div>
                            </td>

                            {/* Quantity */}
                            <td>
                              <div className="flex gap-2 justify-center items-center text-[1.2rem]">
                                {item?.quantity > 1 ? (
                                  <div className="h-8 w-8 ">
                                    <Button
                                      disabled={item?.quantity === 1}
                                      variant="secondary-outline"
                                      label="-"
                                      onClick={() =>
                                        handleQuantityChange(
                                          item?.id,
                                          -1,
                                          floorId,
                                          areaId
                                        )
                                      }
                                      fullWidth={true}
                                      rounded={false}
                                      className="!h-8 !w-8"
                                      multiClickAllowed
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className="px-[8px] py-[8px] h-8 w-8  flex md:gap-x-[1vw] justify-center items-center border-2 border-black cursor-pointer"
                                    onClick={() => handleDelete(item?.id)}
                                  >
                                    <TrashIcon height="16" width="16" />
                                  </div>
                                )}
                                <p className="w-8 font-gilroy-medium text-[12px] leading-[20px]">
                                  {item?.quantity}
                                </p>
                                <div className="h-8 w-8 ">
                                  <Button
                                    variant="secondary"
                                    label={
                                      <PlusIcon
                                        width="18px"
                                        height="18px"
                                        color="#FFFFFF"
                                      />
                                    }
                                    onClick={() =>
                                      handleQuantityChange(
                                        item?.id,
                                        1,
                                        floorId,
                                        areaId
                                      )
                                    }
                                    // fullHeight={true}
                                    fullWidth={true}
                                    rounded={false}
                                    className="!h-8 !w-8"
                                    multiClickAllowed
                                  />
                                </div>
                              </div>
                            </td>

                            {/* Total Price */}
                            <td>
                              <div className="flex gap-2 justify-center">
                                <p className="min-w-[80px]">
                                  {/* {(
                                        item.price_unit * item.quantity || 0
                                      ).toLocaleString("en-IN")} */}
                                  {formattedPrice(
                                    item?.price_unit * item?.quantity || 0
                                  )}
                                </p>
                              </div>
                            </td>

                            {/* Actions */}
                            <td>
                              <div className="flex gap-2 justify-center items-center relative">
                                <div
                                  className="flex justify-center items-center mx-1 cursor-pointer"
                                  onClick={() => {
                                    handleActionClick(item);
                                  }}
                                  ref={(ref) => {
                                    actionRefs.current[
                                      calculateItemIndex(item?.id)
                                    ] = ref;
                                  }}
                                >
                                  <FolderIcon />
                                </div>
                                {openProjectSelection &&
                                  currentRow === item?.id && (
                                    <div
                                      className="fixed z-50 "
                                      style={
                                        dropdownPosition?.openAt === "top"
                                          ? {
                                              top: dropdownPosition.top,
                                              right: dropdownPosition.right,
                                            }
                                          : {
                                              bottom: dropdownPosition.bottom,
                                              right: dropdownPosition.right,
                                            }
                                      }
                                      ref={dropDownRef}
                                    >
                                      <DropdownBox
                                        type="Quotation"
                                        mouseLocation={0}
                                        data={quickList}
                                        selected={{
                                          project,
                                          floor,
                                          area,
                                          setArea,
                                          setFloor,
                                          setProject,
                                        }}
                                        actionType={actionType}
                                        updateActionType={updateActionType}
                                        showCopyItem={true}
                                        quotationId={quotationId}
                                        itemId={item?.id}
                                        itemCount={item?.quantity}
                                        getSingleQuotationCartItems={
                                          getSingleQuotationCartItems
                                        }
                                        closeProjectSelection={
                                          closeProjectSelection
                                        }
                                      />
                                    </div>
                                  )}

                                <div
                                  className="flex justify-center items-center mx-1 cursor-pointer"
                                  onClick={() => handleDelete(item?.id)}
                                >
                                  <TrashIcon height="16" width="16" />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}

                      {/* {floorIndex !== floorArr?.length - 1 && (
                            <tr>
                              <td className="py-2 border-x-0"></td>
                            </tr>
                          )} */}
                    </>
                  );
                })}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>

      <div className=" bg-[#191919] w-[64px] z-50 h-7 fixed bottom-[56px] right-[-1px] lg:flex justify-between">
        <button
          className="flex-1 flex items-center justify-center border-r border-r-[#535353]"
          onClick={() => {
            scrollContainerRef.current?.scrollBy({
              left: -200, // Adjust the scroll amount as needed
              behavior: "smooth",
            });
          }}
        >
          <HipchatChevronLeftIcon color="#D4A15E" />
        </button>
        <button
          className="flex-1 flex items-center justify-center"
          onClick={() => {
            scrollContainerRef.current?.scrollBy({
              left: 200, // Adjust the scroll amount as needed
              behavior: "smooth",
            });
          }}
        >
          <HipchatChevronRightIcon color="#D4A15E" />
        </button>
      </div>
    </div>
  );
}
