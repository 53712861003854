import React, { useEffect, useState } from "react";
import editPen from "../../../../../assets/svg/pen.svg";
import { Link, useParams } from "react-router-dom";
import { Accordian } from "../../../../../components/Accordian";
import { getCustomersProjectlist } from "../../../../../api/project";

export default function ProjectDetails() {
  const [loading, setLoading] = useState(false);
  const [projectData, setProjectData] = useState([]);
  const { id: customerID } = useParams();

  const fetchProjectList = async () => {
    setLoading(true);
    try {
      const res = await getCustomersProjectlist(customerID);
      setProjectData(res.data.project);
    } catch (error) {
      console.error("Error fetching project list:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjectList();
  }, [customerID]);

  return (
    <>
      <div>
        <Accordian label="Project Details">
          <div className="grid grid-cols-3">
            {!projectData.length ? (
              <div className="col-span-3 p-4">
                <p className="text-center font-gilroy-semi-bold">No Projects</p>
              </div>
            ) : (
              <>
                {projectData?.map((project: any, index) => (
                  <div
                    key={project?.id}
                    className="border-x border-y px-5 py-5"
                  >
                    <div className="flex justify-between h-[40px]">
                      <Link to={`/projects/${project.id}`}>
                        <p className="underline text-[14px] font-medium">
                          {project.name || `Project ${index + 1}`}
                        </p>
                      </Link>
                      <div>
                        <Link to={`/projects/${project.id}/edit`}>
                          <img src={editPen} alt="edit-pen" />
                        </Link>
                      </div>
                    </div>

                    <div className="flex flex-col text-[11px] leading-5 tracking-[-0.08px] text-start">
                      <div className="flex space-x-6">
                        <p>Created on : </p>
                        <span>
                          {new Date(project.created_at).toLocaleDateString()}
                        </span>
                      </div>
                      <div className="flex space-x-9">
                        <p>Location : </p>
                        <span>{`${project.address_city}, ${project.address_state}`}</span>
                      </div>
                      <div className="flex space-x-[25px]">
                        <p>Total items : </p>
                        <span>{project.order_count}</span>
                      </div>
                      <div className="flex space-x-[14.5px]  ">
                        <p>Professionals : </p>
                        <span>{project.member_count}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </Accordian>
      </div>
    </>
  );
}
