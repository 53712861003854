import * as yup from "yup";

export const createEditChannelPartnerSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("Name is required")
    .max(64, "Name must not exceed 64 characters"),
  logo: yup.string().trim().required("Please upload logo"),
  gst_doc: yup.string().trim().required("Please upload GST certificate"),
  quotation_front_cover: yup
    .string()
    .trim()
    .required("Please upload quotation front cover"),
  quotation_back_cover: yup
    .string()
    .trim()
    .required("Please upload quotation back cover"),
  pan_doc: yup.string().trim().required("Please upload PAN card"),
  partner_pan_doc: yup.string().trim().required("Please upload PAN card"),
  partner_aadhaar_doc: yup.string().trim().required("Please upload Adhar card"),
  bank_cancel_chq_doc: yup
    .string()
    .trim()
    .required("Please upload cancel cheque"),
  phone: yup.string().trim().required("Phone is required"),
  gst_no: yup.string().trim().required("GST is required"),
  pan_no: yup.string().trim().required("Pan is required"),
  partner_pan_no: yup.string().trim().required("Pan is required"),
  partner_aadhaar_no: yup
    .string()
    .trim()
    .required("Aadhaar Number is required")
    .matches(/^\d{12}$/, "Aadhaar Number must be a valid 12-digit number"),
  emergency_contact_name: yup
    .string()
    .trim()
    .required("Emergency Contact Name is required")
    .max(64, "Emergency Contact Name must not exceed 64 characters"),
  emergency_contact_phone: yup
    .string()
    .trim()
    .required("Emergency Contact Phone is required"),
  bank_beneficiary: yup
    .string()
    .trim()
    .required("Bank Beneficiary Name is required"),
  bank_ifsc: yup.string().trim().required("Bank IFSC is required"),
  bank_acc_no: yup.string().trim().required("Bank Account Number is required"),
  addr_street1: yup.string().trim().required("Street Address 1 is required"),
  addr_street2: yup.string().trim().nullable(),
  addr_city: yup.string().trim().required("City is required"),
  addr_state: yup.string().trim().required("State is required"),
  addr_zipcode: yup
    .string()
    .trim()
    .required("Pincode is required")
    .matches(/^\d{6}$/, "Pincode must be a valid 6-digit number"),
  assigned_cities: yup.array().of(yup.string()),
  assigned_zipcodes: yup.array().of(yup.string()),
  assigned_locations: yup
    .mixed()
    .test(
      "either-cities-or-pincodes",
      "Please select at least one city or pincode",
      function (_:any, context:any) {
        const { assigned_cities, assigned_zipcodes } = context.parent;
        if (assigned_cities?.length > 0 || assigned_zipcodes?.length > 0) {
          return true;
        }
        return false;
      }
    ),
  office_phone: yup
    .string()
    .trim()
    .required("Office Phone is require")
    .matches(/^\d{10}$/, "Office Phone must be a valid 10-digit number"),
});

export type CreateEditChannelPartnerSchema = yup.InferType<
  typeof createEditChannelPartnerSchema
>;
