import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import arczLogo from "../assets/images/Arcz_White.png";
import HipchatChevronDownIcon from "../assets/HipchatChevronDownIcon";
import menubarleftIcon from "../assets/svg/menubarleft.svg";
import menubarrightIcon from "../assets/svg/menubarright.svg";
import { NavItem } from "../utils/NavItems";
import { checkPermissions } from "../utils/helper";

type SideNavigationProps = {
  navItems: NavItem[];
  equals?: boolean;
  sidebarExpand?: boolean | undefined;
  setSidebarExpand?: any;
  containerResizing?: boolean | undefined;
  setContainerResizing?: any;
  initial?: boolean | undefined;
  setInitial?: any;
};

const SideNavigation = ({
  navItems,
  equals = false,
  sidebarExpand,
  setSidebarExpand,
  containerResizing,
  setContainerResizing,
}: SideNavigationProps) => {
  const [mouseMovedValue, setMouseMovedValue] = useState<null | string>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [openSubItems, setOpenSubItems] = useState<string | null>(null);

  // console.log(mouseMovedValue, "mouseMovedValue");

  const filterSubItems = (subItems: any[]) => {
    if (!subItems) return [];
    return subItems.filter((item) => {
      if (!item.permission) return true;
      return checkPermissions(item.permission);
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        layout
        initial={{ x: "-200%" }}
        animate={
          sidebarExpand
            ? { width: 234, x: 0 }
            : {
                width: 48,
                x: 0,
              }
        }
        transition={{ duration: 0.4 }}
        onClick={() => {
          setSidebarExpand((prev: any) => !prev);
          setContainerResizing(true);
        }}
        className={`h-screen w-[48px] cursor-pointer float-left flex flex-col absolute top-0 z-[40] bg-black text-white ${
          sidebarExpand ? "" : "items-center"
        }`}
      >
        <motion.button
          onClick={(e) => {
            e.stopPropagation();
            setSidebarExpand((prev: any) => !prev);
            setContainerResizing(true);
          }}
          // initial={{ width: 0 }}
          // animate={sidebarExpand ? { width: "100%" } : { width: 40 }}
          // transition={{ duration: 0.4, ease: "easeInOut" }}
          className="bg-black w-full"
        >
          {/* Side logo asand  Image Container */}
          <div
            className={`border-b border-[#2C2C2C]  h-fit min-h-[36px] 
                    ${
                      sidebarExpand && "p-1"
                    } flex items-center m-auto justify-center z-0 py-1 w-full`}
          >
            <motion.img
              initial={{ opacity: 0 }}
              animate={{
                height: 32,
                width: sidebarExpand ? 70 : 32,
                objectFit: "contain",
                backgroundClip: "padding-box",
                opacity: 1,
              }}
              transition={{ duration: 0.4 }}
              src={arczLogo}
            />

            {/* {sidebarExpand && (
              <motion.div
                initial={{ width: 0, y: "" }}
                animate={{ width: "", y: "" }}
                exit={{ width: 0, y: "" }}
                transition={{ duration: 1, ease: "anticipate" }}
                className={`${
                  sidebarExpand ? "right-[-5px]" : "right-0.5"
                }  text-white text-[28px] font-mangoGrotesque-black leading-10`}
              >
                PerfectLights
              </motion.div>
            )} */}
          </div>
        </motion.button>

        <motion.div layout>
          {navItems?.map((navItem: any, index: any) => {
            let allow = true;
            const isOpen = openSubItems === navItem.to;
            const filteredSubItems = filterSubItems(navItem.subItems);

            // Skip rendering if no subitems are allowed
            if (navItem.subItems && filteredSubItems.length === 0) {
              return null;
            }

            return (
              <div key={index}>
                {navItem.heading ? (
                  <motion.div
                    layout
                    className={`${
                      sidebarExpand ? " w-[234px]" : "justify-start w-[48px]"
                    } h-10 flex text-xxs font-gilroy-medium justify-center items-center  pt-2 `}
                  >
                    {!containerResizing &&
                      (sidebarExpand ? (
                        <motion.p
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          layout
                        >
                          {navItem.heading}
                        </motion.p>
                      ) : (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          layout
                          className="border-[1px]"
                        ></motion.div>
                      ))}
                  </motion.div>
                ) : (
                  <AnimatePresence>
                    {allow && (
                      <motion.div layout className=" mb-4 mt-4">
                        <motion.div
                          transition={{ duration: 0.4 }}
                          onClick={(e) => {
                            e.stopPropagation();

                            if (navItem.subItems && !sidebarExpand) {
                              // console.log(navItem.to, "navItem.to");
                              setOpenSubItems(isOpen ? null : navItem.to); // Toggle the open state
                              navigate(navItem.to);
                              return;
                            }

                            if (navItem.subItems) {
                              setOpenSubItems(isOpen ? null : navItem.to); // Toggle the open state
                            } else {
                              setOpenSubItems(null); // Close all sub-items
                            }

                            navigate(navItem.to);
                          }}
                          className={`flex items-center cursor-pointer rounded-md py-1 ${
                            equals
                              ? location.pathname === navItem.to ||
                                location.pathname === navItem.cc ||
                                location.pathname === navItem.ce ||
                                location.pathname === navItem.cf
                                ? navItem.subItems
                                  ? openSubItems
                                    ? "bg-pot-yellow active:bg-pot-yellow rounded-b-none"
                                    : location.pathname === navItem.to ||
                                      location.pathname === navItem.cc ||
                                      location.pathname === navItem.ce ||
                                      location.pathname === navItem.cf ||
                                      location.pathname === navItem.cg ||
                                      location.pathname === navItem.ch ||
                                      location.pathname === navItem.ci ||
                                      location.pathname === navItem.cj
                                    ? "bg-pot-yellow "
                                    : ""
                                  : "bg-pot-yellow "
                                : ""
                              : location.pathname.includes(navItem.to)
                              ? navItem.subItems
                                ? openSubItems
                                  ? "bg-pot-yellow "
                                  : ""
                                : "bg-pot-yellow"
                              : ""
                          } ${
                            sidebarExpand
                              ? " w-full pl-4"
                              : "justify-center items-center w-[32px] pl-0"
                          }`}
                        >
                          <motion.div
                            onMouseEnter={() => {
                              setMouseMovedValue(navItem.title);
                            }}
                            onMouseLeave={() => {
                              setMouseMovedValue(null);
                            }}
                            layout
                            className=" relative w-[26px] h-[26px] flex items-center"
                          >
                            <motion.div
                              layout
                              className={`flex justify-center items-center w-[26px] h-[26px]  object-contain `}
                            >
                              <img src={navItem.icon} alt={navItem.title} />
                            </motion.div>

                            {/* hover icon name container */}
                            <AnimatePresence>
                              {!sidebarExpand &&
                                mouseMovedValue === navItem.title && (
                                  <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.1, delay: 0.4 }}
                                    className="w-48 text-start px-6 py-2 h-fit absolute top-0 left-[135%] transform -translate-x-0 translate-y-0  whitespace-pre-wrap bg-[#1C1C1C]  text-white shadow-lg text-sm z-40"
                                  >
                                    {navItem.subItems &&
                                      mouseMovedValue === navItem.title &&
                                      navItem.subItems.map(
                                        (subItem: any, i: number) => (
                                          <button
                                            key={i}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              navigate(subItem.to);
                                            }}
                                            className={`w-full flex justify-start items-center gap-x-1.5 font-semibold text-white text-[11.5px] py-2 bg-[#1C1C1C] hover:text-pot-yellow`}
                                          >
                                            <p>⦁</p>
                                            {subItem.title}
                                          </button>
                                        )
                                      )}
                                    {mouseMovedValue === navItem.title &&
                                      !navItem.subItems && (
                                        <div>{navItem.title}</div>
                                      )}
                                  </motion.div>
                                )}
                            </AnimatePresence>
                          </motion.div>
                          {sidebarExpand && (
                            <motion.div
                              layout
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              transition={{ duration: 0.2, delay: 0.3 }}
                              className={`text-white flex items-center font-gilroy-medium text-xs ml-2  gap-2 ${
                                navItem.subItems && "w-full"
                              }`}
                            >
                              <p className="w-fit flex justify-center items-center">
                                {navItem.title}
                              </p>
                              {navItem.subItems && sidebarExpand && (
                                <motion.div
                                  animate={{ rotate: !isOpen ? 0 : 180 }}
                                  className="w-fit h-fit "
                                >
                                  <HipchatChevronDownIcon />
                                </motion.div>
                              )}
                            </motion.div>
                          )}
                        </motion.div>
                        {openSubItems &&
                          sidebarExpand &&
                          isOpen &&
                          navItem.subItems && (
                            <motion.div
                              layout
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{
                                opacity: 0,
                                scale: 0.6,
                                transition: { delay: 0 },
                              }}
                              transition={{ delay: 0.4 }}
                              className=" w-[93%] m-auto left-0"
                            >
                              {filteredSubItems?.map((each: any, i: any) => (
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(each.to);
                                  }}
                                  className={`w-full flex justify-start pl-9  items-center gap-x-1.5 font-gilroy-medium text-white text-[10.5px] py-2 bg-[#1C1C1C] hover:text-pot-yellow`}
                                  key={i}
                                >
                                  <p>⦁</p>
                                  {each.title}
                                </button>
                              ))}
                            </motion.div>
                          )}
                      </motion.div>
                    )}
                  </AnimatePresence>
                )}
              </div>
            );
          })}
        </motion.div>

        {/* For menubar animations*/}

        <div className="fixed bottom-4 left-0 w-full">
          <motion.div
            onMouseEnter={() =>
              !sidebarExpand &&
              "Collapse menubar" &&
              setMouseMovedValue("Collapse menubar")
            }
            onMouseLeave={() =>
              !sidebarExpand && "Collapse menubar" && setMouseMovedValue(null)
            }
            layout
            className={`relative ${
              sidebarExpand ? "hidden" : "block w-[26px] h-[26px]"
            } flex justify-center m-auto items-center`}
          >
            <motion.div
              layout
              className={`flex justify-center items-center font-gilroy-medium`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.2, delay: 0.3 }}
            >
              {!sidebarExpand && (
                <motion.img src={menubarrightIcon} alt={"Collapse menubar"} />
              )}
            </motion.div>

            {/* hover icon name container */}
            <AnimatePresence>
              {!sidebarExpand && mouseMovedValue === "Collapse menubar" && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.1, delay: 0.4 }}
                  className="w-48 text-start px-6 py-2 h-fit absolute top-0 left-[135%] transform -translate-x-0 translate-y-0  whitespace-pre-wrap bg-[#1C1C1C]  text-white shadow-lg text-sm z-40"
                >
                  Collapse menubar
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
          <AnimatePresence>
            {sidebarExpand && (
              <motion.div
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.2, delay: 0.3 }}
                className={`text-white flex justify-end text-end font-gilroy-medium text-md w-full gap-4 m-auto px-4`}
              >
                <motion.p>Collapse menubar</motion.p>
                <img src={menubarleftIcon} alt={"menubarleftIcon"} />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default SideNavigation;
