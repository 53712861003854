import React, { useEffect, useRef, useState } from "react";
import useOnClickOutside from "../../../hooks/useClickOutside";
import useScrollOutside from "../../../hooks/useScrollOutside";
import LoadingSpinner from "../../../assets/images/LoadingSpinner.gif";
import CarretIcon from "../../../assets/svg-tsx/CarretIcon";
import {
  applyLightningControlCategoryColor,
  formattedPrice,
} from "../../../utils/helper";
import Button from "../../../components/Button";
import TrashIcon from "../../../assets/TrashIcon";
import PlusIcon from "../../../assets/PlusIcon";
import HipchatChevronLeftIcon from "../../../assets/HipchatChevronLeftIcon";
import HipchatChevronRightIcon from "../../../assets/HipchatChevronRightIcon";
import DropdownBox from "../../../components/ProjectSetter/DropdownBox";
import FolderIcon from "../../../assets/svg-tsx/FolderIcon";
import { protectedAxiosInstance } from "../../../api/axiosManagement";
import { toast } from "react-toastify";
import LegendList from "../components/LegenList";

export default function DefaultQuotationTable({
  data = [],
  selectedProject,
  calculateCost,
  showBanner,
  enableCheckout,
  projectSelectionIndex,
  setProjectSelectionIndex,
  cartSpecificData,
  setCartSpecificData,
  loading,
  isAuthenticated,
  bottomBarHeight,
  quotationId,
  getSingleQuotationCartItems,
}: any) {
  const [projectsList, setProjectsList] = useState<any>(null);
  const [floor, setFloor] = useState<any | null>(null);
  const [project, setProject] = useState<any | null>(null);
  const [area, setArea] = useState<any | null>(null);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [openProjectSelection, setOpenProjectSelection] = useState(false);
  const [currentRow, setCurrentRow] = useState<any>();
  const [cartCheckoutData, setCartCheckoutData] = useState<any>([]);
  const timeoutRef = useRef<any>({});
  const [dropdownPosition, setDropdownPosition] = useState<any>();
  const actionRefs = useRef<any>([]);
  const dropDownRef = useRef<any>(null);
  const previousCheckedRows = useRef<{ [key: string]: boolean }>({});
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const [actionType, setActionType] = useState<string>("move");
  const [sequenceChangeLoading, setSequenceChangeLoading] = useState(false);

  function updateActionType(actionName: string) {
    setActionType(actionName);
  }

  const handleActionClick = (item: any, index: any) => {
    setCurrentRow(item.id);

    const midpoint = window.innerHeight / 2;

    const folderIconPosition =
      actionRefs.current[index].getBoundingClientRect().top - window.scrollY;

    if (folderIconPosition < midpoint) {
      setDropdownPosition({
        top:
          actionRefs.current[index].getBoundingClientRect().top -
          window.scrollY -
          (dropDownRef.current ? dropDownRef.current.offsetHeight : 0),

        right:
          window.innerWidth -
          actionRefs.current[index].getBoundingClientRect().right +
          window.scrollX,
        openAt: "top",
      });
    } else {
      setDropdownPosition({
        bottom:
          window.innerHeight -
          actionRefs.current[index].getBoundingClientRect().top +
          window.scrollY,

        right:
          window.innerWidth -
          actionRefs.current[index].getBoundingClientRect().right +
          window.scrollX,
        openAt: "bottom",
      });
    }

    setOpenProjectSelection(true);
  };

  function closeProjectSelection() {
    setOpenProjectSelection(false);
  }

  useOnClickOutside(dropDownRef, () => {
    setOpenProjectSelection(false);
    setProjectSelectionIndex(null);
  });
  useScrollOutside(dropDownRef, () => {
    setOpenProjectSelection(false);

    setProjectSelectionIndex(null);
  });

  function handleDelete(id: any) {
    protectedAxiosInstance
      .delete(`/admin/quotation/${quotationId}/item/${id}`)
      .then(() => {
        getSingleQuotationCartItems(quotationId);
        toast.success("Item Deleted Successfully");
      })
      .catch((error) => {
        toast.error("Error in Deleting Item");
      });
  }

  // item sequence changes
  function handleItemSequenceChange(sequence: any, id: any, quantity: any) {
    setSequenceChangeLoading(true);

    protectedAxiosInstance
      .put(`/admin/quotation/${quotationId}/item/${id}`, {
        sequence,
        quantity,
      })
      .then((res) => {
        setSequenceChangeLoading(false);
        getSingleQuotationCartItems(quotationId);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSequenceChangeLoading(false);
      });
  }

  useEffect(() => {
    if (!openProjectSelection) {
      setFloor(null);
      setProject(null);
      setArea(null);
    }
  }, [openProjectSelection]);

  //   copying project useEffect

  if (loading) {
    return (
      <div className="h-[80%] flex items-center justify-center">
        <img src={LoadingSpinner} alt="Loading spinner" className="w-20 h-20" />
      </div>
    );
  }

  if (!loading && data?.length === 0) {
    return (
      <p
        className={`flex justify-center items-center h-auto  ${
          showBanner ? "my-[15dvh]" : "mt-[25dvh]"
        } text-gray-500 text-md`}
      >
        {" "}
        There are no items in this cart
      </p>
    );
  }

  function handleQuantityChange(id: any, value: any) {
    const newData = data.map((item: any) => {
      if (item.id === id) {
        return { ...item, quantity: item.quantity + value };
      }
      return item;
    });
    setCartSpecificData(newData);

    const lastValue = newData.find((item: any) => item.id === id).quantity;

    if (timeoutRef.current[id]) {
      clearTimeout(timeoutRef.current[id]);
    }

    timeoutRef.current[id] = setTimeout(() => {
      updateQuantity(id, lastValue);
    }, 500);
  }

  async function updateQuantity(id: any, quantity: any) {
    try {
      await protectedAxiosInstance.put(
        `/admin/quotation/${quotationId}/item/${id}`,
        { quantity }
      );
      calculateCost();
    } catch (error) {
      console.error("Error updating quantity : ", error);
    }
  }

  // new implementation
  let showTrackShape = false;
  let showTrackDimension = false;
  let showControllerQuantity = false;

  data?.map((item: any) => {
    if (item?.cart_properties?.track_shape) {
      showTrackShape = true;
    }
    if (item?.cart_properties?.track_dimension) {
      showTrackDimension = true;
    }
    if (item?.cart_properties?.track_driver_quantity) {
      showControllerQuantity = true;
    }
  });

  const showArrowOnItems = data?.length > 1;

  return (
    <div
      className="text-sm mx-4 text-gray-700 pb-0 max-h-[calc(100dvh-141px)] overflow-y-auto no-scrollbar custom-scrollbar"
      ref={scrollContainerRef}
    >
      <table className="mb-[6rem] w-full border border-c-gray-4  border-collapse text-[0.7rem] text-center  table-auto">
        <tbody>
          {/* Heading */}
          <tr className="bg-c-gray-1 border border-c-gray-4 [&>th]:px-2 [&>th]:py-1 font-gilroy-semi-bold [&>th]:border [&>th]:border-c-gray-4 [&>th]:whitespace-nowrap [&>th]:bg-c-gray-3">
            <th>No</th>
            <th>Code</th>
            <th>Product Name</th>
            <th>Image</th>
            <th>Wattage</th>
            <th>Control Type</th>
            {showTrackShape && <th>Profile Shape</th>}
            {showTrackDimension && <th>Profile Dimension</th>}
            {showControllerQuantity && <th>Controller Quantity</th>}
            <th>Light Colour</th>
            <th>Body Colour</th>
            <th>Reflector Colour</th>
            <th>Unit Price</th>
            <th>Quantity</th>
            <th>Total Price</th>
            <th>{isAuthenticated ? "Actions" : "Action"}</th>
          </tr>

          {!loading &&
            data.map((item: any, itemIndex: any, arr: any) => {
              const isFirstItem = itemIndex === 0;
              const isLastItem = data?.length - 1;
              // Function to calculate total part list items before current index
              const calculatePreviousPartListCount = (currentIndex: number) => {
                let count = 0;
                for (let i = 0; i < currentIndex; i++) {
                  if (data[i]?.part_list?.length > 0) {
                    count += data[i].part_list.length - 1; // Subtract 1 because the header counts as one item
                  }
                }
                return count;
              };

              return (
                <SingleRow
                  key={itemIndex}
                  sequenceIndex={itemIndex + 1}
                  item={item}
                  itemIndex={itemIndex}
                  // checkedRows={checkedRows}
                  // setCheckedRows={setCheckedRows}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  // handleQuantity={handleQuantity}
                  handleQuantity={handleQuantityChange}
                  handleDelete={handleDelete}
                  handleActionClick={handleActionClick}
                  actionRefs={actionRefs}
                  openProjectSelection={openProjectSelection}
                  currentRow={currentRow}
                  dropdownPosition={dropdownPosition}
                  dropDownRef={dropDownRef}
                  project={project}
                  floor={floor}
                  area={area}
                  setArea={setArea}
                  setFloor={setFloor}
                  setProject={setProject}
                  isAuthenticated={isAuthenticated}
                  showTrackShape={showTrackShape}
                  showTrackDimension={showTrackDimension}
                  showControllerQuantity={showControllerQuantity}
                  actionType={actionType}
                  updateActionType={updateActionType}
                  isFirstItem={isFirstItem}
                  isLastItem={isLastItem}
                  handleItemSequenceChange={handleItemSequenceChange}
                  sequenceChangeLoading={sequenceChangeLoading}
                  showArrowOnItems={showArrowOnItems}
                  previousPartListCount={calculatePreviousPartListCount(
                    itemIndex
                  )}
                  quotationId={quotationId}
                  getSingleQuotationCartItems={getSingleQuotationCartItems}
                  closeProjectSelection={closeProjectSelection}
                />
              );
            })}

          {loading && <p>HI</p>}
        </tbody>
      </table>

      <div className=" bg-[#191919] w-[64px] z-50 h-7 fixed bottom-[56px] right-[-1px] lg:flex justify-between">
        <button
          className="flex-1 flex items-center justify-center border-r border-r-[#535353]"
          onClick={() => {
            scrollContainerRef.current?.scrollBy({
              left: -200, // Adjust the scroll amount as needed
              behavior: "smooth",
            });
          }}
        >
          <HipchatChevronLeftIcon color="#D4A15E" />
        </button>
        <button
          className="flex-1 flex items-center justify-center"
          onClick={() => {
            scrollContainerRef.current?.scrollBy({
              left: 200, // Adjust the scroll amount as needed
              behavior: "smooth",
            });
          }}
        >
          <HipchatChevronRightIcon color="#D4A15E" />
        </button>
      </div>
    </div>
  );
}

const SingleRow = ({
  sequenceIndex,
  item,
  itemIndex,
  checkedRows,
  setCheckedRows,
  selectedItems,
  setSelectedItems,
  handleQuantity,
  handleDelete,
  handleActionClick,
  actionRefs,
  openProjectSelection,
  currentRow,
  dropdownPosition,
  dropDownRef,
  project,
  floor,
  area,
  setArea,
  setFloor,
  setProject,
  isAuthenticated,
  showTrackShape,
  showTrackDimension,
  showControllerQuantity,
  actionType,
  updateActionType,
  isFirstItem,
  isLastItem,
  handleItemSequenceChange,
  sequenceChangeLoading,
  showArrowOnItems,
  previousPartListCount,
  quotationId,
  getSingleQuotationCartItems,
  closeProjectSelection,
}: any) => {
  const calculateSerialNumber = (baseIndex: number, partIndex?: number) => {
    const adjustedBaseIndex = baseIndex + previousPartListCount;

    if (partIndex !== undefined) {
      // For part list items
      return adjustedBaseIndex + partIndex + 1;
    }
    // For regular items or headers
    return adjustedBaseIndex + 1;
  };

  const trackTypeVariant = item.product_variants?.find(
    (variant: any) => variant.variant.kind === "TRACK_TYPE"
  )?.variant;
  const shapeName = trackTypeVariant?.option?.name;
  const lengthA = trackTypeVariant?.v_user_attrs?.dimensions?.a_ft;
  const lengthB = trackTypeVariant?.v_user_attrs?.dimensions?.b_ft;
  const lengthC = trackTypeVariant?.v_user_attrs?.dimensions?.c_ft;

  const {
    body_color,
    code,
    light_color,
    lighting_control,
    lighting_control_category,
    reflector_color,
    track_dimension,
    track_driver_quantity,
    track_shape,
    wattage,
  } = item?.cart_properties || {};

  const srBackgroundColor = applyLightningControlCategoryColor(
    lighting_control_category
  );

  if (item?.part_list?.length > 0) {
    return (
      <React.Fragment key={itemIndex}>
        {/* header */}
        <tr className="bg-c-gray-1 border border-c-gray-4 [&>td]:border [&>td]:border-c-gray-4 [&>td]:whitespace-nowrap [&>td]:px-2 [&>td]:py-1 h-[68px] group">
          {/* product name and details */}
          <td colSpan={12} className="text-black">
            <div className="flex items-center justify-center">
              <div className="mr-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                {sequenceChangeLoading ? (
                  <div className="w-[20px]">
                    <img
                      src={LoadingSpinner}
                      alt="Loading spinner"
                      className="w-8 h-8"
                    />
                  </div>
                ) : (
                  showArrowOnItems && (
                    <div>
                      <button
                        className="text-[#434343] rotate-180 disabled:text-opacity-50 disabled:cursor-not-allowed"
                        type="button"
                        disabled={isFirstItem}
                        onClick={() =>
                          handleItemSequenceChange(
                            sequenceIndex - 1,
                            item?.id,
                            item?.quantity
                          )
                        }
                      >
                        <CarretIcon />
                      </button>
                      <button
                        className="text-[#434343] disabled:text-opacity-50 disabled:cursor-not-allowed"
                        type="button"
                        disabled={itemIndex === isLastItem}
                        onClick={() =>
                          handleItemSequenceChange(
                            sequenceIndex + 1,
                            item?.id,
                            item?.quantity
                          )
                        }
                      >
                        <CarretIcon />
                      </button>
                    </div>
                  )
                )}
              </div>
              <div>
                <p>
                  <span className="font-gilroy-bold">
                    {item?.product?.name || "-"} -
                  </span>
                  <span className="capitalize">
                    {" "}
                    ( {item?.cart_properties?.lighting_control} -{" "}
                    {item?.cart_properties?.track_shape} -{" "}
                    {item?.cart_properties?.track_dimension})
                  </span>
                </p>
              </div>
            </div>
          </td>

          {/* Unit Price */}
          <td>
            <div className="flex gap-2 justify-center">
              <p className="min-w-[80px]">{formattedPrice(item?.price_unit)}</p>
            </div>
          </td>

          {/* Quantity */}
          <td>
            <div className="flex gap-2 justify-center items-center text-[1.2rem]">
              {item?.quantity > 1 ? (
                <div className="h-8 w-8 ">
                  <Button
                    disabled={item?.quantity === 1}
                    variant="secondary-outline"
                    label="-"
                    onClick={() => handleQuantity(item?.id, -1, item)}
                    // fullHeight={true}
                    fullWidth={true}
                    rounded={false}
                    className="!h-8 !w-8"
                    multiClickAllowed
                  />
                </div>
              ) : (
                <div
                  className="px-[8px] py-[8px] h-8 w-8 flex md:gap-x-[1vw] justify-center items-center border-2 border-black cursor-pointer"
                  onClick={() => handleDelete(item?.id)}
                >
                  <TrashIcon width="16" height="16" />
                </div>
              )}
              <p className="w-8 font-gilroy-medium text-[12px] leading-[20px]">
                {item?.quantity}
              </p>
              <div className="h-8 w-8 ">
                <Button
                  variant="secondary"
                  label={
                    <PlusIcon width="18px" height="18px" color="#FFFFFF" />
                  }
                  onClick={() => handleQuantity(item?.id, 1, item)}
                  // fullHeight={true}
                  fullWidth={true}
                  rounded={false}
                  className="!h-8 !w-8"
                  multiClickAllowed
                />
              </div>
            </div>
          </td>

          {/* Total Price */}
          <td>
            <div className="flex gap-2 justify-center">
              <p className="min-w-[80px]">
                {formattedPrice(item.price_unit * item.quantity || 0)}
              </p>
            </div>
          </td>

          <td>
            <div className="flex gap-2 justify-center items-center relative">
              <>
                {" "}
                <div
                  className="flex justify-center items-center mx-1 cursor-pointer"
                  onClick={() => {
                    handleActionClick(item, itemIndex);
                  }}
                  ref={(ref) => {
                    actionRefs.current[itemIndex] = ref;
                  }}
                >
                  <FolderIcon />
                </div>
                {openProjectSelection && currentRow === item?.id && (
                  <div
                    className="fixed z-50"
                    style={
                      dropdownPosition?.openAt === "top"
                        ? {
                            top: dropdownPosition.top,
                            right: dropdownPosition.right,
                          }
                        : {
                            bottom: dropdownPosition.bottom,
                            right: dropdownPosition.right,
                          }
                    }
                    ref={dropDownRef}
                  >
                    <DropdownBox
                      type="project"
                      mouseLocation={0}
                      selected={{
                        project,
                        floor,
                        area,
                        setArea,
                        setFloor,
                        setProject,
                      }}
                      actionType={actionType}
                      updateActionType={updateActionType}
                      showCopyItem={true}
                      quotationId={quotationId}
                      itemId={item?.id}
                      itemCount={item?.quantity}
                      getSingleQuotationCartItems={getSingleQuotationCartItems}
                      closeProjectSelection={closeProjectSelection}
                    />
                  </div>
                )}
              </>

              <div
                className="flex justify-center items-center mx-1 cursor-pointer"
                onClick={() => handleDelete(item?.id)}
              >
                <TrashIcon width="16px" height="16px" />
              </div>
            </div>
          </td>
        </tr>

        {/* part list */}
        {item?.part_list?.map((part: any, index: any) => {
          return (
            <tr
              className="border border-c-gray-4 [&>td]:border [&>td]:border-c-gray-4 [&>td]:whitespace-nowrap [&>td]:px-2 [&>td]:py-1 group hover:!bg-c-gray-1"
              key={index}
            >
              {/* No. */}
              <td style={{ backgroundColor: srBackgroundColor }}>
                {/* <p>{+itemIndex + index + 1}</p> */}
                <p>{calculateSerialNumber(itemIndex, index)}</p>
              </td>

              {/* Code */}
              <td>
                <p>{part?.cart_properties?.code || "-"}</p>
              </td>

              {/* Product Name*/}
              <td className="">
                <div className="h-full">
                  {part?.product?.name ? (
                    <p>{part?.product?.name}</p>
                  ) : (
                    <p>-</p>
                  )}
                </div>
              </td>

              {/* Image */}
              <td>
                <div className="h-8 w-8 mx-auto">
                  {part?.product?.primary_image ? (
                    <img
                      src={`${process.env.REACT_APP_BUCKET}/${part?.product?.primary_image}`}
                      alt=""
                      className="h-full w-full object-contain"
                    />
                  ) : (
                    <p>-</p>
                  )}
                </div>
              </td>

              {/* Wattage */}
              <td>
                <p>{part?.cart_properties?.wattage || "-"}</p>
              </td>

              {/* Control Type */}
              <td>
                <div className="flex items-center justify-center">
                  <div
                    style={{ backgroundColor: "green" }}
                    className="h-8 w-8  m-auto hidden"
                  ></div>
                  <p>{part?.cart_properties?.lighting_control || "-"}</p>
                </div>
              </td>

              {/* Profile Shape */}
              {showTrackShape && (
                <td>
                  <div className="flex items-center justify-center">
                    <p className=" text-left ">
                      {part?.cart_properties?.track_shape || "-"}
                    </p>
                  </div>
                </td>
              )}

              {/* Profile Dimension */}
              {showTrackDimension && (
                <td>
                  <div className="flex gap-2 justify-center">
                    <p>{part?.cart_properties?.track_dimension || "-"}</p>
                  </div>
                </td>
              )}

              {/* Controller Quantity */}
              {showControllerQuantity && (
                <td>
                  <div className="flex gap-2 justify-center">
                    <p>{part?.cart_properties?.track_driver_quantity || "-"}</p>
                  </div>
                </td>
              )}

              {/* Light Colour */}
              <td>
                <div className="flex gap-2 justify-center">
                  <p>{part?.cart_properties?.light_color || "-"}</p>
                </div>
              </td>

              {/* Body Colour  */}
              <td>
                <div className="flex gap-2 justify-center">
                  <p>{part?.cart_properties?.body_color || "-"}</p>
                </div>
              </td>

              {/* Reflector Body Colour */}
              <td>
                <div className="flex items-center justify-center">
                  <p className=" text-left ">
                    {part?.cart_properties?.reflector_color || "-"}
                  </p>
                </div>
              </td>

              {/* Unit Price */}
              <td>
                <div className="flex gap-2 justify-center">
                  <p className="min-w-[80px]">
                    {formattedPrice(part?.price_unit)}
                  </p>
                </div>
              </td>

              {/* Quantity */}
              <td>
                <div className="flex gap-2 justify-center items-center text-[1.2rem]">
                  <p className="w-8 font-gilroy-medium text-[12px] leading-[20px]">
                    {part?.quantity}
                  </p>
                </div>
              </td>

              {/* Total Price */}
              <td>
                <div className="flex gap-2 justify-center">
                  <p className="min-w-[80px]">
                    {formattedPrice(part?.price_total)}
                  </p>
                </div>
              </td>
            </tr>
          );
        })}

        <tr>
          <td colSpan={21}>
            <div className="w-full h-2 flex justify-center items-center bg-[#C5C5C5]"></div>
          </td>
        </tr>
      </React.Fragment>
    );
  }

  return (
    <tr
      className="border border-c-gray-4 [&>td]:border [&>td]:border-c-gray-4 [&>td]:whitespace-nowrap [&>td]:px-2 [&>td]:py-1 group hover:!bg-c-gray-1"
      key={itemIndex}
    >
      {/* No. */}
      <td style={{ backgroundColor: srBackgroundColor }}>
        {/* <p>{+itemIndex + 1}</p> */}
        {calculateSerialNumber(itemIndex)}
      </td>

      {/* Code */}
      <td>
        <p>{code || "-"}</p>
      </td>

      {/* Product Name*/}
      <td className="">
        <div className="flex items-center justify-center">
          <div className="mr-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
            {sequenceChangeLoading ? (
              <div className="w-[20px]">
                <img
                  src={LoadingSpinner}
                  alt="Loading spinner"
                  className="w-8 h-8"
                />
              </div>
            ) : (
              showArrowOnItems && (
                <div>
                  <button
                    className="text-[#434343] rotate-180 disabled:text-opacity-50 disabled:cursor-not-allowed"
                    type="button"
                    disabled={isFirstItem}
                    onClick={() =>
                      handleItemSequenceChange(
                        sequenceIndex - 1,
                        item?.id,
                        item?.quantity
                      )
                    }
                  >
                    <CarretIcon />
                  </button>
                  <button
                    className="text-[#434343] disabled:text-opacity-50 disabled:cursor-not-allowed"
                    type="button"
                    disabled={itemIndex === isLastItem}
                    onClick={() =>
                      handleItemSequenceChange(
                        sequenceIndex + 1,
                        item?.id,
                        item?.quantity
                      )
                    }
                  >
                    <CarretIcon />
                  </button>
                </div>
              )
            )}
          </div>

          <div className="h-full">
            {item?.product?.name ? (
              <span>
                <p>{item.product.name}</p>
              </span>
            ) : (
              <p>-</p>
            )}
          </div>
        </div>
      </td>

      {/* Image */}
      <td>
        <div className="h-8 w-8 mx-auto">
          {item?.product?.primary_image ? (
            <img
              src={`${process.env.REACT_APP_BUCKET}/${item.product.primary_image}`}
              alt=""
              className="h-full w-full object-contain"
            />
          ) : (
            <p>-</p>
          )}
        </div>
      </td>

      {/* Wattage */}
      <td>
        <p>{wattage || "-"}</p>
      </td>

      {/* Control Type */}
      <td>
        <div className="flex items-center justify-center">
          <div
            style={{ backgroundColor: "green" }}
            className="h-8 w-8  m-auto hidden"
          ></div>
          <p>{lighting_control || "-"}</p>
        </div>
      </td>

      {/* Profile Shape */}
      {showTrackShape && (
        <td>
          <div className="flex items-center justify-center">
            <p className=" text-left ">{track_shape || "-"}</p>
          </div>
        </td>
      )}

      {/* Profile Dimension */}
      {showTrackDimension && (
        <td>
          <div className="flex gap-2 justify-center">
            <p>{track_dimension || "-"}</p>
          </div>
        </td>
      )}

      {/* Controller Quantity */}
      {showControllerQuantity && (
        <td>
          <div className="flex gap-2 justify-center">
            <p>{track_driver_quantity || "-"}</p>
          </div>
        </td>
      )}

      {/* Light Colour */}
      <td>
        <div className="flex gap-2 justify-center">
          <p>{light_color || "-"}</p>
        </div>
      </td>

      {/* Body Colour  */}
      <td>
        <div className="flex gap-2 justify-center">
          <p>{body_color || "-"}</p>
        </div>
      </td>

      {/* Reflector Body Colour */}
      <td>
        <div className="flex items-center justify-center">
          <p className=" text-left ">{reflector_color || "-"}</p>
        </div>
      </td>

      {/* Unit Price */}
      <td>
        <div className="flex gap-2 justify-center">
          <p className="min-w-[80px]">{formattedPrice(item?.price_unit)}</p>
        </div>
      </td>

      {/* Quantity */}
      <td>
        <div className="flex gap-2 justify-center items-center text-[1.2rem]">
          {item?.quantity > 1 ? (
            <div className="h-8 w-8 ">
              <Button
                disabled={item?.quantity === 1}
                variant="secondary-outline"
                label="-"
                onClick={() => handleQuantity(item?.id, -1, item)}
                fullWidth={true}
                rounded={false}
                className="!h-8 !w-8"
                multiClickAllowed
              />
            </div>
          ) : (
            <div
              className="px-[8px] py-[8px] h-8 w-8  flex md:gap-x-[1vw] justify-center items-center border-2 border-black cursor-pointer"
              onClick={() => handleDelete(item?.id)}
            >
              <TrashIcon width="16" height="16" />
            </div>
          )}
          <p className="w-8 font-gilroy-medium text-[12px] leading-[20px]">
            {item?.quantity}
          </p>
          <div className="h-8 w-8 ">
            <Button
              variant="secondary"
              label={<PlusIcon width="18px" height="18px" color="#FFFFFF" />}
              onClick={() => handleQuantity(item?.id, 1, item)}
              // fullHeight={true}
              fullWidth={true}
              rounded={false}
              className="!h-8 !w-8"
              multiClickAllowed
            />
          </div>
        </div>
      </td>

      {/* Total Price */}
      <td>
        <div className="flex gap-2 justify-center">
          <p className="min-w-[80px]">
            {formattedPrice(item.price_unit * item.quantity || 0)}
          </p>
        </div>
      </td>

      {/* Actions */}
      <td>
        <div className="flex gap-2 justify-center items-center relative">
          {isAuthenticated && (
            <>
              {" "}
              <div
                className="flex justify-center items-center mx-1 cursor-pointer"
                onClick={() => {
                  handleActionClick(item, itemIndex);
                }}
                ref={(ref) => {
                  actionRefs.current[itemIndex] = ref;
                }}
              >
                <FolderIcon />
              </div>
              {openProjectSelection && currentRow === item?.id && (
                <div
                  className="fixed z-50"
                  style={
                    dropdownPosition?.openAt === "top"
                      ? {
                          top: dropdownPosition.top,
                          right: dropdownPosition.right,
                        }
                      : {
                          bottom: dropdownPosition.bottom,
                          right: dropdownPosition.right,
                        }
                  }
                  ref={dropDownRef}
                >
                  <DropdownBox
                    type="project"
                    mouseLocation={0}
                    selected={{
                      project,
                      floor,
                      area,
                      setArea,
                      setFloor,
                      setProject,
                    }}
                    actionType={actionType}
                    updateActionType={updateActionType}
                    showCopyItem={true}
                    quotationId={quotationId}
                    itemId={item?.id}
                    itemCount={item?.quantity}
                    getSingleQuotationCartItems={getSingleQuotationCartItems}
                    closeProjectSelection={closeProjectSelection}
                  />
                </div>
              )}
            </>
          )}

          <div
            className="flex justify-center items-center mx-1 cursor-pointer"
            onClick={() => handleDelete(item?.id)}
          >
            <TrashIcon width="16" height="16" />
          </div>
        </div>
      </td>
    </tr>
  );
};
