import { protectedAxiosInstance } from "./axiosManagement";

export const getListForQuotation = (params) => {
  return protectedAxiosInstance
    .get("/admin/quotation", { params })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const createQuotation = (payload) => {
  return protectedAxiosInstance
    .post("/admin/quotation", payload)
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });
};

export const getSingleQuotationDetails = (id) => {
  return protectedAxiosInstance
    .get(`/admin/quotation/${id}`)
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });
};

export const getSingleProjectDetails = (id) => {
  return protectedAxiosInstance
    .get(`/admin/quotation/${id}/project-info`)
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });
};

export const getSingleQuotationDetailsItems = (id) => {
  return protectedAxiosInstance
    .get(`/admin/quotation/${id}/item`)
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });
};
