import React, { useEffect, useMemo, useState } from "react";
import UpandDownArrow from "../../../assets/svg/dateIcon.svg";
import { useTable, usePagination, useSortBy } from "react-table";
import InputField from "../../../components/InputField";
import SearchIcon from "../../../assets/SearchIcon";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import ManagementTableLayout from "../../../components/MangementTable";
import PlusIcon from "../../../assets/PlusIcon";
import { formatIndianRupeesForDecimals } from "../../../utils/formatToIndianRupees";
import Pagination from "../../../components/Pagination";
import { useRecoilValue } from "recoil";
import { stateAtom } from "../../../atom";
import TrashIcon from "../../../assets/TrashIcon";
import CreateQuotation from "../CreateQuotation";
import EditIcon from "../../../assets/EditIcon";
import Modal from "../../../components/common/Modal";
import { protectedAxiosInstance } from "../../../api/axiosManagement";
import { toast } from "react-toastify";
import { formatDateToDDMMYYYY } from "../../../utils/formatDateTime";

function convertDateFormat(inputDate: any) {
  const datePart = inputDate.split(" ")[0]; // Extract date part
  const timePart = inputDate.split(" ")[1]; // Extract time part

  // Extract year, month, and day
  const [year, month, day] = datePart.split("-");

  // Extract hours, minutes, and seconds
  const [hour, minute, second] = timePart.split(":");

  // Convert to 12-hour format
  const hours12 = ((parseInt(hour) + 11) % 12) + 1; // Convert 24-hour to 12-hour
  const ampm = parseInt(hour) >= 12 ? "PM" : "AM";

  // Format the result as DD/MM/YYYY HH:MM AM/PM
  const formattedDate = `${day}/${month}/${year} ${hours12}:${minute} ${ampm}`;

  return formattedDate;
}

const Table = ({
  page,
  setPage,
  pageLength,
  setPageLength,
  searchValue,
  setSearchValue,
  quotationList,
  isLoading,
  totalCount,
  setTotalCount,
  getQuotationList,
  setIsLoading,
}: any) => {
  const stateList = useRecoilValue(stateAtom);
  const [deleteModal, setDeleteModal] = useState<any>("");

  const handleDelete = async (id: string) => {
    setIsLoading(true);
    try {
      const response = await protectedAxiosInstance.delete(
        `/admin/quotation/${id}`
      );
      console.log(response);
      getQuotationList();
    } catch (error: any) {
      toast.error("Error while deleting quotation");
      console.log(error);
    } finally {
      setIsLoading(false);
      setDeleteModal("");
    }
  };

  const COLUMN = [
    {
      id: "created_at",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center py-2">Created On</div>
      ),
      accessor: "created_at",
      Cell: ({ value }: any) => {
        return (
          <div className="px-2 py-2">
            {value ? convertDateFormat(value) : "-"}
          </div>
        );
      },
    },
    {
      id: "name",
      Header: ({ column }: { column: any }) => {
        return (
          <div className="flex items-center justify-between gap-1">Name</div>
        );
      },
      accessor: "num",
      Cell: ({ row }: { row: any }) => <div>{row?.original?.name || "-"}</div>,
    },
    {
      id: "quotation_number",
      Header: ({ column }: { column: any }) => {
        return (
          <div className="flex items-center justify-between gap-1">
            Quotation #
          </div>
        );
      },
      accessor: "num",
      Cell: ({ row }: { row: any }) => (
        <Link
          to={`/quotations/${row?.original?.id}`}
          className="hover:underline underline-offset-2"
        >
          #{row?.original?.num || "-"}
        </Link>
      ),
    },
    {
      id: "kind",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Type</div>
      ),
      accessor: "kind",
      Cell: ({ value }: any) => (
        <div className="!capitalize">{value || "-"}</div>
      ),
    },
    {
      id: "project_name",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Project Name</div>
      ),
      accessor: "project_name",
      Cell: ({ value }: any) => <div>{value || "-"}</div>,
    },
    {
      id: "items_count",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Items</div>
      ),
      accessor: "count_items",
      Cell: ({ value }: any) => <div>{value || 0}</div>,
    },
    {
      id: "actions",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Actions</div>
      ),
      accessor: "id",
      Cell: ({ value, row }: { value: any; row: any }) => (
        <div className="flex gap-x-5 px-2 items-center">
          <button
            className="cursor-pointer"
            disabled={isLoading}
            onClick={() => {
              setDeleteModal(row?.original?.id);
            }}
          >
            <TrashIcon width="16" height="16" />
          </button>
          <button disabled={isLoading}>
            <Link
              to={`/quotations/${row?.original?.id}`}
              className="cursor-pointer"
            >
              <EditIcon />
            </Link>
          </button>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMN, [quotationList]);
  const data = useMemo(() => quotationList || [], [quotationList]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: page, pageSize: pageLength },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pageLength),
    },
    useSortBy,
    usePagination
  );

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handlePageSizeChange = (size: any) => {
    setPageLength(size);
  };

  return (
    <div className="h-full">
      <div className="flex flex-row justify-between items-center w-full px-3 pt-6 pb-3">
        <div className="flex gap-4 w-[max-content]">
          <div className=" w-[235px]">
            <InputField
              onChange={(e: string) => setSearchValue(e)}
              placeholder="Search Quotation Number"
              value={searchValue}
              rightIcon={<SearchIcon />}
            />
          </div>
          <CreateQuotation getQuotationList={getQuotationList} />
        </div>
      </div>

      <div className="h-[calc(100%-5.5rem)] w-full relative -z-0">
        <ManagementTableLayout
          tableInstance={tableInstance}
          loading={isLoading}
        />
      </div>
      {isLoading ? (
        ""
      ) : (
        <div className="w-full">
          <Pagination
            currentPage={page}
            pageSize={pageLength}
            totalCount={totalCount}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
      )}

      {deleteModal && (
        <Modal
          top
          header="Delete Product"
          footer={
            <div className="flex gap-x-3">
              <Button
                variant="primary"
                label="Delete"
                onClick={() => handleDelete(deleteModal)}
                disabled={isLoading}
              />
              <Button
                variant="secondary"
                label="Cancel"
                onClick={() => {
                  setDeleteModal("");
                }}
                disabled={isLoading}
              />
            </div>
          }
          handleCancel={() => setDeleteModal("")}
        >
          <p className="text-left">
            Are you sure you want to delete the Quotation?
          </p>
        </Modal>
      )}
    </div>
  );
};

export default Table;
