import * as yup from "yup";

// Define interfaces for the component props and form data
export interface AddressFormProps {
    addressData?: any;
    setOpenInputAddress: (open: boolean) => void;
    setTargetAddress: (address?: any) => void;
    orderDetails?: any;
    customerId: string;
    getAddressList: () => void;
  }

export interface AddressData {
  full_name: string;
  kind: "SHIPPING" | "BILLING";
  phone: string;
  state: string;
  city: string;
  street_1: string;
  street_2: string;
  postal_code: string;
  tag: "HOME" | "WORK" | "OTHERS";
  country_calling_code?: string;
}

type AddressKind = "SHIPPING" | "BILLING";
type AddressTag = "HOME" | "WORK" | "OTHERS";

export const addressFormSchema = yup.object().shape({
  full_name: yup.string().required("Please enter your full name"),
  kind: yup
    .string()
    .oneOf(["SHIPPING", "BILLING"] as AddressKind[])
    .required("Please choose billing or shipping"),
  phone: yup.string().required("Please enter a phone number"),
  state: yup.string().required("Please choose a state"),
  city: yup.string().required("Please choose a city"),
  street_1: yup.string().required("Please enter address line 1"),
  street_2: yup.string().required("Please enter address line 2"),
  postal_code: yup.string().required("Please enter pin code"),
  tag: yup
    .string()
    .oneOf(["HOME", "WORK", "OTHERS"] as AddressTag[])
    .required("Please choose type of address"),
  country_calling_code: yup.string().default("+91"),
}) as yup.ObjectSchema<AddressData>;

export type AddressFormSchema = yup.InferType<typeof addressFormSchema>;
