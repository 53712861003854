import React, { useState } from "react";
import OrderTimelineTable from "./OrderTimelineTable";
import Button from "../../../components/Button";
import PrintIcon from "../../../assets/svg-tsx/PrintIcon";
import { useParams } from "react-router-dom";
import { submitTimeline } from "../../../api/orderManagement";
import { toast } from "react-toastify";
import { getStatusBgColor } from "../SingleOrderDetails";
import AddProductPopup from "./AddProductPopup";

function OrderTimelineRequested({
  data,
  orderId,
  setTimelineData,
  handleDelete,
  handleQuantity,
  setTableData,
  orderDetails,
  projectDetails,
  setOrderDetails,
}: any) {
  const { id } = useParams();
  const [openAddProductModal, setOpenAddProductModal] =
    useState<boolean>(false);
  const [tableWidth, SetTableWidth] = useState(0); // State to track bottom bar height
  
  const updateTimeline = () => {
    let itemsWithEstDates: any = [];
    let totalItems: any = [];
    data?.map((singleData: any) => {
      singleData.items?.map((item: any) => {
        totalItems.push(item);
        if (item.est_delivery_at) {
          itemsWithEstDates.push(item);
        }
      });
    });
    submitTimeline(id)
      .then((res: any) => toast.success("Timeline Submitted"))
      .catch((error: any) => {
        const errorMessage = error?.data?.errors?.general[0];
        toast.error(errorMessage);
      });
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="w-full flex justify-between">
        <h1 className="text-xl font-gilroy-bold">
          {orderDetails?.status === "AWAITING_TIMELINE" ||
          orderDetails?.status === "AWAITING_TIMELINE_CONFIRMATION"
            ? "Timeline requested"
            : "Order Items"}
          {(orderDetails?.status === "AWAITING_TIMELINE" ||
            orderDetails?.status === "AWAITING_TIMELINE_CONFIRMATION") && (
            <span
              className={`mx-2 px-1 font-gilroy-regular text-sm ${getStatusBgColor(
                orderDetails?.status
              )}`}
            >
              {orderDetails?.status === "AWAITING_TIMELINE" && "PENDING"}
            </span>
          )}
        </h1>
        <div className="flex gap-2">
          <div onClick={() => setOpenAddProductModal(true)}>
            <Button label="Add product" variant="secondary" />
            {openAddProductModal && (
              <AddProductPopup
                handleCancel={() => setOpenAddProductModal(false)}
                orderDetails={orderDetails}
                projectDetails={projectDetails}
                setTableData={setTableData}
                setOrderDetails={setOrderDetails}
              />
            )}
          </div>
          <Button
            label="Print Order"
            variant="secondary"
            leftIcon={<PrintIcon />}
          />

          {orderDetails?.status === "DRAFT" && (
            <Button label="Confirm Order" variant="secondary" />
          )}

          {orderDetails?.status === "AWAITING_TIMELINE" && (
            <Button
              label="Update Timeline"
              variant="secondary"
              onClick={updateTimeline}
            />
          )}
        </div>
      </div>
      <div className="overflow-auto custom-scrollbar">
        <div className="flex flex-col">
          {data?.map((singleData: any) => {
            if (singleData?.items.length === 0) {
              return null;
            }
            return (
              <div key={singleData?.floor?.id}>
                <h1
                  className={`${
                    orderDetails?.owner === "USER"
                      ? "hidden"
                      : singleData?.floor?.id === "no_floor" && "italic"
                  }   bg-black text-white text-lg py-1.5 font-gilroy-bold text-center sticky top-0 z-10`}
                  style={{ width: `${tableWidth}px` }}
                >
                  {singleData?.floor?.name}
                </h1>
                <OrderTimelineTable
                  data={singleData.items}
                  orderId={orderId}
                  setTimelineData={setTimelineData}
                  handleDelete={handleDelete}
                  handleQuantity={handleQuantity}
                  setTableData={setTableData}
                  setOrderDetails={setOrderDetails}
                  orderDetails={orderDetails}
                  projectDetails={projectDetails}
                  SetTableWidth={SetTableWidth}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default OrderTimelineRequested;
