
import React, { SetStateAction, Dispatch, useState, useEffect } from "react";
import IconButton from "./IconButton";
import { useSetRecoilState } from "recoil";
import { quickListAtom } from "../../atom";
import { toast } from "react-toastify";
import CrossIcon from "../../assets/CrossIcon";
import TickIcon from "../../assets/TickIcon";
import { protectedAxiosInstance } from "../../api/axiosManagement";

type EditableFieldType = {
  defaultValue: string;
  api?: () => Promise<any>;
  setValue?: Dispatch<SetStateAction<string>> | (() => void);
  close?: () => void;
  fieldType?: any;
  selectedData?: any;
};

const EditableField = ({
  defaultValue,
  api,
  setValue,
  close,
  fieldType,
  selectedData,
}: EditableFieldType) => {
  const setQuickList = useSetRecoilState(quickListAtom);
  const [project, setProject] = useState<any>();
  const [floor, setFloor] = useState<any>();
  const [area, setArea] = useState<any>();
  const [inputVal, setInputVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputVal(newValue);

    if (error) {
      setError(false);
    }

    if (fieldType === "project") {
      setProject({ name: newValue?.trim() });
    } else if (fieldType === "floor") {
      setFloor({ name: newValue?.trim() });
    } else if (fieldType === "area") {
      setArea({ name: newValue?.trim(), floor_id: selectedData?.floor?.id });
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      if (!inputVal || loading) {
        return;
      } else {
        handleSubmit();
      }
    }
  };

  // Adding the event listener
  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [inputVal, loading]);

  const handleSubmit = async () => {
    if (!inputVal || loading) {
      return;
    }
    if (project) {
      setLoading(true);
      protectedAxiosInstance
        .post(`/projects`, project)
        .then((res: any) => {
          toast.success("New project added successfully.");
          if (close) {
            close();
          }
          protectedAxiosInstance
            .get("/projects/quick-list")
            .then((res: any) => {
              setQuickList(res);
            })
              .catch((err: any) => {
              console.error("Error getting quickList data", err);
            });
        })
        .catch((error: any) => {
          const errorMessage = error?.data?.errors?.name?.[0];
          setError(true);
          toast.error(errorMessage || "Error in creating new project!");
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (area) {
      const payload = {
        floor_id: area?.floor_id,
        name: area?.name,
      };
      setLoading(true);
      protectedAxiosInstance
        .post(`/projects/${selectedData?.project?.id}/add-area`, payload)
        .then(() => {
          toast.success("New area added successfully.");
          if (close) {
            close();
          }
          protectedAxiosInstance
            .get("/projects/quick-list")
            .then((res: any) => {
              setQuickList(res);
            })
            .catch((err: any) => {
              setError(true);
              console.error("Error getting quickList data", err);
            });
        })
        .catch((error: any) => {
          toast.error("Error in creating new area!");
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (floor) {
      const payload = {
        name: floor?.name,
      };
      setLoading(true);
      protectedAxiosInstance
        .post(`/projects/${selectedData?.project?.id}/add-floor`, payload)
        .then((res: any ) => {
          toast.success("New floor added successfully.");
          protectedAxiosInstance
            .get("/projects/quick-list")
            .then((res: any) => {
              setQuickList(res);
            })
            .catch((err: any) => {
              setError(true);
              console.error("Error getting quickList data", err);
            });

          if (close) {
            close();
          }
        })
        .catch((error: any) => {
          toast.error("Error in creating new floor!");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="w-full h-full flex gap-x-1 py-[2px] px-2 ">
      <div className="flex self-stretch w-full items-center">
        <input
          autoFocus
          className={`px-2 border self-stretch text-sm w-full outline-none focus:border-pot-yellow ${
            error && "focus:!border-red-600 !border-red-600"
          }`}
          onChange={handleChange}
          value={inputVal}
          maxLength={100}
        />
      </div>

      <IconButton
        onClick={handleSubmit}
        variant="secondary"
        disabled={!inputVal || !inputVal?.trim() || loading}
      >
        <TickIcon width={"10.5"} />
      </IconButton>

      <IconButton
        onClick={close}
        variant="secondary-outline"
        disabled={loading}
      >
        <CrossIcon />
      </IconButton>
    </div>
  );
};

export default EditableField;
