import { atom } from "recoil";
import { VariantDataProps, singleProjectAtomProps } from "./types";

export const MainAppSideBarExpandAtom = atom({
  key: "sidebarExpand",
  default: false,
});
export const ResizingAtom = atom({
  key: "resizing",
  default: false,
});
export const MainAppInitailAtom = atom({
  key: "initial",
  default: true,
});

export const quickListAtom = atom({
  key: "quicklist",
  default: [] as any,
});

// for storing and updating the available list of cart
export const CartProjectsAtom = atom({
  key: "cartProjects",
  default: [] as any,
});

export const CartCheckoutDataAtom = atom({
  key: "CartCheckoutDataState",
  default: null as any,
});

export const titleAtom = atom({
  key: "title",
  default: "" as string | string[],
});

export const indexAtom = atom({
  key: "indexOfUser&Professional",
  default: 0,
});

export const LoadingProgressAtom = atom({
  key: "loadingProgress",
  default: 0,
});

export const VariantsData = atom({
  key: "VariantsData",
  default: [] as VariantDataProps[],
});

type Option = {
  id: string;
  name: string;
};

export const assignedSeriesDataAtom = atom<Option[]>({
  key: "assignedSeriesDataAtom",
  default: [],
});
export const assignedCategoryDataAtom = atom<Option[]>({
  key: "assignedCategoryDataAtom",
  default: [],
});

export const categoryDataCount = atom({
  key: "categoryCount",
  default: 0,
});
export const seriesDataCount = atom({
  key: "seriesCount",
  default: 0,
});
export const variantsDataCount = atom({
  key: "variantsCount",
  default: 0,
});

export const singleProjectAtom = atom<
  singleProjectAtomProps | undefined | null
>({
  key: "singleProjectState",
  default: null,
});

export const authAtom = atom({
  key: "authState",
  default: {
    is_auth: false,
    auth_token: "",
    refresh_token: "",
  } as any,
});

export const countryAtom = atom({
  key: "countryState",
  default: [] as any,
});
export const stateAtom = atom({
  key: "stateState",
  default: [] as any,
});

export const targetPathAtom = atom({
  key: "targetPathState",
  default: "" as string,
});

export const filterByStatusAtom = atom<string | null>({
  key: "filterByStatus",
  default: "PENDING",
});
// loaded when app starts here
export const ordeAppConfigAtom = atom({
  key: "ordeAppConfig",
  default: [] as any,
});
export const userInfoAtom = atom({
  key: "userInfoState",
  default: null as any,
});
// ends here

// product management recoil starts here
export const productCategoryAtom = atom({
  key: "productCategoryState",
  default: [] as any[],
});

export const productSeriesAtom = atom({
  key: "productSeriesState",
  default: [] as any[],
});

export const productBrandAtom = atom({
  key: "productBrandState",
  default: [] as any[],
});

export const productPropertySuggestionAtom = atom({
  key: "productPropertySuggestionState",
  default: { "product-properties": [], "option-properties": [] } as any,
});

// product management recoil ends here
