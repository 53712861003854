import Button from "../../../components/Button";
import Modal from "../../../components/common/Modal";
import React, { SetStateAction, Dispatch, useEffect, useState } from "react";
import SelectField2 from "../../../components/SelectedField2";
import { getListForChannelPartner } from "../../../api/channelPartner";
import _debounce from "lodash/debounce";
import { toast } from "react-toastify";
import { assignedChannelPartner } from "../../../api/orderManagement";

interface ChangeChannelPartnerModelProps {
  onClose: () => void;
  orderId?: string;
  setOpenChangePartner?: Dispatch<SetStateAction<boolean>>;
  orderDetails?: any;
  getSingleOrderApi?: any;
}

const ChangeChannelPartnerModel: React.FC<ChangeChannelPartnerModelProps> = ({
  onClose,
  orderId,
  setOpenChangePartner,
  orderDetails,
  getSingleOrderApi,
}) => {
  const [channelPartnerList, setChannelPartnerList] = useState([]);
  const [channelPartner, setChannelPartner] = useState<any>(null);
  const [externalChannelPartner, setExternalChannelPartner] =
    useState<any>(null);
  const [totalPage, setTotalPage] = useState(1);
  const [channlePartnerPage, setChannlePartnerPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [channelPartnerLoading, setChannelPartnerLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [time, setTime] = useState(500);
  const debouncedFetchChannelPartners = _debounce(geChannelPartnerList, time);

  async function geChannelPartnerList() {
    setChannelPartnerLoading(true);
    const params = {
      page: channlePartnerPage,
      length: 10,
      search: searchValue,
    };
    try {
      const res = await getListForChannelPartner(params);
      const newChannelPartnerList = res?.data?.channel_partners.map(
        (channelPartner: any) => ({
          value: channelPartner.id,
          label: channelPartner.name,
        })
      );

      // Update list based on page number
      setChannelPartnerList((prev) =>
        channlePartnerPage === 1
          ? newChannelPartnerList
          : [...prev, ...newChannelPartnerList]
      );

      setTotalPage(res?.data?.pagination?.total_pages);
    } catch (error) {
      console.error("Error fetching channel partner list:", error);
    } finally {
      setChannelPartnerLoading(false);
    }
  }

  // Handle pagination/infinite scroll
  useEffect(() => {
    if (channlePartnerPage <= totalPage) {
      const fetchData = async () => {
        await debouncedFetchChannelPartners();
      };
      fetchData();
    }
    return () => {
      debouncedFetchChannelPartners.cancel();
    };
  }, [channlePartnerPage]);

  // Handle search
  useEffect(() => {
    setTime(500);
    setChannlePartnerPage(1);
    setChannelPartnerList([]);
    setChannelPartnerLoading(true);
    const fetchData = async () => {
      await debouncedFetchChannelPartners();
    };
    fetchData();
    return () => {
      debouncedFetchChannelPartners.cancel();
    };
  }, [searchValue]);

  useEffect(() => {
    if (orderDetails?.assigned_channel_partner) {
      setExternalChannelPartner({
        id: orderDetails?.assigned_channel_partner?.id,
        value: orderDetails?.assigned_channel_partner?.name,
      });
      setChannelPartner(orderDetails?.assigned_channel_partner?.id);
    }
  }, [orderDetails]);

  const handleSubmit = async () => {
    const payload = {
      assigned_channel_partner: channelPartner,
    };

    try {
      setLoading(true);
      await assignedChannelPartner(orderId, payload);
      toast.success("Channel Partner assigned successfully");
      setOpenChangePartner && setOpenChangePartner(false);
      getSingleOrderApi();
    } catch (e: any) {
      toast.error(e?.data?.errors);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal handleCancel={onClose} header="Change Channel Partner">
      <div className="w-full text-black py-2">
        <div className="mt-2">
          <SelectField2
            options={channelPartnerList}
            value={channelPartnerLoading ? null : channelPartner}
            onChange={(value: any) => {
              setChannelPartner(value);
            }}
            loading={channelPartnerLoading}
            totalPage={totalPage}
            page={channlePartnerPage}
            setPage={setChannlePartnerPage}
            externalSearch={searchValue}
            externalSetSearch={setSearchValue}
            placeholder="Select Channel Partner"
            searchPlaceHolder="Select Channel Partner"
            label="Channel Partner"
            openListCss="h-[180px]"
            removeOption={() => {
              setChannelPartner(null);
            }}
            externalValue={externalChannelPartner}
            setExternalValue={setExternalChannelPartner}
          />
        </div>
        <div className="mt-9 flex gap-4">
          <Button label="Apply" disabled={loading} onClick={handleSubmit} />
          <Button
            label="Cancel"
            onClick={onClose}
            variant="secondary-outline"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ChangeChannelPartnerModel;
