import React, { useEffect, useState } from "react";
import Modal from "../../components/common/Modal";
import Button from "../../components/Button";
import PlusIcon from "../../assets/PlusIcon";
import SelectCheckboxOption from "../../components/SelectCheckboxOption";
import SelectField2 from "../../components/SelectedField2";
import { toast } from "react-toastify";
import { createQuotation } from "../../api/quotation";
import _debounce from "lodash/debounce";
import { protectedAxiosInstance } from "../../api/axiosManagement";
import InputField from "../../components/InputField";
import { useNavigate } from "react-router-dom";

const CreateQuotation = ({ getQuotationList }: any) => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [pageLength, setPageLength] = useState<number>(10);
  const [openModal, setOpenModal] = useState(false);
  const [selectedType, setSelectedType] = useState<"Regular" | "Project">(
    "Regular"
  );
  const [selectedProject, setSelectedProject] = useState<number | null>(null);
  const [projectList, setProjectList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState<any>("");
  const [projectsPageLoading, setProjectsPageLoading] = useState(true);
  const [projectWindow, setProjectWindow] = useState<Window | null>(null);

  const fetchProjects = async () => {
    setProjectsPageLoading(true);
    try {
      const res = await protectedAxiosInstance.get(
        "/admin/projects/quick-list",
        {
          params: { length: pageLength, page, search: searchText },
        }
      );

      const newProjects = res.data.data.project.map((project: any) => ({
        value: project.id,
        label: project.name,
      }));

      setProjectList((prevProjects) =>
        page === 1 ? newProjects : [...prevProjects, ...newProjects]
      );

      setTotalPage(res.data.data.pagination.total_pages);
      setProjectsPageLoading(false);
    } catch (error) {
      console.error("Error fetching projects:", error);
      setProjectsPageLoading(false);
    }
  };

  const [time, setTime] = useState(500);
  const debouncedFetchProjects = _debounce(fetchProjects, time);

  useEffect(() => {
    if (selectedType === "Project") {
      setTime(10);
      const fetchData = async () => {
        await debouncedFetchProjects();
      };
      fetchData();
      return () => {
        debouncedFetchProjects.cancel();
      };
    }
  }, [page]);

  useEffect(() => {
    if (selectedType === "Project") {
      setTime(500);
      setPage(1);
      setProjectList([]);
      setProjectsPageLoading(true);
      const fetchData = async () => {
        await debouncedFetchProjects();
      };
      fetchData();
      return () => {
        debouncedFetchProjects.cancel();
      };
    }
  }, [searchText, selectedType]);

  useEffect(() => {
    const handleMessage = async (event: MessageEvent) => {
      if (event.data?.type === "PROJECT_CREATED" && event.data?.projectId) {
        projectWindow?.close();

        try {
          const res = await protectedAxiosInstance.get(
            `/admin/projects/quick-list`,
            {
              params: { length: 1, page: 1 },
            }
          );

          const newProject = res.data.data.project.find(
            (p: any) => p.id === event.data.projectId
          );
          if (newProject) {
            const formattedProject = {
              value: newProject.id,
              label: newProject.name,
            };

            setProjectList((prev: any) => [formattedProject, ...prev]);
            setSelectedProject(newProject.id);
          }
        } catch (error) {
          console.error("Error fetching new project:", error);
        }
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, [projectWindow]);

  const handleSubmit = async () => {
    setIsLoading(true);

    if (selectedType === "Project" && !selectedProject) {
      toast.error("Project is required");
      setIsLoading(false);
      return;
    }

    const payload: any = {
      kind: selectedType.toUpperCase(),
      name: name,
    };

    if (selectedType === "Project") {
      payload.project_id = selectedProject;
    }

    try {
      const response = await createQuotation(payload);
      if (response) {
        getQuotationList();
        navigate(`/quotations/${response.data.id}`);
      }
      setIsLoading(false);
      setOpenModal(false);
    } catch (error: any) {
      setError(error.data.errors.name);
      setIsLoading(false);
    }
  };

  function onButtonClick() {
    const newWindow = window.open(
      `/projects/create/?window_popup=1`,
      "_blank",
      "location=yes,height=570,width=1120,scrollbars=yes,status=yes"
    );
    setProjectWindow(newWindow);
  }
  return (
    <div className="flex flex-col gap-4">
      <div className="w-fit">
        <Button
          label="Create Quotation"
          leftIcon={<PlusIcon />}
          onClick={() => setOpenModal(true)}
        />
      </div>
      <form>
        {openModal && (
          <Modal
            clickOutsideToClose
            allowScroll
            header={"Create New Quotation"}
            footer={
              <Button
                variant="primary"
                label="Continue"
                onClick={handleSubmit}
                disabled={isLoading}
              />
            }
            handleCancel={() => setOpenModal(false)}
            maxHeight="overflow-y-visible"
          >
            <div className="flex flex-col gap-3 w-full">
              <div className="w-[30.5rem]">
                <InputField
                  label="Name"
                  placeholder="Enter quotation name"
                  value={name}
                  onChange={(value) => {
                    if (error) {
                      setError("");
                    }
                    setName(value);
                  }}
                  hint={error}
                  required
                />
              </div>
              <p className="font-gilroy-semi-bold text-[12px] leading-4	flex justify-start">
                Type
              </p>
              <div className="flex flex-col gap-4">
                <div className="flex gap-4 mb-2">
                  <SelectCheckboxOption
                    kind="Regular"
                    isSelected={selectedType === "Regular"}
                    handleSelection={() => setSelectedType("Regular")}
                    disabled={isLoading}
                  />
                  <SelectCheckboxOption
                    kind="Project"
                    isSelected={selectedType === "Project"}
                    handleSelection={() => setSelectedType("Project")}
                    disabled={isLoading}
                  />
                </div>
                {selectedType === "Project" && (
                  <div className="w-[30.5rem] mb-4">
                    <SelectField2
                      loading={projectsPageLoading}
                      totalPage={totalPage}
                      page={page}
                      setPage={setPage}
                      options={projectList}
                      value={selectedProject}
                      onChange={(value: any) => setSelectedProject(value)}
                      placeholder="Select a project"
                      externalSearch={searchText}
                      externalSetSearch={setSearchText}
                      searchPlaceHolder="Search project"
                      disabled={isLoading}
                      openListCss="overflow-y-scroll h-[180px]"
                    />
                    <button
                      onClick={onButtonClick}
                      className="cursor-pointer underline-offset-2 underline text-pot-yellow text-[12px] ml-[2px] font-gilroy-semi-bold"
                    >
                      Create New Project
                    </button>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        )}
      </form>
    </div>
  );
};

export default CreateQuotation;
