import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../components/common/Modal";
import ProfessionalDropdown from "../../../components/ProfessionalDropdown";
import { getProfessionalList } from "../../../api/professional";
import { protectedAxiosInstance } from "../../../api/axiosManagement";
import { toast } from "react-toastify";

const AddDiscountModal = ({
  showProfessionalModal,
  setShowProfessionalModal,
  quotationId,
  getSingleQuotation,
}: {
  showProfessionalModal: boolean;
  setShowProfessionalModal: (showProfessionalModal: boolean) => void;
  quotationId: any;
  getSingleQuotation: any;
}) => {
  const [professionalList, setProfessionalList] = useState<any>([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(200);
  const [professionalId, setProfessionalId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const debounceTimeoutRef = useRef<any>(null);

  const addProfessional = (data: any) => {
    const requestBody = {
      professional_id:
        data?.kind === "p_company"
          ? data?.p_company_id
          : data?.kind === "p_individual"
          ? data?.p_individual_id
          : null,
      professional_kind: data?.kind,
    };

    protectedAxiosInstance
      .put(`/admin/quotation/${quotationId}`, requestBody)
      .then((res: any) => {
        getSingleQuotation(quotationId);
        setShowProfessionalModal(false);
      })
      .catch((error) => {
        toast.error(error?.data?.errors?.professional_id?.[0]);
        setShowProfessionalModal(false);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProfessionalList({
          search: debouncedSearchTerm || "",
          page: currentPage || "",
          length: totalPages || "",
        });
        setProfessionalList(response?.data?.professional);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchData();
  }, [debouncedSearchTerm, currentPage, totalPages]);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      setDebouncedSearchTerm(searchQuery);
    }, 400);
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [searchQuery]);

  return (
    <div>
      {showProfessionalModal && (
        <Modal
          clickOutsideToClose
          allowScroll
          header="Add a professional"
          handleCancel={() => setShowProfessionalModal(false)}
          minHeight="!h-[350px] relative"
        >
          <div className="w-[90%] absolute top-10 left-[48%] -translate-x-1/2 mt-4">
            <ProfessionalDropdown
              smallText
              width="100%"
              height="100%"
              Xsearch={searchQuery}
              setXSearch={setSearchQuery}
              options={professionalList.map((el: any) => ({
                value: el.p_individual_id,
                label: el,
              }))}
              onChange={(e: any) => {
                setProfessionalId(e?.value);
                addProfessional(e?.label);
              }}
              value={professionalId}
              searchPlaceHolder="Search professional"
              requiredAllData={true}
              onClear={() => [setProfessionalId("")]}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AddDiscountModal;
