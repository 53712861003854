import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { titleAtom } from "../../atom";
import Button from "../../components/Button";
import SelectCheckboxOption from "../../components/SelectCheckboxOption";
import AuthInputField from "../../components/AuthInputField";
import { useNavigate, useParams } from "react-router-dom";
import {
  getSingleAdminUsers,
  getUsersRoles,
  postAdminUsers,
  updateAdminUsers,
} from "../../api/user";
import SingleSelection from "../../components/SingleSelection";
import { getCountryCallingCode, isSupportedCountry } from "libphonenumber-js";
import { toast } from "react-toastify";
import SelectField2 from "../../components/SelectedField2";
import { checkPermissions } from "../../utils/helper";
import { getListForChannelPartner } from "../../api/channelPartner";
import _debounce from "lodash/debounce";

function CreateUser() {
  const { id: userID } = useParams();
  const navigate = useNavigate();
  const setTitle = useSetRecoilState(titleAtom);
  const [errorMessage, setErrorMessage] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    phonerNumber: "",
    gender: "",
    role: "",
    designConsultant: "",
    channelPartner: "",
  });
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [channelPartner, setChannelPartner] = useState<any>(null);
  const [phonerNumber, setPhoneNumber] = useState<string>("");
  const [isDesignConsultant, setIsDesignConsultant] = useState(false);
  const [countryCode, setCountryCode] = useState<any>();
  const [selectedGender, setSelectedGender] = useState<string | null>(null);
  const [pageLength, setPageLength] = useState<number>(200);
  const [page, setPage] = useState<any>(1);
  const [channlePartnerPage, setChannlePartnerPage] = useState<any>(1);
  const [loading, setLoading] = useState(true);
  const [userRolesData, setUserRolesData] = useState<Array<any>>([]);
  const [selectedRole, setSelectedRole] = useState<any>(null);
  const [channelPartnerList, setChannelPartnerList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [externalChannelPartner, setExternalChannelPartner] =
    useState<any>(null);

  const hasEditPermission: any =
    userID && checkPermissions("edit_user", true, toast);

  const hasCreatePermission =
    !userID && checkPermissions("create_user", true, toast);

  const [time, setTime] = useState(500);
  const debouncedFetchChannelPartners = _debounce(geChannelPartnerList, time);

  async function geChannelPartnerList() {
    const params = {
      page: channlePartnerPage,
      length: 10,
      search: searchValue,
    };
    try {
      const res = await getListForChannelPartner(params);
      const newChannelPartnerList = res?.data?.channel_partners.map(
        (channelPartner: any) => ({
          value: channelPartner.id,
          label: channelPartner.name,
        })
      );

      // Update list based on page number
      setChannelPartnerList((prev) =>
        channlePartnerPage === 1
          ? newChannelPartnerList
          : [...prev, ...newChannelPartnerList]
      );

      setTotalPage(res?.data?.pagination?.total_pages);
    } catch (error) {
      console.error("Error fetching channel partner list:", error);
    }
  }

  useEffect(() => {
    if (userID) {
      // Fetch existing user data
      setLoading(true);
      getSingleAdminUsers(userID)
        .then((res: any) => {
          const userData = res.data.admin;
          const nameParts = userData.name.split(" ");
          setFirstName(nameParts[0]);
          setLastName(nameParts.slice(1).join(" "));
          setEmail(userData?.email);
          setPhoneNumber(userData?.phone.slice(3));
          setSelectedGender(userData.gender.toLowerCase());
          setSelectedRole(userData?.role?.id);
          setChannelPartner(userData?.channel_partner?.id);
          setExternalChannelPartner({
            id: userData?.channel_partner?.id,
            value: userData?.channel_partner?.name,
          });
          setIsDesignConsultant(userData.is_design_consultant);
          setLoading(false);
        })
        .catch((error: any) => {
          console.error("Error fetching user data:", error);
          toast.error("Error loading user data");
          setLoading(false);
        });
    }
  }, [userID]);

  const handleSelection = (gender: string) => {
    setSelectedGender(gender);
    if (errorMessage.gender) {
      setErrorMessage({ ...errorMessage, gender: "" });
    }
  };

  const handleSubmit = async () => {
    const contact = countryCode + phonerNumber;
    const gender =
      selectedGender === "female"
        ? "FEMALE"
        : selectedGender === "male"
        ? "MALE"
        : selectedGender === "others"
        ? "OTHERS"
        : "";
    // "Must be one of: MALE, FEMALE, OTHERS."

    const payload = {
      name: firstName + " " + lastName,
      email: email,
      phone: contact,
      gender: gender,
      role: selectedRole,
      is_design_consultant: isDesignConsultant,
      channel_partner_id: channelPartner,
    };

    try {
      setLoading(true);
      if (userID) {
        await updateAdminUsers(userID, payload);
        toast.success("User updated successfully");
      } else {
        await postAdminUsers(payload);
        toast.success("Admin user created successfully");
      }
      navigate("/users");
    } catch (e: any) {
      if (e.data && e.data.errors) {
        const errors = e.data.errors;
        setErrorMessage((prev: any) => ({
          ...prev,
          firstName: errors.name ? errors.name[0] : "",
          email: errors.email ? errors.email[0] : "",
          phonerNumber: errors.phone ? errors.phone[0] : "",
          gender: errors.gender ? errors.gender[0] : "",
          role: errors.role ? errors.role[0] : "",
          designConsultant: errors.is_design_consultant
            ? errors.is_design_consultant[0]
            : "",
          channelPartner: errors.channel_partner_id
            ? errors.channel_partner_id[0]
            : "",
        }));
      }
      toast.error(e?.data?.errors);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getUsersRoles({ page: page, length: pageLength })
      .then((res) => {
        const newRoles = res.data.roles.map((role: any) => ({
          value: role.id,
          label: role.name,
        }));
        setUserRolesData(newRoles);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [page, pageLength]);

  // Handle pagination/infinite scroll
  useEffect(() => {
    if (channlePartnerPage <= totalPage) {
      const fetchData = async () => {
        await debouncedFetchChannelPartners();
      };
      fetchData();
    }
    return () => {
      debouncedFetchChannelPartners.cancel();
    };
  }, [channlePartnerPage]);

  // Handle search
  useEffect(() => {
    setTime(500);
    setChannlePartnerPage(1);
    setChannelPartnerList([]);
    const fetchData = async () => {
      await debouncedFetchChannelPartners();
    };
    fetchData();
    return () => {
      debouncedFetchChannelPartners.cancel();
    };
  }, [searchValue]);

  useEffect(() => {
    if (userID) {
      document.title = `Admin User - ${firstName + " " + lastName}`;
    }
  }, [userID, firstName, lastName]);

  if (!hasCreatePermission && !userID) {
    navigate("/users");
  }

  if (!hasEditPermission && userID) {
    navigate("/users");
  }
  return (
    <div className="h-[calc(100vh-2.5rem)] no-scrollbar w-full flex flex-col justify-start items-start">
      {/* heading */}
      <div className="bg-white border-b border-[#ECECEC] w-full pt-4 pb-4  px-4">
        <p className="flex font-gilroy-medium text-[14px] leading-[20p[x]]">
          {userID ? "Edit User" : "Create new User"}
        </p>
      </div>

      <div className="flex w-full justify-between  flex-1  overflow-y-auto custom-scrollbar  py-3">
        <div className="w-[537px] overflow-y-auto custom-scrollbar px-4">
          <div className="flex flex-col">
            <div className="flex gap-3 ">
              <AuthInputField
                onChange={(e: any) => {
                  setFirstName(e);
                  if (errorMessage.firstName) {
                    setErrorMessage({ ...errorMessage, firstName: "" });
                  }
                }}
                value={firstName}
                placeholder="First name"
                heading="Enter first name"
                hint={errorMessage?.firstName}
                required
              />

              <AuthInputField
                onChange={(e: any) => {
                  setLastName(e);
                  if (errorMessage.lastName) {
                    setErrorMessage({ ...errorMessage, lastName: "" });
                  }
                }}
                setCode={(code) => setCountryCode(code)}
                value={lastName}
                placeholder="Last name"
                heading="Enter last name"
                errorMessage={errorMessage?.lastName || ""}
              />
            </div>

            <div className=" flex flex-col mt-2 gap-2">
              <AuthInputField
                onChange={(e: any) => {
                  setEmail(e);
                  if (errorMessage.email) {
                    setErrorMessage({ ...errorMessage, email: "" });
                  }
                }}
                value={email}
                placeholder="eg. abc@xyz.com"
                heading="Enter email id"
                hint={errorMessage.email}
                type="text"
                required
              />
              <AuthInputField
                onChange={(e: any) => {
                  setPhoneNumber(e);
                  if (errorMessage.phonerNumber) {
                    setErrorMessage({ ...errorMessage, phonerNumber: "" });
                  }
                }}
                country_code_value={
                  isSupportedCountry(countryCode)
                    ? `+${getCountryCallingCode(countryCode)}`
                    : `+${getCountryCallingCode("IN")}`
                }
                setCode={(e: any) => {
                  setCountryCode(e);
                }}
                value={phonerNumber}
                placeholder="XXXXXXXXXX"
                heading="Phone number"
                hint={errorMessage?.phonerNumber}
                type="telephone"
                maxLength={15}
                required
              />
              <div className="flex flex-col justify-start text-left ">
                <h2 className="text-[12px] font-medium"> Gender</h2>
                <div className="flex  items-center space-x-12 mt-2">
                  <SelectCheckboxOption
                    kind="male"
                    isSelected={selectedGender === "male"}
                    handleSelection={handleSelection}
                  />
                  <SelectCheckboxOption
                    kind="female"
                    isSelected={selectedGender === "female"}
                    handleSelection={handleSelection}
                  />
                  <SelectCheckboxOption
                    kind="others"
                    isSelected={selectedGender === "others"}
                    handleSelection={handleSelection}
                  />
                </div>
                {errorMessage?.gender && (
                  <p className="flex text-left font-gilroy-bold text-[0.7rem] text-[#FC5A5A] tracking-wider relative h-0 m-0">
                    {errorMessage?.gender}
                  </p>
                )}
              </div>
              <div className="flex flex-col justify-start text-left mt-2">
                <div className="mt-1 mb-3">
                  <SelectField2
                    loading={loading}
                    options={userRolesData}
                    value={selectedRole}
                    onChange={(value: any) => {
                      setSelectedRole(value);
                      setErrorMessage({ ...errorMessage, role: "" });
                    }}
                    placeholder="Select a role"
                    searchPlaceHolder="Search role"
                    disabled={loading}
                    hint={errorMessage?.role}
                    label="Role"
                    required
                  />
                </div>
                <div className="mt-1 mb-3">
                  <SelectField2
                    options={channelPartnerList}
                    value={channelPartner}
                    onChange={(value: any) => {
                      setChannelPartner(value);
                      setErrorMessage({ ...errorMessage, channelPartner: "" });
                    }}
                    totalPage={totalPage}
                    page={channlePartnerPage}
                    setPage={setChannlePartnerPage}
                    externalSearch={searchValue}
                    externalSetSearch={setSearchValue}
                    placeholder="Select a role"
                    searchPlaceHolder="Select Channel Partner"
                    hint={errorMessage?.channelPartner}
                    label="Assign to Channel Partner"
                    openListCss="h-[180px]"
                    removeOption={() => {
                      setChannelPartner(null);
                      setErrorMessage({ ...errorMessage, channelPartner: "" });
                    }}
                    externalValue={externalChannelPartner}
                    setExternalValue={setExternalChannelPartner}
                  />
                </div>
                <div>
                  <div className="w-fit">
                    <SingleSelection
                      isSelected={isDesignConsultant}
                      onClick={() => setIsDesignConsultant(!isDesignConsultant)}
                      label="  This user is a Design Consultant"
                    />
                  </div>
                </div>
                {errorMessage?.designConsultant && (
                  <p className="flex text-left font-light text-xs text-red-700 relative h-0 m-0">
                    {errorMessage?.designConsultant}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="px-4 border-r border-[#ECECEC]"></div>
      </div>

      {/* footer */}
      <div className="flex gap-x-4 w-[100%] py-3 border-t  border-[#ECECEC] px-4">
        <Button
          label={userID ? "Update" : "Create"}
          variant="primary"
          onClick={handleSubmit}
        />
        <Button
          label="Cancel "
          variant="secondary-outline"
          onClick={() => navigate("/users")}
        />
      </div>
    </div>
  );
}

export default CreateUser;
