import {
  protectedAxiosInstance,
  unprotectedAxiosInstance,
} from "./axiosManagement";

export const getOrderList = (params) => {
  return protectedAxiosInstance
    .get("/admin/orders", { params })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const postOrderItem = (id, payload) => {
  return protectedAxiosInstance
    .post(`/admin/orders/${id}/items`, payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getSingleOrderDetails = (id, params) => {
  return protectedAxiosInstance
    .get(`/admin/orders/${id}`, { params })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getOrderTimeline = (id, params) => {
  return protectedAxiosInstance
    .get(`/admin/orders/${id}/activity-log`, { params })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getOrderItems = (id) => {
  return protectedAxiosInstance
    .get(`/admin/orders/${id}/items`)
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
};

export const updateOrderItem = (orderId, itemId, payload) => {
  return protectedAxiosInstance
    .patch(`/admin/orders/${orderId}/items/${itemId}`, payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
export const updateSpecificOrder = (orderId, payload) => {
  return protectedAxiosInstance
    .patch(`/admin/orders/${orderId}`, payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const deleteOrderItem = (orderId, itemId) => {
  return protectedAxiosInstance
    .delete(`/admin/orders/${orderId}/items/${itemId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const submitTimeline = (orderId) => {
  return protectedAxiosInstance
    .get(`/admin/orders/${orderId}/submit-timeline`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const postOrderDraft = (payload) => {
  return protectedAxiosInstance
    .post("/admin/orders/create-draft", payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

// export const updateOrder = (id, payload) => {
//   return protectedAxiosInstance
//     .put(`/admin/products/category/${id}`, payload)
//     .then((response) => response.data)
//     .catch((error) => {
//       throw error;
//     });
// };

// export const deleteProductCategory = (id) => {
//   return protectedAxiosInstance
//     .delete(`/admin/products/category/${id}`)
//     .then((response) => response.data)
//     .catch((error) => {
//       throw error;
//     });
// };

export const getOrderStatusSummary = (params) => {
  return protectedAxiosInstance
    .get("/admin/orders/status-summary", { params })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getOrderAppConfig = (params) => {
  return unprotectedAxiosInstance
    .get("/admin/app-config", { params })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getCustomersAddressList = (customerID) => {
  return protectedAxiosInstance
    .get(`/admin/accounts/customers/${customerID}/address`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const assignedChannelPartner = (orderId, payload) => {
  return protectedAxiosInstance
    .post(`/admin/orders/${orderId}/re-assign-channel-partner`, payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
