import { protectedAxiosInstance } from "./axiosManagement";

export const getAdminUsers = (params) => {
  return protectedAxiosInstance
    .get("/admin/accounts/admin-users", { params })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
export const getSingleAdminUsers = (id) => {
  return protectedAxiosInstance
    .get(`/admin/accounts/admin-users/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const updateAdminUsers = (id, payload) => {
  return protectedAxiosInstance
    .patch(`/admin/accounts/admin-users/${id}`, payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const postAdminUsers = (payload) => {
  return protectedAxiosInstance
    .post("/admin/accounts/admin-users", payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

// /admin/accounts/admin-users/available-permissions
export const getAdminUserAvailablePermission = (params) => {
  return protectedAxiosInstance
    .get("/admin/accounts/admin-users/available-permissions", { params })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getAdminUserAvailablePermissionRoles = () => {
  return protectedAxiosInstance
    .get("/admin/accounts/available-permissions")
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getUsersRoles = (payload) => {
  return protectedAxiosInstance
    .get("/admin/accounts/role", { params: payload })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getSingleUserRole = (id) => {
  return protectedAxiosInstance
    .get(`/admin/accounts/role/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const updateRoleStatus = (id, payload) => {
  return protectedAxiosInstance
    .put(`/admin/accounts/role/${id}/change-status`, payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
