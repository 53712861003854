import React, { useMemo, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import InputField from "../../../../components/InputField";
import SearchIcon from "../../../../assets/SearchIcon";
import { Link } from "react-router-dom";
import Button from "../../../../components/Button";
import ManagementTableLayout from "../../../../components/MangementTable";
import Pagination from "../../../../components/Pagination";
import TrashIcon from "../../../../assets/TrashIcon";
import EditIcon from "../../../../assets/EditIcon";
import Modal from "../../../../components/common/Modal";
import { protectedAxiosInstance } from "../../../../api/axiosManagement";
import { toast } from "react-toastify";
import PlusIcon from "../../../../assets/PlusIcon";
import {
  getListForChannelPartner,
  updateChannelPartnerStatus,
} from "../../../../api/channelPartner";
import activeStatus from "../../../../assets/svg/activestatus.svg";
import DeActiveStatus from "../../../../assets/svg/deactivatestatus.svg";

const Table = ({
  page,
  setPage,
  pageLength,
  setPageLength,
  searchValue,
  setSearchValue,
  channelPartnerList,
  isLoading,
  totalCount,
  setTotalCount,
  setIsLoading,
  setChannelPartnerList,
}: any) => {
  const [deleteModal, setDeleteModal] = useState<any>("");
  const [isTogglingStatus, setIsTogglingStatus] = useState("");

  const handleDelete = async (id: string) => {
    setIsLoading(true);
    try {
      await protectedAxiosInstance.delete(
        `/admin/accounts/channel-partner/${id}`
      );
      toast.success("Channel Partner deleted successfully");
      const res = await getListForChannelPartner({
        page: page,
        length: pageLength,
        search: searchValue,
      });
      setChannelPartnerList(res?.data?.channel_partners);
      setTotalCount(res.data.pagination.total);
    } catch (error: any) {
      toast.error(error?.data?.errors?.error);
      console.log(error);
    } finally {
      setIsLoading(false);
      setDeleteModal("");
    }
  };

  const handleStatusToggle = async (
    partnerId: string,
    currentStatus: boolean
  ) => {
    if (isTogglingStatus) return;
    setIsTogglingStatus(partnerId);

    const payload = {
      is_active: !currentStatus,
    };

    try {
      // Update partner status via API PATCH request
      const response = await updateChannelPartnerStatus(partnerId, payload);
      if (response.data) {
        // Fetch the updated partner list after status update
        const updatedPartners = await getListForChannelPartner({
          page,
          length: pageLength,
          search: searchValue,
        });
        setChannelPartnerList(updatedPartners?.data?.channel_partners || []);
        toast.success("Status updated successfully");
      }
    } catch (error: any) {
      const { errors } = error.data;
      toast.error(errors);

      // Revert the toggle in the UI (assuming 'currentStatus' is the correct state)
      setChannelPartnerList((prevData: any) =>
        prevData.map((item: any) => {
          if (item.id === partnerId) {
            return {
              ...item,
              is_active: currentStatus,
            };
          }
          return item;
        })
      );
    } finally {
      setIsTogglingStatus("");
    }
  };
  const COLUMN = [
    {
      id: "name",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center py-2">Partner Name</div>
      ),
      accessor: "name",
      Cell: ({ row }: any) => {
        return <div className="px-2 py-2">{row?.original?.name || "-"}</div>;
      },
    },
    {
      id: "phone",
      Header: ({ column }: { column: any }) => {
        return (
          <div className="flex items-center justify-between gap-1">
            Phone Number
          </div>
        );
      },
      accessor: "phone",
      Cell: ({ row }: { row: any }) => <div>{row?.original?.phone || "-"}</div>,
    },
    {
      id: "addr_city",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Office Location</div>
      ),
      accessor: "addr_city",
      Cell: ({ row }: any) => (
        <div>
          {row?.original?.addr_city}, {row?.original?.addr_state}
        </div>
      ),
    },
    {
      id: "gst_no",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">GST number</div>
      ),
      accessor: "gst_no",
      Cell: ({ value }: any) => <div>{value}</div>,
    },
    {
      id: "order_count",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Assigned Orders</div>
      ),
      accessor: "order_count",
      Cell: ({ value }: any) => <div>{value || 0}</div>,
    },
    {
      id: "assigned_pincodes_count",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Assigned Pincodes</div>
      ),
      accessor: "assigned_pincodes_count",
      Cell: ({ value }: any) => <div>{value || 0}</div>,
    },
    {
      id: "assigned_cities_count",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Assigned Cities</div>
      ),
      accessor: "assigned_cities_count",
      Cell: ({ value }: any) => <div>{value || 0}</div>,
    },
    {
      id: "is_active",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Status</div>
      ),
      accessor: "is_active",
      Cell: ({ value, row }: any) => (
        <div className="flex items-center">
          <span
            className={`p-1 rounded-md font-gilroy-medium text-sm leading-5 text-[#1C1C1C]  ${
              value ? "bg-[#BBF8DF]" : "bg-[#FFB4A1]"
            }`}
          >
            {value ? "Active" : "Inactive"}
          </span>
        </div>
      ),
    },
    {
      id: "actions",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Actions</div>
      ),
      accessor: "id",
      Cell: ({ value, row }: { value: any; row: any }) => (
        <div className="flex gap-x-5 px-2 items-center">
          <Link
            to={`/channel-partner/${row.original.id}/edit`}
            className="cursor-pointer"
          >
            <button className="cursor-pointer" disabled={isLoading}>
              <EditIcon width="16" height="16" />
            </button>
          </Link>
          <button
            className="cursor-pointer"
            disabled={isLoading}
            onClick={() => setDeleteModal(row?.original?.id)}
          >
            <TrashIcon width="16" height="16" />
          </button>
          <div
            onClick={() => {
              setChannelPartnerList((prevData: any) =>
                prevData.map((item: any) => {
                  if (item.id === row.original.id) {
                    return {
                      ...item,
                      is_active: !row.original.is_active,
                    };
                  }
                  return item;
                })
              );
              handleStatusToggle(row.original.id, row.original.is_active);
            }}
            className="stroke-grey cursor-pointer bg-white p-1 rounded-md hover:bg-red hover:stroke-white h-8 w-12"
          >
            {row.original.is_active ? (
              <img
                src={activeStatus}
                alt="status-icon"
                className="w-full object-contain h-full"
              />
            ) : (
              <img
                src={DeActiveStatus}
                alt="status-icon"
                className="w-full object-contain h-full"
              />
            )}
          </div>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMN, [channelPartnerList]);
  const data = useMemo(() => channelPartnerList || [], [channelPartnerList]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: page, pageSize: pageLength },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pageLength),
    },
    useSortBy,
    usePagination
  );

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handlePageSizeChange = (size: any) => {
    setPageLength(size);
  };

  return (
    <div className="h-full">
      <div className="flex flex-row justify-between items-center w-full px-3 pt-6 pb-3">
        <div className="flex gap-4 w-[max-content]">
          <div className=" w-[235px]">
            <InputField
              onChange={(e: string) => setSearchValue(e)}
              placeholder="Search"
              value={searchValue}
              rightIcon={<SearchIcon />}
            />
          </div>
          <Link to={"/channel-partner/create"}>
            <Button label="Create New Partner" leftIcon={<PlusIcon />} />
          </Link>
        </div>
      </div>

      <div className="h-[calc(100%-5.5rem)] w-full relative -z-0">
        <ManagementTableLayout
          tableInstance={tableInstance}
          loading={isLoading}
        />
      </div>
      {isLoading ? (
        ""
      ) : (
        <div className="w-full">
          <Pagination
            currentPage={page}
            pageSize={pageLength}
            totalCount={totalCount}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
      )}

      {deleteModal && (
        <Modal
          top
          header="Delete Channel Partner"
          footer={
            <div className="flex gap-x-3">
              <Button
                variant="primary"
                label="Delete"
                onClick={() => handleDelete(deleteModal)}
                disabled={isLoading}
              />
              <Button
                variant="secondary"
                label="Cancel"
                onClick={() => {
                  setDeleteModal("");
                }}
                disabled={isLoading}
              />
            </div>
          }
          handleCancel={() => setDeleteModal("")}
        >
          <p className="text-left">
            Are you sure you want to delete the Channel Partner?
          </p>
        </Modal>
      )}
    </div>
  );
};

export default Table;
