import Button from "../../../components/Button";
import { useState } from "react";
import ChangeChannelPartnerModel from "./ChangeChannelPartnerModel";

const AssignPartner = ({ orderId, orderDetails,getSingleOrderApi }: any) => {
  const [openChangePartner, setOpenChangePartner] = useState(false);

  return (
    <div className="text-start px-4 py-5 h-full">
      <div className="w-full flex justify-between">
        <div className="flex flex-col gap-2">
          <p className="text-[#AEAEAE] text-[12px]">Assigned Partner</p>
          <p className="font-gilroy-medium text-[12px] leading-5">
            {orderDetails?.assigned_channel_partner?.name || "-"}
          </p>
        </div>

        <Button
          className="h-7 font-thin"
          label="Change"
          variant="secondary"
          onClick={() => setOpenChangePartner(true)}
        />
        {openChangePartner && (
          <ChangeChannelPartnerModel
            onClose={() => setOpenChangePartner(false)}
            setOpenChangePartner={setOpenChangePartner}
            orderId={orderId}
            orderDetails={orderDetails}
            getSingleOrderApi={getSingleOrderApi}
          />
        )}
      </div>
    </div>
  );
};

export default AssignPartner;
