import React, { useEffect, useMemo, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import { Link } from "react-router-dom";
import ManagementTableLayout from "../../components/MangementTable";
import InputField from "../../components/InputField";
import SearchIcon from "../../assets/SearchIcon";
import Button from "../../components/Button";
import PlusIcon from "../../assets/PlusIcon";
import { useSticky } from "react-table-sticky";
import { useSetRecoilState } from "recoil";
import { titleAtom } from "../../atom";
import Pagination from "../../components/Pagination";
import EditIcon from "../../assets/EditIcon";
import { getUsersRoles, updateRoleStatus } from "../../api/user";
import TrashIcon from "../../assets/TrashIcon";
import { toast } from "react-toastify";
import { protectedAxiosInstance } from "../../api/axiosManagement";
import Modal from "../../components/common/Modal";
import activeStatus from "../../assets/svg/activestatus.svg";
import DeActiveStatus from "../../assets/svg/deactivatestatus.svg";

function UserRoles() {
  const setTitle = useSetRecoilState(titleAtom);
  const [totalCount, setTotalCount] = useState<number>(100);
  const [pageLength, setPageLength] = useState<number>(25);
  const [page, setPage] = useState<any>(1);
  const [loading, setLoading] = useState(true);
  const [deleteRoleLoading, setDeleteRoleLoading] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const [userRolesData, setUserRolesData] = useState<Array<any>>([]);
  const [deleteModal, setDeleteModal] = useState<any>("");
  const [isTogglingStatus, setIsTogglingStatus] = useState("");

  const handleStatusToggle = async (roleId: string, currentStatus: boolean) => {
    if (isTogglingStatus) return;
    setIsTogglingStatus(roleId);

    const payload = {
      is_active: !currentStatus,
    };

    try {
      // Update user status via API PATCH request
      const response = await updateRoleStatus(roleId, payload);
      if (response.data) {
        // Fetch the updated user list after status update
        const updatedRoles = await getUsersRoles({
          page,
          length: pageLength,
          search: inputVal,
        });
        setUserRolesData(updatedRoles?.data?.roles || []);
        toast.success("Status updated successfully");
      }
    } catch (error: any) {
      const { errors } = error.data;
      toast.error(errors);

      // Revert the toggle in the UI (assuming 'currentStatus' is the correct state)
      setUserRolesData((prevData: any) =>
        prevData.map((role: any) => {
          if (role.id === roleId) {
            return {
              ...role,
              is_active: currentStatus,
            };
          }
          return role;
        })
      );
    } finally {
      setIsTogglingStatus("");
    }
  };
  const COLUMN = [
    {
      id: "role_name",
      width: 280,
      Header: ({ column }: { column: any }) => (
        <div className=" w-[100px]">
          <div className="flex justify-start items-center font-gilroy-semibold font-weight-500 text-[12px] text-[#242424]">
            Role Name
          </div>
        </div>
      ),
      accessor: "role_name",
      Cell: ({ row }: { row: any }) => (
        <div className="py-2">
          <p>{row.original.name || "-"}</p>
        </div>
      ),
    },
    {
      id: "permission_count",
      width: 280,
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center font-gilroy-semibold font-weight-500 text-[12px] text-[#242424]">
          Permission Count
        </div>
      ),
      accessor: "permission_count",
      Cell: ({ row }: { row: any }) => (
        <div className="flex justify-start items-center">
          <p>{row.original.permissions_count}</p>
        </div>
      ),
    },
    {
      id: "user_count",
      width: 280,
      Header: ({ row }: { row: any }) => (
        <div className="flex justify-start items-center">Users Count</div>
      ),
      accessor: "user_count",
      Cell: ({ row }: { row: any }) => <div>{row.original.user_count}</div>,
    },

    {
      id: "actions",
      Header: () => (
        <div className="flex w-full justify-start font-gilroy-semibold font-weight-500 text-[12px] text-[#242424]">
          Action
        </div>
      ),
      Cell: ({ row }: { row: any }) => (
        <div className="flex justify-start items-center gap-4">
          <div
            onClick={() => {}}
            className="stroke-grey cursor-pointer bg-white p-1 rounded-md hover:bg-red hover:stroke-white"
          >
            <Link
              to={`/users/roles/${row.original.id}/edit`}
              className="cursor-pointer"
            >
              <EditIcon height="20" width="20" />
            </Link>
          </div>
          <div
            onClick={() => {
              setDeleteModal(row.original.id);
            }}
            className="cursor-pointer"
          >
            <TrashIcon />
          </div>
          <div
            onClick={() => {
              setUserRolesData((prevData: any) =>
                prevData.map((role: any) => {
                  if (role.id === row.original.id) {
                    return {
                      ...role,
                      is_active: !row.original.is_active,
                    };
                  }
                  return role;
                })
              );
              handleStatusToggle(row.original.id, row.original.is_active);
            }}
            className="stroke-grey cursor-pointer bg-white p-1 rounded-md hover:bg-red hover:stroke-white h-8 w-12"
          >
            {row.original.is_active ? (
              <img
                src={activeStatus}
                alt="status-icon"
                className="w-full object-contain h-full"
              />
            ) : (
              <img
                src={DeActiveStatus}
                alt="status-icon"
                className="w-full object-contain h-full"
              />
            )}
          </div>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMN, [userRolesData]);
  const data = useMemo(() => userRolesData, [userRolesData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: page, pageSize: pageLength },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pageLength),
      useSticky,
    },
    useSortBy,
    usePagination
  );

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handlePageSizeChange = (size: any) => {
    setPageLength(size);
  };

  const handleDelete = async (id: string) => {
    setDeleteRoleLoading(true);
    try {
      const response = await protectedAxiosInstance.delete(
        `/admin/accounts/role/${id}`
      );
      const res = await getUsersRoles({
        page: page,
        length: pageLength,
        search: inputVal,
      });
      setUserRolesData(res?.data?.roles);
      setTotalCount(res.data.pagination.total);
      toast.success("Role deleted successfully");
    } catch (error: any) {
      toast.error(error.data.errors);
      console.log(error);
    } finally {
      setDeleteRoleLoading(false);
      setDeleteModal("");
    }
  };

  useEffect(() => {
    setLoading(true);
    getUsersRoles({ page: page, length: pageLength, search: inputVal })
      .then((res) => {
        setUserRolesData(res?.data?.roles);
        setTotalCount(res.data.pagination.total);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [page, pageLength, inputVal]);

  return (
    <div className="h-full overflow-x-hidden">
      <div className="flex flex-row justify-between items-center py-7 w-full ml-5 overflow-x-hidden">
        <div className="flex gap-4 w-[max-content]">
          <div className="w-[235px]">
            <InputField
              onChange={(e: string) => setInputVal(e)}
              placeholder="Search role"
              value={inputVal}
              rightIcon={<SearchIcon />}
              withDebounce
            />
          </div>
          <div className="w-fit">
            <Link to={"/users/roles/create"}>
              <Button label="Create Role" leftIcon={<PlusIcon />} />
            </Link>
          </div>
        </div>
      </div>

      <div className="!h-[calc(100%-20vh)] w-full relative -z-0 ml-[10px]">
        <ManagementTableLayout
          tableInstance={tableInstance}
          loading={loading}
        />
      </div>
      {loading ? (
        ""
      ) : (
        <div className="w-full">
          <Pagination
            currentPage={page}
            pageSize={pageLength}
            totalCount={totalCount}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
      )}

      {deleteModal && (
        <Modal
          top
          header="Delete Role"
          footer={
            <div className="flex gap-x-3">
              <Button
                variant="primary"
                label="Delete"
                onClick={() => handleDelete(deleteModal)}
                disabled={deleteRoleLoading}
              />
              <Button
                variant="secondary"
                label="Cancel"
                onClick={() => {
                  setDeleteModal("");
                }}
                disabled={deleteRoleLoading}
              />
            </div>
          }
          handleCancel={() => setDeleteModal("")}
        >
          <p className="text-left">Are you sure you want to delete the Role?</p>
        </Modal>
      )}
    </div>
  );
}

export default UserRoles;
